import React, { useState, useEffect } from "react";
import { Modal, Typography, Row, Col, Button, message } from "antd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths, isQuantitiesWithinMax } from "../const/const_functions";
import FoodCart from "./FoodCart";
import RetailCart from "./RetailCart";
import EventCart from "./EventCart";

const { Text } = Typography;

const EditItemsCartModal = ({
  showEditItemsModal,
  setShowEditItemsModal,
  storeType,
  setMultiCartData,
  cart,
  multiCart,
  multiCartCopy,
}) => {
  const { width } = useWindowDimensions();
  const [currentCart, setCurrentCart] = useState(cart);

  const updateMultiCart = () => {
    let newMultiCart = [];
    if (storeType === "food") {
      if (currentCart.foodItems.length === 0) {
        //Remove currentCart
        newMultiCart = multiCart.filter(
          (cart) => cart.stadiumID !== currentCart.stadiumID
        );
      } else {
        newMultiCart = multiCart.map((mCart) =>
          mCart.stadiumID === currentCart.stadiumID ? currentCart : mCart
        );
      }
    } else if (storeType === "retail") {
      if (currentCart.merchItems.length === 0) {
        //Remove currentCart
        newMultiCart = multiCart.filter(
          (cart) => cart.stadiumID !== currentCart.stadiumID
        );
      } else {
        newMultiCart = multiCart.map((mCart) =>
          mCart.stadiumID === currentCart.stadiumID ? currentCart : mCart
        );
      }
    } else if (storeType === "venue") {
      const quantitiesData = isQuantitiesWithinMax(currentCart);
      if (quantitiesData) {
        message.open({
          type: "warning",
          content: `You can only purchase a maximum of ${quantitiesData.evAdmisCustMax} ${quantitiesData.evAdmisName} for this event, please adjust your cart amount and try again`,
        });
        return;
      }
      if (
        (!currentCart.eventItems || currentCart.eventItems?.length === 0) &&
        (!currentCart.foodItems || currentCart.foodItems?.length === 0)
      ) {
        //Remove currentCart
        newMultiCart = multiCart.filter(
          (cart) => cart.stadiumID !== currentCart.stadiumID
        );
      } else {
        let newCart = { ...currentCart };
        if (newCart.foodItems && newCart.foodItems.length === 0) {
          //Remove foodItems from currentCart
          delete newCart.foodItems;
        }
        if (newCart.eventItems && newCart.eventItems.length === 0) {
          //Remove eventItems from currentCart
          delete newCart.eventItems;
        }
        setCurrentCart(newCart);
        newMultiCart = multiCart.map((mCart) =>
          mCart.stadiumID === currentCart.stadiumID ? currentCart : mCart
        );
      }
    }
    setMultiCartData(newMultiCart);
    setShowEditItemsModal(false);
  };

  const onCancelModal = () => {
    setShowEditItemsModal(false);
    setMultiCartData(multiCartCopy);
  };

  const increaseQuantityToPurchase = (cartItem, evAdmisId) => {
    let tempCart = { ...currentCart };

    if (storeType === "food") {
      // Find the item to increase in current cart
      for (let foodItem of tempCart.foodItems) {
        if (cartItem.apfRenderId === foodItem.apfRenderId) {
          if (foodItem.availabilityStatus === "unlimited") {
            foodItem.quantityToPurchase += 1;
          } else {
            if (foodItem.quantityToPurchase < foodItem.quantity) {
              foodItem.quantityToPurchase += 1;
            }
          }
          break;
        }
      }
    } else if (storeType === "venue") {
      if (cartItem.apfRenderId) {
        for (let foodItem of tempCart.foodItems) {
          if (cartItem.apfRenderId === foodItem.apfRenderId) {
            if (foodItem.availabilityStatus === "unlimited") {
              foodItem.quantityToPurchase += 1;
            } else {
              if (foodItem.quantityToPurchase < foodItem.quantity) {
                foodItem.quantityToPurchase += 1;
              }
            }
            break;
          }
        }
      } else if (cartItem.evpRenderId) {
        // Increase the quantity of the admission type
        for (let admisType of cartItem.admissionPurchaseQuantities) {
          if (admisType.evAdmisId === evAdmisId) {
            if (admisType.evAdmisQuantity <= admisType.quantityToPurchase)
              return;

            admisType.quantityToPurchase += 1;
            break;
          }
        }
      }
    } else if (storeType === "retail") {
      // Find the item to increase in current cart
      for (let merchItem of tempCart.merchItems) {
        if (cartItem.apfRenderId === merchItem.apfRenderId) {
          const quantityAvailable = merchItem.quantityAvailable
            ? merchItem.quantityAvailable
            : merchItem.veepQuantity;
          if (quantityAvailable > merchItem.quantityToPurchase) {
            merchItem.quantityToPurchase += 1;
          }
          break;
        }
      }
    }
    setCurrentCart(tempCart);
  };

  const decreaseQuantityToPurchase = (cartItem, evAdmisId) => {
    let tempCart = { ...currentCart };
    if (storeType === "food") {
      // Find the item to decrease in current cart
      if (cartItem.quantityToPurchase > 1) {
        for (let foodItem of tempCart.foodItems) {
          if (cartItem.apfRenderId === foodItem.apfRenderId) {
            foodItem.quantityToPurchase -= 1;
            break;
          }
        }
      }
    } else if (storeType === "venue") {
      if (cartItem.apfRenderId) {
        if (cartItem.quantityToPurchase > 1) {
          for (let foodItem of tempCart.foodItems) {
            if (cartItem.apfRenderId === foodItem.apfRenderId) {
              foodItem.quantityToPurchase -= 1;
              break;
            }
          }
        }
      } else if (cartItem.evpRenderId) {
        // Decrease the quantity of the admission type
        for (let admisType of cartItem.admissionPurchaseQuantities) {
          if (admisType.evAdmisId === evAdmisId) {
            if (admisType.quantityToPurchase > 1) {
              admisType.quantityToPurchase -= 1;
              break;
            }
          }
        }
      }
    } else if (storeType === "retail") {
      // Find the item to decrease in current cart
      if (cartItem.quantityToPurchase > 1) {
        for (let merchItem of tempCart.merchItems) {
          if (cartItem.apfRenderId === merchItem.apfRenderId) {
            merchItem.quantityToPurchase -= 1;
            break;
          }
        }
      }
    }
    setCurrentCart(tempCart);
  };

  const removeItemFromCart = (cartItem, evAdmisId, evpRenderId) => {
    if (storeType === "food") {
      const foundItem = currentCart.foodItems.some(
        (item) => item.apfRenderId === cartItem.apfRenderId
      );
      if (foundItem) {
        //Remove current item
        const newFoodItems = currentCart.foodItems.filter(
          (item) => item.apfRenderId !== cartItem.apfRenderId
        );
        const newCart = { ...cart, foodItems: newFoodItems };
        setCurrentCart(newCart);
      }
    } else if (storeType === "venue") {
      if (cartItem.apfRenderId) {
        //If Food item
        const foundItem = currentCart.foodItems.some(
          (item) => item.apfRenderId === cartItem.apfRenderId
        );
        if (foundItem) {
          //Remove current item
          const newFoodItems = currentCart.foodItems.filter(
            (item) => item.apfRenderId !== cartItem.apfRenderId
          );
          const newCart = { ...cart, foodItems: newFoodItems };
          setCurrentCart(newCart);
        }
      } else if (cartItem.evpRenderId) {
        //If Event item
        const rightEvent = currentCart.eventItems.find(
          (event) => event.evpRenderId === evpRenderId
        );
        const newAdmissionPurchaseQuantities =
          rightEvent.admissionPurchaseQuantities.filter(
            (admisType) => admisType.evAdmisId !== evAdmisId
          );
        if (newAdmissionPurchaseQuantities.length === 0) {
          //Remove current event item
          const newEventItems = currentCart.eventItems.filter(
            (eventItem) => eventItem.evpRenderId !== evpRenderId
          );
          const newCart = { ...currentCart, eventItems: newEventItems };
          setCurrentCart(newCart);
        } else {
          const newEventItems = currentCart.eventItems.map((eventItem) =>
            eventItem.evpRenderId === evpRenderId
              ? {
                  ...eventItem,
                  admissionPurchaseQuantities: newAdmissionPurchaseQuantities,
                }
              : eventItem
          );
          const newCart = { ...currentCart, eventItems: newEventItems };
          setCurrentCart(newCart);
        }
      }
    } else if (storeType === "retail") {
      const foundItem = currentCart.merchItems.some(
        (item) => item.apfRenderId === cartItem.apfRenderId
      );
      if (foundItem) {
        const newRetailItems = currentCart.merchItems.filter(
          (merchItem) => merchItem.apfRenderId !== cartItem.apfRenderId
        );
        const newCart = { ...cart, merchItems: newRetailItems };
        setCurrentCart(newCart);
      }
    }
  };

  return (
    <Modal
      open={showEditItemsModal}
      className="modal"
      onCancel={() => onCancelModal()}
      width={width > screenWidths.md ? "60%" : "100%"}
      footer={null}
      destroyOnClose={true}
    >
      <Col>
        <Row>
          <Text className="editItemTitle">Edit Items</Text>
          <div className="editLine"></div>
        </Row>
        <Row>
          <Text className="stadiumName">{cart.stadiumName}</Text>
        </Row>
        <Row>
          <Text className="stadiumAddress">{cart.stadiumAddress}</Text>
        </Row>
        <Col
          className="editItemsContainer"
          style={{ width: "100%", marginTop: "20px" }}
        >
          {storeType === "food" && (
            <FoodCart
              currentCart={currentCart}
              removeItemFromCart={removeItemFromCart}
              increaseQuantityToPurchase={increaseQuantityToPurchase}
              decreaseQuantityToPurchase={decreaseQuantityToPurchase}
            />
          )}
          {storeType === "retail" && (
            <RetailCart
              currentCart={currentCart}
              removeItemFromCart={removeItemFromCart}
              increaseQuantityToPurchase={increaseQuantityToPurchase}
              decreaseQuantityToPurchase={decreaseQuantityToPurchase}
            />
          )}
          {storeType === "venue" && (
            <EventCart
              currentCart={currentCart}
              removeItemFromCart={removeItemFromCart}
              increaseQuantityToPurchase={increaseQuantityToPurchase}
              decreaseQuantityToPurchase={decreaseQuantityToPurchase}
            />
          )}
        </Col>
        <Row justify="center">
          <Button
            type="primary"
            className="editBtn"
            style={{ backgroundColor: "black", color: "white" }}
            onClick={() => updateMultiCart()}
          >
            Save
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};

export default EditItemsCartModal;
