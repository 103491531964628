import React from "react";
import styles from "../styles/LoginForm.module.css";

const LoginSubText = ({
  showError,
  errorText,
  showSubText = false,
  subText,
  onClickAction = () => {},
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: 18,
        alignItems: "center",
        marginTop: 6,
      }}
    >
      <p style={{ color: "red" }}>{showError ? errorText : ""}</p>

      {showSubText ? (
        <p onClick={onClickAction} className={styles.forgotBtn}>
          {subText}
        </p>
      ) : null}
    </div>
  );
};
export default LoginSubText;
