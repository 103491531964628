import { Row, Col, Typography, ConfigProvider } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GiShoppingCart } from "react-icons/gi";

const EmptyCart = () => {
  const navigate = useNavigate();
  const { Text } = Typography;
  const { theme } = useContext(ConfigProvider.ConfigContext);
  return (
    <Col className="emptyCartContainer">
      <Row
        justify="center"
        align="middle"
        style={{
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: theme.token.colorSecondary,
            borderRadius: "100%",
            width: 190,
            height: 190,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GiShoppingCart style={{ fontSize: 150, color: "white" }} />
        </div>
      </Row>
      <Col>
        <Text className="emptyCartText">Get Shopping!</Text>
        <br />
        <Text
          className="emptyCartLink"
          style={{ color: theme.token.colorSecondary }}
          onClick={() => navigate("/", false)}
        >
          Browse Now
        </Text>
      </Col>
    </Col>
  );
};

export default EmptyCart;
