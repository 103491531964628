import React, { createContext, useState, useContext, useEffect } from "react";
import { useBrandContext } from "./Brand";
import { useServer } from "./Server";
import { isLocationRemovabele } from "../const/const_functions";

// Create the Chain Context with the data type specified
// and a empty object
const ChainContext = createContext();

const ChainProvider = ({ children }) => {
  const { brandContextData } = useBrandContext();
  const { serverData } = useServer();

  const [chainBrands, setChainBrands] = useState([]);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [locationSelected, setLocationSelected] = useState(null);

  useEffect(() => {
    if (brandContextData?.isChain && serverData) {
      // Everytime a page is opened we get chain
      fetchChainData();
    }
  }, [brandContextData, serverData]);

  const fetchChainData = () => {
    const chainBrands = JSON.parse(localStorage.getItem("chainBrands"));
    let newChainBrands = chainBrands;
    if (chainBrands) {
      const currentBrand = chainBrands.find(
        (brand) => brand.brandContextId === brandContextData._id
      );
      if (currentBrand) {
        //Remove a location if createdAt greater than 7 days
        if (isLocationRemovabele(currentBrand.createdAt)) {
          newChainBrands = chainBrands.filter(
            (brand) => brand.brandContextId !== currentBrand.brandContextId
          );
          localStorage.setItem("chainBrands", JSON.stringify(newChainBrands));
        } else {
          getStadiumData(currentBrand.locationSelected);
        }
      }
      setChainBrands(newChainBrands);
    }
  };

  const getStadiumData = async (stadiumId) => {
    try {
      const stadium = await serverData.call("USSR_getOneStadium", stadiumId);
      setLocationSelected(stadium);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (locationSelected) {
      getFeaturedItems();
    }
  }, [locationSelected]);

  const getFeaturedItems = async () => {
    try {
      const featuredData = await serverData.call("USSR_getStoreFeaturedItems", {
        stadiumId: locationSelected._id,
      });
      setFeaturedItems(featuredData.featuredItems);
    } catch (e) {
      console.log(e);
    }
  };

  const setBrandChain = async (stadium) => {
    const stadiumId = stadium._id;
    const currentDate = new Date();
    let newChainBrands = [];
    if (chainBrands) {
      const currentBrand = chainBrands.find(
        (brand) => brand.brandContextId === brandContextData._id
      );

      //Add new brand and location to an existing chainBrands
      newChainBrands = [
        ...chainBrands,
        {
          brandContextId: brandContextData._id,
          locationSelected: stadiumId,
          createdAt: currentDate,
        },
      ];

      //Change location for the current brand
      if (currentBrand) {
        newChainBrands = chainBrands.map((brand) =>
          brand.brandContextId !== brandContextData._id
            ? brand
            : { ...brand, locationSelected: stadiumId, createdAt: currentDate }
        );
      }
    } else {
      //Create new chainBrands
      newChainBrands = [
        {
          brandContextId: brandContextData._id,
          locationSelected: stadiumId,
          createdAt: currentDate,
        },
      ];
    }

    localStorage.setItem("chainBrands", JSON.stringify(newChainBrands));
    setLocationSelected(stadium);
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <ChainContext.Provider
      value={{
        locationSelected,
        setLocationSelected,
        setBrandChain,
        featuredItems,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

function useChain() {
  const context = useContext(ChainContext);

  if (!context) {
    throw new Error("useChain must be used within an ChainContext");
  }

  return context;
}

export { ChainContext, ChainProvider, useChain };
