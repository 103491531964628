import React from "react";
import { Row, Col, Button, Input, message } from "antd";

const DonateAmountBox = (props) => {
  const {
    tempAmount,
    paymentAmount,
    setPaymentAmount,
    setInputPaymentAmountValue,
    token,
    inputPaymentAmountValue,
  } = props;

  const handlePaymentAmountChange = (event) => {
    // Get the new payment amount from the input field
    const newPaymentAmount = parseFloat(event.target.value);

    // Ensure the payment amount is a number
    if (isNaN(newPaymentAmount)) {
      setPaymentAmount(tempAmount[0]);
      return;
    }

    // The minimum donation amount cannot be lower than the first temp amount.
    if (newPaymentAmount < tempAmount[0]) {
      setPaymentAmount(tempAmount[0]);
      setInputPaymentAmountValue(tempAmount[0]);
      message.error(
        `Sorry, there is a minimum donation amount of $${tempAmount[0].toFixed(
          2
        )}`
      );
      return;
    }

    // Update the paymentAmount state
    setPaymentAmount(newPaymentAmount);
    setInputPaymentAmountValue(newPaymentAmount);
  };

  return (
    <>
      {tempAmount?.length ? (
        <Row gutter={[15, 15]} type="flex">
          {tempAmount.map((item, index) => {
            return (
              <Col span={8} key={index}>
                <Button
                  className="amountBox"
                  style={{
                    backgroundColor: paymentAmount === item ? "black" : "white",
                    color: paymentAmount === item ? "white" : "gray",
                    fontWeight: paymentAmount === item ? "bold" : "initial",
                  }}
                  onClick={() =>
                    paymentAmount === item
                      ? setPaymentAmount(0)
                      : (setPaymentAmount(item), setInputPaymentAmountValue(0))
                  }
                >
                  ${item}
                </Button>
              </Col>
            );
          })}
        </Row>
      ) : null}
      <Row style={{ paddingTop: tempAmount?.length ? 15 : 0 }}>
        <Col className="otherAmountBox">
          <Input
            className="amountInput"
            addonBefore={<p className="grayText">$</p>}
            placeholder="Other amount"
            type="number"
            onBlur={handlePaymentAmountChange}
            value={inputPaymentAmountValue ? inputPaymentAmountValue : null}
            onChange={(e) => {
              setInputPaymentAmountValue(e.target.value);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DonateAmountBox;
