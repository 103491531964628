import React from "react";
import { Modal } from "antd";

const AccountSuccessModal = ({
  message,
  showSuccessModal,
  handleCloseModal,
}) => {
  return (
    <Modal
      title={`Success!`}
      open={showSuccessModal}
      onCancel={handleCloseModal}
      onOk={handleCloseModal}
      okButtonProps={{
        style: {
          backgroundColor: "black",
          borderColor: "black",
          color: "white",
        },
      }}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default AccountSuccessModal;
