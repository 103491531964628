import {
  Button,
  Col,
  Image,
  Input,
  Layout,
  Popconfirm,
  Row,
  Spin,
  message,
  theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import { Content } from "antd/es/layout/layout";
import { useServer } from "../contexts/Server";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths, validateEmail } from "../const/const_functions";
import SquareAddCreditCard from "../components/SquareAddCreditCard";
import styles from "../styles/EditAccount.module.css";
import { useNavigate } from "react-router-dom";
const { useToken } = theme;

const EditAccount = () => {
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [removingCard, setRemovingCard] = useState(false);
  const [gettingCust, setGettingCust] = useState(true);
  const { logoutUser, userDetails, fetchAsync, authData, loadingAuth } =
    useAuth();
  const { serverData } = useServer();
  const [stadium, setStadium] = useState();
  const [currentSavedCC, setCurrentSavedCC] = useState();
  const [showCreditCardEntry, setShowCreditCardEntry] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState({
    password: "",
  });
  const { token } = useToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authData && !loadingAuth) {
      navigate("/login");
    }
  }, [authData, loadingAuth]);

  useEffect(() => {
    if (userDetails?.profile && serverData) {
      fetchStadium();
      getSQCustomer();
      setRegistrationDetails({
        customerName: userDetails.profile.name,
        customerEmail: userDetails.profile.email,
        customerPhone: userDetails.profile.phone,
        accountId: userDetails.profile.externalAccountId,
      });
    }
  }, [userDetails, serverData]);

  const getSQCustomer = async () => {
    try {
      const res = await serverData.call("BLLSTC_getSQCustomer", {
        userID: userDetails?._id,
      });
      if (res?.cards?.length) {
        setCurrentSavedCC(res.cards[0]);
      } else {
        setCurrentSavedCC(null);
      }
    } catch (e) {
      setCurrentSavedCC(null);
    }

    setGettingCust(false);
  };

  useEffect(() => {
    if (stadium && !gettingCust) {
      setInitialLoading(false);
    }
  }, [stadium, gettingCust]);

  const updateCCInfo = async (token, verifiedBuyer) => {
    setLoading(true);

    if (!checkForValidInput("update")) {
      setLoading(false);
      return;
    }

    if (currentSavedCC) {
      try {
        await serverData.call("BLLSTC_deleteSQCustomerCard", {
          userID: userDetails._id,
          cardID: currentSavedCC.id,
        });
      } catch (e) {
        console.log(e);
        setLoading(false);

        return;
      }
    }

    try {
      await serverData.call("BLLSTC_createSQCustomerCard", {
        userID: userDetails._id,
        nonce: token.token,
        verifiedBuyer,
      });
    } catch (e) {
      console.log(e);
      setLoading(false);

      return;
    }

    await updateCustomerData();

    getSQCustomer();
    setShowCreditCardEntry(false);
    setLoading(false);
  };

  const userMadeChanges = () => {
    const { customerName, customerEmail, customerPhone } = registrationDetails;
    const { invoiceTemplate } = stadium;

    const nameTemplate = invoiceTemplate?.customerName;
    const emailTemplate = invoiceTemplate?.customerEmail;
    const phoneTemplate = invoiceTemplate?.customerPhone;

    return (
      (nameTemplate?.canRegister &&
        nameTemplate?.display &&
        customerName !== userDetails.profile.name) ||
      (emailTemplate?.canRegister &&
        emailTemplate?.display &&
        customerEmail !== userDetails.profile.email) ||
      (phoneTemplate?.canRegister &&
        phoneTemplate?.display &&
        customerPhone !== userDetails.profile.phone)
    );
  };

  const fetchStadium = async () => {
    let res;

    try {
      res = await serverData.call(
        "USSR_getOneStadium",
        userDetails?.profile?.placeSelected
      );
      setStadium(res);
    } catch (e) {
      console.log(e);
    }
  };

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  let imgWidth = 180;

  if (
    stadium?._id === "vWbhEfxtsfbK82CKv" ||
    stadium?._id === "CakDLtXvDiB7xZRCR"
  ) {
    imgWidth = 280;
  }

  if (!userDetails?.profile) {
    return null;
  }

  const checkForValidInput = (method) => {
    let valid = true;
    let messageToShow = "";

    const { customerPhone, customerName, customerEmail } = registrationDetails;
    const { invoiceTemplate } = stadium;

    const phoneTemplate = invoiceTemplate?.customerPhone;
    const nameTemplate = invoiceTemplate?.customerName;
    const emailTemplate = invoiceTemplate?.customerEmail;

    if (!userMadeChanges() && method !== "update") {
      valid = false;
    }

    if (
      !customerPhone &&
      phoneTemplate?.canRegister &&
      phoneTemplate?.display
    ) {
      valid = false;
      messageToShow = "Invalid Phone number";
    } else if (
      customerPhone &&
      phoneTemplate?.canRegister &&
      phoneTemplate?.display &&
      customerPhone.length !== 10
    ) {
      valid = false;
      messageToShow = "Phone numbers must be 10 digits long";
    }

    if (
      !customerName.trim() &&
      nameTemplate?.canRegister &&
      nameTemplate?.display
    ) {
      valid = false;
      messageToShow = `Please input a ${nameTemplate.label}`;
    }

    if (
      !customerEmail &&
      emailTemplate?.canRegister &&
      emailTemplate?.display
    ) {
      valid = false;
      messageToShow = "Please input an email";
    } else if (
      customerEmail &&
      emailTemplate?.canRegister &&
      emailTemplate?.display &&
      !validateEmail(customerEmail)
    ) {
      valid = false;
      messageToShow = "Invalid Email";
    }

    if (messageToShow && method === "update") {
      message.error(messageToShow);
    }

    return valid;
  };

  const updateCustomerData = async (single) => {
    setLoading(true);

    if (!checkForValidInput("update")) {
      setLoading(false);
      return;
    }

    const newProfile = {
      phone: registrationDetails?.customerPhone
        ? registrationDetails.customerPhone
        : "",
      name: registrationDetails?.customerName,
      externalAccountId: userDetails.profile.externalAccountId,
      email: registrationDetails?.customerEmail,
    };
    try {
      await serverData.call("BLLSTC_updatePayorInfo", {
        userId: userDetails._id,
        newProfile,
      });
      if (single) {
        setLoading(false);
        message.success("Profile info saved");
      }
      fetchAsync();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const deleteSQCustomerCard = async () => {
    setRemovingCard(true);
    try {
      await serverData.call("BLLSTC_deleteSQCustomerCard", {
        userID: userDetails._id,
        cardID: currentSavedCC.id,
      });
      await getSQCustomer();
    } catch (e) {
      console.log(e);
    }
    setRemovingCard(false);
  };

  const _renderFormField = (formObj, fieldName) => {
    const { label } = formObj;
    const formLabel = label;
    const txtLabel = (
      <p style={{ fontWeight: "600", color: "black", marginBottom: 6 }}>
        {formLabel}
        <span style={{ fontWeight: "500", color: "red", marginBottom: 6 }}>
          *
        </span>
      </p>
    );

    return (
      <>
        {txtLabel}
        <Input
          type={fieldName === "customerPhone" ? "number" : "text"}
          disabled={fieldName === "accountId" || fieldName === "customerEmail"}
          value={registrationDetails[fieldName]}
          onChange={(e) => takeInput({ inputVal: e.target.value, fieldName })}
        />
      </>
    );
  };

  const takeInput = ({ inputVal, fieldName }) => {
    setRegistrationDetails((prevState) => ({
      ...prevState,
      [fieldName]: inputVal,
    }));
  };

  let ccButtonText = "";
  if (currentSavedCC && !showCreditCardEntry) {
    ccButtonText = "Update Credit Card";
  } else if (!currentSavedCC && !showCreditCardEntry) {
    ccButtonText = "Add Credit Card";
  }

  if (showCreditCardEntry) {
    ccButtonText = "Cancel";
  }

  let loadingText = "Retrieving account info...";

  if (loading) {
    loadingText =
      "Saving your account... Please do not navigate away or refresh the page.";
  } else if (removingCard) {
    loadingText = "Removing Credit Card...";
  }

  if (!stadium) {
    return null;
  }

  const formFields = [];
  for (const [key, value] of Object.entries(stadium?.invoiceTemplate)) {
    const newObj = { ...value, key };

    if (newObj.display && newObj.canRegister) {
      formFields.push(newObj);
    }
  }

  // Sort the formFields array based on the renderPosition property
  formFields.sort((a, b) => (a.renderPosition || 0) - (b.renderPosition || 0));

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Spin
        size="large"
        spinning={loading || initialLoading || removingCard}
        tip={loadingText}
      >
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }}>
            {stadium?.imageUrl ? (
              <Row justify={"center"}>
                <Image
                  width={mobile ? "50%" : imgWidth}
                  preview={false}
                  src={stadium.imageUrl}
                  style={{
                    maxHeight: 180,
                    minHeight: 75,
                    objectFit: "contain",
                  }}
                />
              </Row>
            ) : null}
            <p
              style={{
                fontWeight: "600",
                color: "black",
                fontSize: 24,
                marginBottom: 40,
              }}
            >
              Edit your auto-billing info
            </p>
            {formFields.map((field) => (
              <React.Fragment key={field.key}>
                {_renderFormField(field, field.key)}
              </React.Fragment>
            ))}

            <div style={{ height: 25 }}></div>

            {currentSavedCC?.last4 ? (
              <p style={{ fontWeight: "600", color: "black", marginBottom: 6 }}>
                Credit Card ... last 4: {currentSavedCC.last4} (
                {currentSavedCC.cardBrand})
              </p>
            ) : null}
            <div style={{ display: "flex" }}>
              <Button
                className={styles.primary}
                onClick={() => setShowCreditCardEntry(!showCreditCardEntry)}
              >
                {ccButtonText}
              </Button>
              {currentSavedCC && !showCreditCardEntry ? (
                <Popconfirm
                  okType="secondary"
                  title="Remove Credit Card"
                  description="Are you sure to remove this card from your account?"
                  onConfirm={deleteSQCustomerCard}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button className={styles.remove}>Remove Credit Card</Button>
                </Popconfirm>
              ) : null}
            </div>
            {showCreditCardEntry ? (
              <>
                <div style={{ paddingTop: 10 }}>
                  <SquareAddCreditCard
                    tempName={registrationDetails?.customerName}
                    tempEmail={registrationDetails?.customerEmail}
                    tempPhone={registrationDetails?.customerPhone}
                    title={"Update Credit Card & Account Info"}
                    handleSubmitCreditCard={updateCCInfo}
                  />
                </div>
              </>
            ) : null}
            {!showCreditCardEntry ? (
              <Button
                disabled={!userMadeChanges()}
                style={{
                  width: "100%",
                  marginTop: 22,
                  backgroundColor: userMadeChanges()
                    ? token.colorSecondary
                    : "lightgray",
                  color: "white",
                  fontWeight: "600",
                  height: 44,
                }}
                onClick={() => updateCustomerData(true)}
              >
                Update Your Information
              </Button>
            ) : null}

            <Button
              className={styles.primary}
              style={{ marginTop: 22, width: "100%", height: 36 }}
              onClick={async () => {
                await logoutUser();
                navigate("/login");
              }}
            >
              Logout
            </Button>
          </Col>
        </Content>
      </Spin>
    </Layout>
  );
};

export default EditAccount;
