import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Input,
  Checkbox,
  theme,
  Form,
  Button,
  notification,
  Tooltip,
  Popconfirm,
} from "antd";
import styles from "../styles/InvoicePayment.module.css";
import { useBrandContext } from "../contexts/Brand";
import { useServer } from "../contexts/Server";
import { useCarts } from "../contexts/Carts";
import { useAuth } from "../contexts/Auth";
import {
  screenWidths,
  validateEmail,
  rfx_v2_xnew_transformOrderObject,
  decodeParamString,
  encodeParamString,
} from "../const/const_functions";
import { AiFillStar } from "react-icons/ai";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useNavigate, useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import EditItemSection from "./checkout/EditItemSection";
import OrderBreakdown from "../components/checkout/OrderBreakdown";

const allRules = {
  customerEmail: [
    {
      required: true,
      message: "Please input your Email!",
    },
    { whitespace: true },
    {
      validator: (_, value) => {
        const trimmedValue = value.trim();
        if (trimmedValue && !validateEmail(trimmedValue)) {
          return Promise.reject(new Error("Please input a valid email"));
        }

        return Promise.resolve();
      },
    },
  ],
  customerPhone: [
    { whitespace: false },
    {
      validator: (_, value) => {
        if (Number(value) < 0) {
          return Promise.reject(
            new Error("Please enter a valid 10 digit phone number.")
          );
        }

        if (isNaN(Number(value))) {
          return Promise.reject(
            new Error("Phone Numbers should only consist of digits.")
          );
        }

        if (value && value.length !== 10) {
          return Promise.reject(
            new Error("Phone Numbers should be 10 digits long.")
          );
        }

        return Promise.resolve();
      },
    },
  ],
};

const CatalogueTemplate = {
  accountId: {
    label: "Account Number",
    type: "or",
    sibling: "invoiceId",
    display: true,
    allowSave: true,
    readOnlyIfCustomerLoggedIn: true,
  },
  invoiceId: {
    label: "Invoice Number",
    sibling: "accountId",
    type: "or",
    display: false,
    allowSave: false,
    readOnlyIfCustomerLoggedIn: true,
  },
  serviceDescription: {
    label: "Service Description",
    type: "required",
    display: true,
    allowSave: true,
    readOnlyIfCustomerLoggedIn: false,
  },
  customerName: {
    label: "Personal/Business Name",
    type: "required",
    display: true,
    allowSave: true,
    readOnlyIfCustomerLoggedIn: true,
  },
  customerEmail: {
    label: "Email",
    type: "required",
    display: true,
    allowSave: true,
    readOnlyIfCustomerLoggedIn: true,
  },
  customerPhone: {
    label: "Phone",
    type: "optional",
    display: true,
    allowSave: true,
    readOnlyIfCustomerLoggedIn: true,
  },
};

const { Text } = Typography;

const CataloguePaymentForm = () => {
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { serverData } = useServer();
  const { multiCart, setMultiCartData } = useCarts();
  const { useToken } = theme;
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm();

  const { encodedStadiumId } = useParams();
  const stadiumId = decodeParamString(encodedStadiumId);

  const [stadiumData, setStadiumData] = useState(null);
  const [cart, setCart] = useState(null);
  const { userDetails, authData, loadingAuth } = useAuth();

  const [submittable, setSubmittable] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState({});

  const [digitalFeeObj, setDigitalFeeObj] = useState(null);
  const [serviceFeeToShow, setServiceFeeToShow] = useState(0);
  const [computingBreakdown, setComputingBreakdown] = useState(true);
  const [hstToShow, setHstToShow] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [orderTotalWithTax, setOrderTotalWithTax] = useState(0);
  const [orderTotalWithEverything, setOrderTotalWithEverything] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [showAddressTooFar, setShowAddressTooFar] = useState(false);

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }
  useEffect(() => {
    if (!authData && !loadingAuth) {
      navigate("/login");
    }
  }, [authData, loadingAuth]);

  const getUserProfileInfo = () => {
    if (userDetails?.profile) {
      const { name, email, phone, externalAccountId } = userDetails.profile;
      const userInfo = {
        customerEmail: email,
        customerName: name,
        customerPhone: phone,
        accountId: externalAccountId,
      };
      setInvoiceDetails(userInfo);
    }
  };

  useEffect(() => {
    if (userDetails && serverData) {
      getUserProfileInfo();
      getStadiumData();
      getServiceFee();
    }
  }, [serverData, userDetails]);

  useEffect(() => {
    if (digitalFeeObj) {
      computeOrderTotal();
    }
  }, [orderSubtotal]);

  useEffect(() => {
    if (!digitalFeeObj?.applyServiceFee) {
      handleFormChange();
    }
  }, [digitalFeeObj]);

  useEffect(() => {
    if (stadiumData?.catalogueMode) {
      const newCart = multiCart.find((mCart) => mCart.stadiumID === stadiumId);
      setCart(newCart);
    }
  }, [multiCart]);

  const validateInvoiceDetails = (invoiceDetails) => {
    const errors = {};

    const requiredFields = new Set();

    if (Object.keys(invoiceDetails).length === 0) {
      return { noInvoiceDetails: "There are no invoice details" };
    }

    if (!stadiumData?.invoiceTemplate) {
      return errors;
    }

    Object.keys(stadiumData?.invoiceTemplate).forEach((field) => {
      const { type, sibling, display, label } =
        stadiumData.invoiceTemplate[field];

      if (display) {
        const value = invoiceDetails[field];

        if (type === "required" && (!value || String(value).trim() === "")) {
          errors[field] = `${label} is required.`;
        }

        if (sibling) {
          requiredFields.add(field);

          if (invoiceDetails[field] && invoiceDetails[sibling]) {
            // If both siblings are present, clear the errors for both
            delete errors[field];
            delete errors[sibling];
          }
        }
      }
    });

    // Check if at least one field from each sibling pair is present
    requiredFields.forEach((field) => {
      const sibling = stadiumData.invoiceTemplate[field].sibling;
      if (!invoiceDetails[field] && !invoiceDetails[sibling]) {
        errors[
          field
        ] = `${stadiumData.invoiceTemplate[field].label} or ${stadiumData.invoiceTemplate[sibling].label} is required.`;
      }
    });

    return errors;
  };

  const getStadiumData = async () => {
    setCreatingOrder(true);
    try {
      const stadiumData = await serverData.call(
        "USSR_getOneStadium",
        stadiumId
      );
      if (stadiumData) {
        const _ = require("lodash");
        const newStadiumData = _.cloneDeep(stadiumData);

        if (!newStadiumData.invoiceTemplate) {
          // Mocked templating
          newStadiumData.invoiceTemplate = CatalogueTemplate;
        }
        //Delete Payment Amount Field
        delete newStadiumData.invoiceTemplate["amountToPay"];

        setStadiumData(newStadiumData);

        if (newStadiumData?.catalogueMode) {
          const newCart = multiCart.find(
            (mCart) => mCart.stadiumID === stadiumId
          );
          setCart(newCart);
        }
      } else {
        navigate("/error");
      }
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
    setCreatingOrder(false);
  };

  const getServiceFee = async () => {
    // Compute order digital fee
    let digitalFeeObj = {};
    try {
      digitalFeeObj = await serverData.call("MIKO_getContextServiceFee", {
        contextId: brandContextData._id,
        exemptFee: stadiumData && stadiumData.catalogueMode ? true : false,
      });
      setDigitalFeeObj(digitalFeeObj);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const computeOrderTotal = () => {
    let serviceFee = 0;
    if (digitalFeeObj?.applyServiceFee) {
      const feePercentage = digitalFeeObj.serviceFeeInfo.feePercentage;
      const feeCents = digitalFeeObj.serviceFeeInfo.feeCents;

      serviceFee = orderSubtotal * (feePercentage / 100) + feeCents / 100;
    }
    setServiceFeeToShow(serviceFee);
    setOrderTotalWithEverything(orderSubtotal + serviceFee);
  };

  const createTheOrder = async () => {
    let paymentInfo = {
      totalAmount: orderTotalWithEverything,
      hst: 0,
      deliveryFee: null,
      orderAmount: orderSubtotal,
      appVersion: null,
      platform: "web",
    };

    let region = null;
    let storeInstructions = "N/A";
    let dataObjToBeTransformed = {
      foodItems: cart,
      amount: orderTotalWithEverything,
      tipAmount: null,
      address: null,
      deliveryComment: null,
      note: storeInstructions,
      deliveryOption: "invoice",
      stadium: stadiumData,
      paymentInfo,
      zone: region,
      invoicesToPay: [
        {
          accountId: invoiceDetails["accountId"],
          invoiceId: invoiceDetails["invoiceId"]
            ? invoiceDetails["invoiceId"]
            : null,
          serviceDetails: invoiceDetails["serviceDescription"]
            ? invoiceDetails["serviceDescription"]
            : null,
          amountToPay: orderSubtotal,
        },
      ],
    };

    let transformedDataObj = null;
    transformedDataObj = rfx_v2_xnew_transformOrderObject(
      dataObjToBeTransformed
    );

    transformedDataObj.guestInfo = false;
    transformedDataObj.contextId = brandContextData._id;
    try {
      const res = await serverData.call(
        "USSR_makePurchaseOrderRequest",
        transformedDataObj
      );
      const { orderDOC } = res;

      // Remove current cart
      const newMultiCart = multiCart.filter(
        (cart) => cart.stadiumID !== cart.stadiumID
      );
      setMultiCartData(newMultiCart);

      const encodedStadiumId = encodeParamString(cart.stadiumID);
      navigate(`/${encodedStadiumId}/catalogueconfirmation`, {
        state: {
          order: orderDOC,
          stadium: orderDOC.stadium,
        },
      });
    } catch (e) {
      notification.error({
        message: "Failed to Purchase Order Request",
        description: e.reason,
      });
      return;
    }
  };

  const handleFormChange = () => {
    let isFormValid = false;
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const validationErrors = validateInvoiceDetails(invoiceDetails);

    if (Object.keys(validationErrors).length === 0 && !hasErrors) {
      if (!digitalFeeObj?.applyServiceFee) {
        isFormValid = true;
      }

      if (digitalFeeObj?.applyServiceFee) {
        isFormValid = true;
      }
    } else {
      // console.log("Validation errors:", validationErrors);
    }

    setSubmittable(isFormValid);
  };

  let submitButtonTitle = "Request A Quote";
  if (orderSubtotal && creatingOrder) {
    submitButtonTitle = "Sending request...";
  }

  const _renderFormField = (formObj, fieldName) => {
    const {
      display,
      label,
      type,
      sibling = false,
      readOnlyIfCustomerLoggedIn = true,
    } = formObj;

    const showFormField = display;
    const formLabel = label;
    const isRequired = type === "required";

    const txtLabel = <Text className={styles.grayText}>{formLabel}</Text>;
    const requiredStar = isRequired ? (
      <AiFillStar
        fontSize={5}
        color="red"
        style={{ marginTop: 2, marginRight: 3 }}
      />
    ) : null;

    if (!showFormField) {
      return null;
    }

    if (sibling) {
      const siblingObj = stadiumData.invoiceTemplate[sibling];
      return (
        <Form.Item
          initialValue={invoiceDetails[fieldName]}
          className={styles.formItems}
          name={fieldName}
          rules={[
            {
              validator: (_, value) => {
                if (!value && !invoiceDetails[fieldName]) {
                  return Promise.reject(
                    new Error(
                      `Please input ${formLabel} and/or ${siblingObj.label}!`
                    )
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Row style={{ alignItems: "center" }}>
            <Col
              xs={{ span: 9 }}
              sm={{ span: 9 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 11 }}
            >
              <Row>
                <AiFillStar
                  fontSize={5}
                  color="red"
                  style={{ marginTop: 2, marginRight: 3 }}
                />
                <Text className={styles.grayText}>{formLabel}</Text>
              </Row>
              <Input
                maxLength={7}
                disabled={readOnlyIfCustomerLoggedIn}
                value={invoiceDetails[fieldName]}
                onChange={(e) =>
                  takeInput({ inputVal: e.target.value, fieldName })
                }
              />
            </Col>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
              className={styles.spaceCol}
            >
              <Text className={styles.text}>and/or</Text>
            </Col>
            <Col
              xs={{ span: 9 }}
              sm={{ span: 9 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 11 }}
            >
              <Row>
                <AiFillStar
                  fontSize={5}
                  color="red"
                  style={{ marginTop: 2, marginRight: 3 }}
                />
                <Text className={styles.grayText}>{siblingObj.label}</Text>
              </Row>
              <Input
                disabled={readOnlyIfCustomerLoggedIn}
                value={invoiceDetails[sibling]}
                onChange={(e) =>
                  takeInput({ inputVal: e.target.value, fieldName: sibling })
                }
              />
            </Col>
          </Row>
        </Form.Item>
      );
    }

    let rules = [];
    if (allRules[fieldName]) {
      rules = allRules[fieldName];
    } else {
      rules = [
        {
          required: isRequired,
          message: `Please input a ${formLabel}`,
        },
      ];
    }

    return (
      <>
        <Row>
          {requiredStar}
          {txtLabel}
        </Row>
        <Form.Item
          initialValue={
            invoiceDetails[fieldName] ? invoiceDetails[fieldName] : null
          }
          className={styles.formItems}
          name={fieldName}
          rules={rules}
        >
          <Input
            type={null}
            disabled={readOnlyIfCustomerLoggedIn}
            value={invoiceDetails[fieldName]}
            placeholder={isRequired ? null : "(Optional)"}
            onChange={(e) => takeInput({ inputVal: e.target.value, fieldName })}
          />
        </Form.Item>
      </>
    );
  };

  const takeInput = ({ inputVal, fieldName }) => {
    setInvoiceDetails((prevState) => ({ ...prevState, [fieldName]: inputVal }));
  };

  let imgWidth = 180;

  if (
    stadiumData?._id === "vWbhEfxtsfbK82CKv" ||
    stadiumData?._id === "CakDLtXvDiB7xZRCR"
  ) {
    imgWidth = 280;
  }

  if (!stadiumData) {
    return null;
  }
  const formFields = [];
  for (const [key, value] of Object.entries(stadiumData?.invoiceTemplate)) {
    const newObj = { ...value, key };
    formFields.push(newObj);
  }

  // Sort the formFields array based on the renderPosition property
  formFields.sort((a, b) => (a.renderPosition || 0) - (b.renderPosition || 0));

  return (
    <div>
      {!loadingBrandContextData && brandContextData && stadiumData ? (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 16, offset: 4 }}
          className={styles.container}
        >
          {stadiumData?.imageUrl ? (
            <Row justify={"center"} className={styles.logoContainer}>
              <Image
                width={mobile ? "50%" : imgWidth}
                preview={false}
                src={stadiumData.imageUrl}
                style={{
                  maxHeight: 180,
                  minHeight: 75,
                  objectFit: "contain",
                }}
                onClick={() => navigate("/")}
              />
            </Row>
          ) : null}
          <Col className={styles.content}>
            {stadiumData?.name ? (
              <Row style={{ marginBottom: 20, justifyContent: "center" }}>
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Text style={{ fontWeight: "bold" }}>PAYEE</Text>
                </Col>
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Text className={styles.grayText} style={{ fontSize: 20 }}>
                    {stadiumData.name}
                  </Text>
                </Col>
              </Row>
            ) : null}
            {stadiumData?.catalogueMode ? (
              <Col span={24} style={{ paddingBottom: 50 }}>
                <EditItemSection
                  cart={cart}
                  multiCart={multiCart}
                  setMultiCartData={setMultiCartData}
                  catalogueMode={stadiumData.catalogueMode}
                />
              </Col>
            ) : null}
            <Form
              form={form}
              layout="vertical"
              size="large"
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFieldsChange={handleFormChange}
            >
              {formFields.map((field) => (
                <React.Fragment key={field.key}>
                  {_renderFormField(field, field.key)}
                </React.Fragment>
              ))}
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 12, offset: 6 }}
              >
                <Text
                  style={{
                    color: "gray",
                  }}
                >
                  <span style={{ color: "red" }}>* </span>
                  DISCLAIMER: There is no fee for submitting your order request.
                  Please note that any orders processed via credit card have an
                  administrative fee applied.
                </Text>
              </Col>
              <OrderBreakdown
                cartPointsObj={null}
                userPoints={null}
                pointsRedemptionAmount={0}
                stadiumData={stadiumData}
                stadiumChosenToCheckoutID={stadiumData._id}
                takeoutMethod={"pickup"}
                tipDollarAmount={0}
                orderTotalWithEverything={orderTotalWithEverything}
                setOrderTotalWithEverything={setOrderTotalWithEverything}
                hstToShow={hstToShow}
                setHstToShow={setHstToShow}
                deliveryPrice={deliveryPrice}
                setDeliveryPrice={setDeliveryPrice}
                orderSubtotal={orderSubtotal}
                setOrderSubtotal={setOrderSubtotal}
                tipAmount={tipAmount}
                setTipAmount={setTipAmount}
                orderTotalWithTax={orderTotalWithTax}
                setOrderTotalWithTax={setOrderTotalWithTax}
                showAddressTooFar={false}
                setShowAddressTooFar={setShowAddressTooFar}
                digitalFeeObj={digitalFeeObj}
                setDigitalFeeObj={setDigitalFeeObj}
                serviceFeeToShow={serviceFeeToShow}
                setServiceFeeToShow={setServiceFeeToShow}
                setComputingBreakdown={setComputingBreakdown}
                computingBreakdown={computingBreakdown}
              />
              <Col style={{ marginTop: 40, marginBottom: 40 }}>
                <Popconfirm
                  disabled={!submittable}
                  title={"Are you sure you would like to request a quote?"}
                  placement="top"
                  style={{ width: "100%" }}
                  onConfirm={() => createTheOrder()}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    type: "danger",
                  }}
                >
                  <Col
                    xs={{ span: 22, offset: 1 }}
                    sm={{ span: 22, offset: 1 }}
                    md={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 12, offset: 6 }}
                  >
                    <Button
                      disabled={!submittable}
                      htmlType="submit"
                      style={{
                        backgroundColor: submittable ? "black" : "gray",
                        fontSize: 18,
                        fontWeight: "bold",
                        color: "white",
                        width: "100%",
                        height: 48,
                      }}
                    >
                      {submitButtonTitle}
                    </Button>
                  </Col>
                </Popconfirm>
              </Col>
            </Form>
          </Col>
        </Col>
      ) : null}
    </div>
  );
};

export default CataloguePaymentForm;
