import { Col, Radio, Row, Space, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { useCarts } from "../../contexts/Carts";
import Title from "antd/es/typography/Title";

const { Text } = Typography;

const TakeoutMethodSelection = ({
  stadiumData,
  takeoutMethod,
  setTakeoutMethod,
}) => {
  useEffect(() => {
    setInitialTakeoutMethod();
  }, [stadiumData]);

  const { multiCart } = useCarts();

  const setInitialTakeoutMethod = () => {
    if (!stadiumData) return;
    const { nowDoDelivery, nowDoPickup, nowDoInHouse } = stadiumData;

    const defaultInhouse = localStorage.getItem("inhouseDirect");

    if (nowDoInHouse && defaultInhouse) {
      setTakeoutMethod("inhouse");
      return;
    }

    if (nowDoDelivery) {
      setTakeoutMethod("delivery");
      return;
    }
    if (nowDoPickup) {
      setTakeoutMethod("pickup");
      return;
    }
    if (nowDoInHouse) {
      setTakeoutMethod("inhouse");
      return;
    }
  };

  if (stadiumData) {
    let { nowDoDelivery, nowDoPickup, nowDoInHouse, _id } = stadiumData;

    const currentCart = multiCart.find((cart) => cart.stadiumID === _id);

    if (!currentCart) {
      return null;
    }

    let pickupLabel = "Pickup";
    if (currentCart.stadTypes.includes("STT_VENUE")) {
      if (currentCart.eventItems) {
        pickupLabel = "Event Admission Purchase";
      }
      if (currentCart.foodItems && currentCart.eventItems) {
        pickupLabel = "Event Admission Purchase & Food Pre-ordering";
      }
      // if (currentCart.foodItems && !currentCart.eventItems) {
      //   nowDoPickup = false;
      //   setTakeoutMethod('inhouse');
      // }
      if (currentCart.foodItems && !currentCart.eventItems && !nowDoInHouse) {
        pickupLabel = "Food Pre-ordering";
      }
    }

    let takeoutMethods = [
      {
        key: 0,
        label: "Delivery",
        value: "delivery",
        currentlyAvailable: nowDoDelivery,
      },
      {
        key: 1,
        label: pickupLabel,
        value: "pickup",
        currentlyAvailable: nowDoPickup,
      },
      {
        key: 2,
        label: "In-House",
        value: "inhouse",
        currentlyAvailable: nowDoInHouse,
      },
    ];

    takeoutMethods = takeoutMethods.filter(
      (method) => method.currentlyAvailable
    );

    return (
      <Row justify="center" style={{ marginTop: 20 }}>
        <Col xl={12} lg={18} md={22} xs={24}>
          <Title style={{ fontSize: 18, marginBottom: 0 }}>
            Takeout Selection
          </Title>
          <Text>Tell us how you'd like to receive your order</Text>
          <Radio.Group
            value={takeoutMethod}
            onChange={(e) => setTakeoutMethod(e.target.value)}
            defaultValue={takeoutMethods[0].value}
            style={{ width: "100%", marginTop: 12 }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              {takeoutMethods.map((method) => (
                <Radio
                  value={method.value}
                  key={method.key}
                  style={{
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "lightgray",
                    borderRadius: 8,
                    padding: 16,
                    fontSize: 18,
                    width: "100%",
                  }}
                >
                  {method.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default TakeoutMethodSelection;
