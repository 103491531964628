import { Col, Image, Typography, theme } from "antd";
import React, { useState } from "react";
import FoodItemModal from "./FoodItemModal";
import { ReactComponent as FoodPlaceholder } from "../assets/foodPlaceholder.svg";
import _ from "lodash";

const { Text } = Typography;
const { useToken } = theme;

const FoodItem = ({ categoryItem, item, stadium, stadiumCartFoodItems }) => {
  const [showFoodItemModal, setShowFoodItemModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  let quantityPurchased = 0;
  // this gives us a cart array with all matching items
  const filteredItemArray = stadiumCartFoodItems.filter(
    (foodItem) => foodItem.foodItemId === item._id
  );
  for (let foodItem of filteredItemArray) {
    // Add all quantities from all instances of FoodItem
    quantityPurchased += foodItem.quantityToPurchase;
  }
  const { token } = useToken();

  const viewSelectedItem = (item) => {
    setCurrentItem(item);
    setShowFoodItemModal(true);
  };
  return (
    <>
      <Col
        style={{ display: "flex", cursor: "pointer" }}
        key={item._id}
        xl={6}
        lg={8}
        md={8}
        onClick={() => viewSelectedItem(item)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            borderWidth: quantityPurchased > 0 ? 2 : 1,
            borderColor:
              quantityPurchased > 0 ? token.colorSecondary : "lightgray",
            borderStyle: "solid",
            borderRadius: 12,
          }}
        >
          {item.fXQImageURL ? (
            <Image
              wrapperStyle={{ width: "-webkit-fill-available" }}
              preview={false}
              src={item.fXQImageURL}
              style={{
                width: "-webkit-fill-available",
                height: 200,
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
                borderBottomLeftRadius: 0,
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
                backgroundColor: "black",
              }}
            />
          ) : null}
          {!item.fXQImageURL && !item.doNotShowPlaceholder ? (
            <FoodPlaceholder
              style={{
                height: 200,
                width: "100%",
                fill: "darkgray",
                backgroundColor: "lightgray",
                borderTopLeftRadius: 12,
                borderTopRightRadius: 12,
              }}
            />
          ) : null}

          {quantityPurchased > 0 ? (
            <div
              style={{
                borderRadius: "50%",
                backgroundColor: "black",
                width: 36,
                height: 36,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 10,
                right: 20,
                borderColor: "#FFFFFF",
                borderWidth: 2,
                borderStyle: "solid",
              }}
            >
              <Text
                style={{
                  color: "#FFFFFF",
                  fontWeight: "bold",
                }}
              >
                {quantityPurchased > 0 ? `${quantityPurchased}` : ``}
              </Text>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 18,
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text
                ellipsis
                style={{
                  fontSize: 14,
                  color: "grey",
                }}
              >
                {categoryItem.name}
              </Text>
              <Text
                style={{
                  fontSize: 20,
                  color: "#000000",
                  fontWeight: "600",
                }}
              >
                {item.name}
              </Text>
              <div
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Text
                  style={{
                    fontSize: 14,
                    color: "grey",
                  }}
                >
                  {item.fXQDesc}
                </Text>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginBottom: 4,
                marginRight: 4,
                marginTop: 20,
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  ${item.price.toFixed(2)}
                </Text>

                <img
                  style={{
                    width: 20,
                    height: 20,
                    fill: "black",
                    marginLeft: 6,
                  }}
                  src={`https://extra-getit.s3.amazonaws.com/whitelabel-icons/external.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
      <FoodItemModal
        showFoodItemModal={showFoodItemModal}
        setShowFoodItemModal={setShowFoodItemModal}
        currentItem={currentItem}
        categoryItem={categoryItem}
        stadium={stadium}
      />
    </>
  );
};

export default FoodItem;
