import { Row, Typography, Image, Col, Divider, Button, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { deliveryFee } from "../const/const_vars.js";
import { FaInfoCircle } from "react-icons/fa";
import {
  mom4tzDateOnly,
  mom4tzTimeOnly,
  formatXDay,
  formatXTime,
} from "../const/const_functions";
// import Logo from "../assets/images/getit_red.png";
// import RateOrderModal from './RateOrderModal.jsx';
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths, roundTo3DecimalPlaces } from "../const/const_functions";
import { useBrandContext } from "../contexts/Brand.js";
import "add-to-calendar-button";
import moment from "moment-timezone";

const OrderStatusDetails = ({ order, stadium }) => {
  const { brandContextData } = useBrandContext();
  const { Text } = Typography;
  const {
    address,
    createdAt,
    note,
    deliveryComment,
    inhouseClientPickup,
    inhouseTableNumber,
    eventAdmissions: events,
    omprodItems,
    tipping,
    paymentInfo,
    zitPromos,
    ORDER_QTYPE,
    id,
  } = order;
  const { applicableFees, applicableTaxes, paymentTimeZone } = paymentInfo;

  let seatingType = "Table";
  if (order?.stadium?.inhouseSeatingType) {
    seatingType = order.stadium.inhouseSeatingType;
  }

  const dateOrderPlaced = formatXDay(new Date(createdAt));
  const timeOrderPlaced = formatXTime(new Date(createdAt));
  // const [showRateOrderModal, setShowRateOrderModal] = useState(false);
  let total = 0;
  let deliveryOption = order.deliveryOption;
  let v2_foodCart = order.v2_foodCart ? order.v2_foodCart : [];

  // let foundHSTinObj = null;
  // if (paymentInfo && paymentInfo.hst && typeof paymentInfo.hst === "string") {
  //   foundHSTinObj = parseFloat(paymentInfo.hst);
  // }

  let serviceFee = 0;
  if (
    paymentInfo &&
    paymentInfo.merchantServiceFee &&
    paymentInfo.merchantServiceFee !== 0
  ) {
    serviceFee = parseFloat(paymentInfo.merchantServiceFee);
  }

  if (deliveryOption === "pickup" && ORDER_QTYPE === "QVENUE") {
    // Ticket or Preorder
    deliveryOption = "event admission purchase";
  }
  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  const showFinalTotal = (total) => {
    let order = paymentInfo.orderAmount;

    let orderToDisplay = order;
    let promotionalDiscounts = 0;
    if (zitPromos) {
      promotionalDiscounts = zitPromos.zitSumOfDiscounts;
    }

    let backendDeliveryfee = deliveryFee;
    if (paymentInfo && paymentInfo.deliveryFee) {
      backendDeliveryfee = paymentInfo.deliveryFee;
    }

    let pendingTotal =
      deliveryOption === "delivery"
        ? Number(backendDeliveryfee) + total + promotionalDiscounts
        : total + promotionalDiscounts;

    let minPendingAmount = false;
    if (paymentInfo.totalAmount === "1.00") {
      pendingTotal = 1;
      minPendingAmount = true;
    }

    let taxToDisplay = paymentInfo.hst;

    let FinalTotal = paymentInfo.totalAmount;

    let tipGiven = tipping.amount;

    let totalWithTip = Number(FinalTotal) + tipGiven;
    return (
      <Col className="subtotalCol">
        <Row className="subtotalRow">
          <Text className="totalGray">Subtotal</Text>
          <Text className="totalGray">${orderToDisplay}</Text>
        </Row>

        {/* {deliveryOption === "delivery" ? (
          <Row className="subtotalRow">
            <Text className="totalGray">Delivery Fee</Text>
            <Text className="totalGray">
              ${Number(backendDeliveryfee).toFixed(2)}
            </Text>
          </Row>
        ) : null} */}
        {zitPromos && zitPromos.zitAppliedPromos.length > 0 ? (
          <Row className="subtotalRow">
            <Text className="totalGray">Promotions</Text>
            <Text className="totalGray">
              -${Math.abs(promotionalDiscounts).toFixed(2)}
            </Text>
          </Row>
        ) : null}

        {applicableFees?.length ? (
          <Row className="subtotalRow">
            <Row style={{ alignItems: "center" }}>
              <Text className="totalGray" style={{ marginRight: 4 }}>
                Fees
              </Text>
              <Tooltip
                overlayStyle={{ minWidth: "275px" }}
                title={
                  <>
                    {applicableFees?.map((feeItem, index) => (
                      <Row
                        key={index}
                        style={{ justifyContent: "space-between" }}
                      >
                        <Row>
                          <span style={{ marginRight: 4 }}>
                            {feeItem.feeLabel}
                          </span>
                          {feeItem.feePercentMultiply &&
                          feeItem.feeCentsDecimal ? (
                            <span>
                              (
                              {roundTo3DecimalPlaces(
                                feeItem.feePercentMultiply * 100
                              )}
                              % + ${feeItem?.feeCentsDecimal?.toFixed(2)})
                            </span>
                          ) : null}

                          {feeItem.feePercentMultiply &&
                          !feeItem.feeCentsDecimal ? (
                            <span>
                              (
                              {roundTo3DecimalPlaces(
                                feeItem.feePercentMultiply * 100
                              )}
                              %)
                            </span>
                          ) : null}

                          {!feeItem.feePercentMultiply &&
                          feeItem.feeCentsDecimal ? (
                            <span>
                              (${feeItem?.feeCentsDecimal?.toFixed(2)})
                            </span>
                          ) : null}
                        </Row>

                        <span>${feeItem?.calculatedFeeAmount?.toFixed(2)}</span>
                      </Row>
                    ))}
                  </>
                }
              >
                <FaInfoCircle
                  name="info"
                  style={{ fontSize: 15, color: "gray" }}
                />
              </Tooltip>
            </Row>

            <Text className="totalGray">${paymentInfo.locationFees}</Text>
          </Row>
        ) : null}

        {brandContextData._id !== "64b80d8a5bdf376def855b1e" ? (
          <Row className="subtotalRow">
            <Row style={{ alignItems: "center" }}>
              <Text className="totalGray" style={{ marginRight: 4 }}>
                Tax
              </Text>
              <Tooltip
                overlayStyle={{ minWidth: "250px" }}
                title={
                  <>
                    {applicableTaxes?.map((taxItem, index) => (
                      <>
                        <Row style={{ justifyContent: "space-between" }}>
                          <Row>
                            <span style={{ marginRight: 4 }}>
                              {taxItem.taxLabel}
                            </span>
                            <span>
                              (
                              {roundTo3DecimalPlaces(
                                taxItem.taxPercentMultiply * 100
                              )}
                              %)
                            </span>
                          </Row>

                          <span>${taxItem.calculatedTaxAmount.toFixed(2)}</span>
                        </Row>
                      </>
                    ))}
                  </>
                }
              >
                <FaInfoCircle
                  name="info"
                  style={{ fontSize: 15, color: "gray" }}
                />
              </Tooltip>
            </Row>

            <Text className="totalGray">${taxToDisplay}</Text>
          </Row>
        ) : null}

        {serviceFee !== 0 ? (
          <Row className="subtotalRow" style={{ marginBottom: 15 }}>
            <Text className="totalGray">Digital Merchant Fee</Text>
            <Text className="totalGray">${serviceFee}</Text>
          </Row>
        ) : null}

        {paymentInfo?.pointAdjustmentsForLoyalty?.actualPointsSpent > 0 ? (
          <Row
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Text className="totalGray">
              {paymentInfo.pointAdjustmentsForLoyalty.pointsLabel} Redeemed
            </Text>
            <Text className="totalGray">
              -$
              {paymentInfo.pointAdjustmentsForLoyalty.actualPointsDiscountAmount.toFixed(
                2
              )}
            </Text>
          </Row>
        ) : null}

        {minPendingAmount ? (
          <Row className="subtotalRow" style={{ marginBottom: 10 }}>
            <Text className="totalGray">Minimum Order amount</Text>
            <Text className="totalGray">$1.00</Text>
          </Row>
        ) : null}

        {tipGiven && tipGiven !== 0 ? (
          <>
            {/* <Row className="subtotalRow" style={{ marginBottom: 15 }}>
              <Text className="totalBlack">Subtotal</Text>
              <Text className="totalBlack" style={{ fontWeight: "bold" }}>
                ${FinalTotal}
              </Text>
            </Row> */}
            <Row
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <Text className="totalGray">Tip</Text>
              <Text className="totalGray">${tipGiven.toFixed(2)}</Text>
            </Row>
            <Divider style={{ margin: 0 }} />
            <Row className="subtotalRow" style={{ paddingTop: 15 }}>
              <Text className="totalBlack">Total</Text>
              <Text className="totalBlack" style={{ fontWeight: "bold" }}>
                ${totalWithTip.toFixed(2)}
              </Text>
            </Row>
          </>
        ) : (
          <>
            {brandContextData._id !== "64b80d8a5bdf376def855b1e" ? (
              <Divider style={{ margin: 0 }} />
            ) : null}
            <Row
              className="subtotalRow"
              style={{
                paddingTop: 15,
              }}
            >
              <Text className="totalBlack">Total</Text>
              <Text className="totalBlack" style={{ fontWeight: "bold" }}>
                ${FinalTotal}
              </Text>
            </Row>
          </>
        )}
      </Col>
    );
  };

  return (
    <Content
      style={{
        marginTop: 40,
      }}
    >
      <Row className="logoContainer">
        {stadium.imageUrl ? (
          <Image src={stadium.imageUrl} style={{ width: mobile ? 50 : 60 }} />
        ) : null}
        <div className="dateContainer">
          <Text className="dateText">{timeOrderPlaced}</Text>
          <Text className="timeText">{dateOrderPlaced}</Text>
        </div>
      </Row>
      <Text className="orderText">Your order</Text>
      <Divider />
      {/* {deliveryOption === "delivery" ? (
        <Col className="detailsCol">
          <Row className="detailsRow">
            <Text className="detailTitle">Your Address</Text>
            <Text className="detailData">{address}</Text>
          </Row>
          <Row className="detailsRow">
            <Text className="detailTitle">Delivery Comment</Text>
            <Text className="detailData">{deliveryComment}</Text>
          </Row>
          <Row className="detailsRow">
            <Text className="detailTitle">Order Note</Text>
            <Text className="detailData">{note}</Text>
          </Row>
        </Col>
      ) : ( */}
      <>
        {deliveryOption === "inhouse" && (
          <Col className="inhouseContainer">
            {inhouseClientPickup ? (
              <Text style={{ paddingTop: 10 }}>
                Pickup at: Designated Pickup Location
              </Text>
            ) : (
              <Text className="detailTitle">
                {seatingType}: {inhouseTableNumber}
              </Text>
            )}
          </Col>
        )}
      </>
      {/* )} */}
      {v2_foodCart && v2_foodCart.length > 0 && (
        <>
          {v2_foodCart.map((foodItem, index) => {
            let addOnCost = 0;
            const ItemsOptionGroup = [];
            if (foodItem.optionGroups) {
              foodItem.optionGroups.forEach((mod) => {
                ItemsOptionGroup.push(
                  <Text className="groupName">{mod.optionGroupName}:</Text>
                );
                mod.optionsSelected.forEach((option) => {
                  ItemsOptionGroup.push(
                    <Text className="optionSelected">
                      {option.optionObjName} + $
                      {option.optionObjExtraCost.toFixed(2)}
                    </Text>
                  );
                  addOnCost = addOnCost + option.optionObjExtraCost;
                });
              });
              total +=
                parseFloat(foodItem.price) * parseInt(foodItem.quantity) +
                addOnCost * parseInt(foodItem.quantity);
            }

            return (
              <Row key={`${foodItem._id}${index}`} className="orderItemRow">
                <Col style={{ flex: 8 }}>
                  <Row
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text className="foodItemName">{foodItem.name}</Text>
                    <Text className="foodItemPrice">
                      {foodItem.quantity +
                        " x $" +
                        Number(foodItem.price).toFixed(2)}
                    </Text>
                  </Row>
                  <>
                    <Col className="modContainer">
                      {ItemsOptionGroup.map((item, index) => {
                        return <Row key={index}>{item}</Row>;
                      })}
                    </Col>
                  </>
                </Col>
              </Row>
            );
          })}
        </>
      )}
      {/* Check if the user bought event admissions! */}
      {events && events.length > 0 && (
        <>
          {events.map((event) => {
            const eventResult = [];
            for (const admission of event.selectedAdmissions) {
              let evBaseCost = admission.evAdmisPricePerAdmission;
              // if (brandContextData._id === "64b80d8a5bdf376def855b1e") {
              //   evBaseCost = admission.evAdmisPricePerAdmission * 1.13;
              // }

              total +=
                parseFloat(admission.evAdmisPricePerAdmission) *
                parseInt(admission.evAdmisQuantityOrdered);
              eventResult.push(
                <Row
                  justify="space-between"
                  key={admission.evAdmisId}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text className="admName">{admission.evAdmisName}</Text>
                  <Text className="admPrice">
                    {admission.evAdmisQuantityOrdered} x $
                    {evBaseCost.toFixed(2)}
                  </Text>
                </Row>
              );
            }
            let formattedDateWithTimeZone = null;
            let formattedStartTime = null;
            let formattedOpeningTime = null;
            let formattedEndTime = null;
            if (event.evOperatingTime) {
              if (event.evOperatingTime.evStartTime) {
                const eventStartTime = event.evOperatingTime.evStartTime;
                formattedDateWithTimeZone = mom4tzDateOnly(
                  new Date(eventStartTime).getTime(),
                  paymentTimeZone
                );
                formattedStartTime = mom4tzTimeOnly(
                  new Date(eventStartTime).getTime(),
                  paymentTimeZone
                );
              }
              if (event.evOperatingTime.evOpening) {
                const eventOpeningTime = event.evOperatingTime.evOpening;
                formattedOpeningTime = mom4tzTimeOnly(
                  new Date(eventOpeningTime).getTime(),
                  paymentTimeZone
                );
              }
              if (event.evOperatingTime.evEndTime) {
                const eventEndTime = event.evOperatingTime.evEndTime;
                formattedEndTime = mom4tzTimeOnly(
                  new Date(eventEndTime).getTime(),
                  paymentTimeZone
                );
              }
            }

            let addToCalStartDate = moment(event.evOperatingTime.evStartTime)
              .tz(paymentTimeZone)
              .format("YYYY-MM-DD");
            let addToCalEndDate = moment(event.evOperatingTime.evEndTime)
              .tz(paymentTimeZone)
              .format("YYYY-MM-DD");
            let addToCalStartTime = moment(event.evOperatingTime.evStartTime)
              .tz(paymentTimeZone)
              .format("HH:mm");
            let addToCalEndTime = moment(event.evOperatingTime.evEndTime)
              .tz(paymentTimeZone)
              .format("HH:mm");
            return (
              <div key={event.evId} style={{ paddingTop: 20 }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <div>
                    <Text className="eventName">{event.evName}</Text>
                    {formattedDateWithTimeZone && (
                      <Row style={{ display: "flex", flexDirection: "column" }}>
                        <Text className="eventTime">{`${formattedDateWithTimeZone} || ${formattedStartTime} - ${formattedEndTime}`}</Text>
                        {formattedOpeningTime &&
                          formattedOpeningTime !== formattedStartTime && (
                            <Text className="eventTime">{`Doors Open: ${formattedOpeningTime}`}</Text>
                          )}
                      </Row>
                    )}
                  </div>
                  <add-to-calendar-button
                    name={event.evName}
                    description={event.evDesc}
                    startDate={addToCalStartDate}
                    endDate={addToCalEndDate}
                    startTime={addToCalStartTime}
                    endTime={addToCalEndTime}
                    location={event.evAddress.physical}
                    options="['Apple','Google','iCal','Microsoft365','Outlook.com','Yahoo']"
                    timeZone={paymentTimeZone}
                    trigger="click"
                    inline
                    listStyle={mobile ? "modal" : "dropdown"}
                    iCalFileName="Reminder-Event"
                    buttonStyle="round"
                    hideTextLabelButton={mobile}
                  />
                </Row>

                <Col style={{ paddingTop: 15 }}>
                  {eventResult.map((event, index) => {
                    return <Row key={`index${index}`}>{event}</Row>;
                  })}
                </Col>
              </div>
            );
          })}
        </>
      )}
      {/* Check if the user bought merch items! */}
      {omprodItems && omprodItems.length > 0 && (
        <>
          {omprodItems.map((prodItem) => {
            let selectedVariant = [];
            if (!prodItem.isSimpleProduct) {
              selectedVariant = prodItem.selectedVeep.selDarded.map(
                (selectedV) => {
                  return (
                    <Col
                      className="prodItemContainer"
                      key={selectedV.selname}
                      style={{
                        display: "flex",
                        marginBottom: 20,
                      }}
                    >
                      <Text style={{ fontWeight: "bold", color: "gray" }}>
                        {selectedV.selname}
                      </Text>
                      <Text style={{ fontStyle: "italic", paddingLeft: 10 }}>
                        {selectedV.selvalue}
                      </Text>
                    </Col>
                  );
                }
              );
            }
            total +=
              (parseFloat(prodItem.omPriceCents) * parseInt(prodItem.omQty)) /
              100;

            return (
              <Row key={prodItem.retailItemId}>
                <div style={{ flex: 8 }}>
                  <Row
                    style={{
                      marginBottom: !prodItem.isSimpleProduct ? 0 : 20,
                    }}
                  >
                    <Col span={18}>
                      <Text className="foodItemName">{prodItem.omPtitle}</Text>
                    </Col>
                    <Col span={6} style={{ textAlign: "right" }}>
                      <Text className="foodItemPrice">
                        {prodItem.omQty +
                          " x $" +
                          (Number(prodItem.omPriceCents) / 100).toFixed(2)}
                      </Text>
                    </Col>
                  </Row>
                  {selectedVariant}
                </div>
              </Row>
            );
          })}
        </>
      )}
      <Divider />
      {showFinalTotal(total)}
      {/* <Row className="rateOrderButtonRow">
        <Button
          type="primary"
          className="rateOrderButton"
          onClick={() => setShowRateOrderModal(true)}
        >
          Rate Your Order
        </Button>
      </Row> */}
      {/* <RateOrderModal
        showRateOrderModal={showRateOrderModal}
        setShowRateOrderModal={setShowRateOrderModal}
        orderId={id}
      /> */}
    </Content>
  );
};

export default OrderStatusDetails;
