import { Col, Form, Input, Row, Switch, Typography } from "antd";
import React, { useEffect } from "react";
import { useBrandContext } from "../../contexts/Brand";

const CheckoutInHouseSelection = ({
  inhouseSeatingType,
  usePickupLocation,
  setUsePickupLocation,
  tableNumber,
  setTableNumber,
  inhouseOrderOptions,
  stadiumData,
}) => {
  const { Text } = Typography;
  const { brandContextData } = useBrandContext();

  const inSeat = inhouseOrderOptions?.inSeat
    ? inhouseOrderOptions?.inSeat
    : false;
  const clientPickup = inhouseOrderOptions?.clientPickup
    ? inhouseOrderOptions?.clientPickup
    : false;

  const renderTableNumberInput = () => {
    let seatingType = `${inhouseSeatingType} Number`;

    if (brandContextData._id === "64d5090ea50ac027dda40204") {
      seatingType = "Seat Number";
    }

    return (
      <Form layout="vertical">
        <Form.Item
          initialValue={tableNumber}
          colon={false}
          label={
            <Text style={{ fontSize: 18, fontWeight: "bold" }}>
              {seatingType}
            </Text>
          }
          name="tableNumber"
          rules={[
            {
              required: true,
              message: `Please input your ${inhouseSeatingType.toLowerCase()} number!`,
            },
          ]}
        >
          <Input
            size="large"
            placeholder="ex. 22"
            value={tableNumber}
            onChange={(e) => setTableNumber(e.target.value)}
            disabled={usePickupLocation}
          />
        </Form.Item>
      </Form>
    );
  };

  const renderPickupToggle = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row
            style={{
              height: "100%",
              justifyContent:
                stadiumData?._id === "f6fquSWYZXQqtmS2t" ? "center" : "initial",
            }}
            align="middle"
          >
            {inSeat ? (
              <Text style={{ paddingRight: 10 }}>
                Or, pickup at the designated location
              </Text>
            ) : (
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                  marginBottom: 12,
                }}
              >
                {stadiumData?._id === "f6fquSWYZXQqtmS2t"
                  ? "Thank you for tipping"
                  : "When your order is ready, please collect it at the designated In-House pickup location"}
              </Text>
            )}
            {inSeat ? (
              <Switch
                style={{
                  backgroundColor: !usePickupLocation
                    ? "lightgrey"
                    : brandContextData.brandTheme.colorSecondary,
                }}
                onChange={() => setUsePickupLocation(!usePickupLocation)}
              />
            ) : null}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Row
      justify="center"
      style={{
        paddingTop: 40,
      }}
    >
      <Col xl={12} lg={18} md={22} xs={24}>
        {inSeat ? renderTableNumberInput() : null}
        {!(inSeat && !clientPickup) ? renderPickupToggle() : null}
      </Col>
    </Row>
  );
};

export default CheckoutInHouseSelection;
