import { Typography, Row, Col, Button, Divider, theme } from "antd";
import styles from "../styles/EventItemModal.module.css";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import { useState, useEffect } from "react";
import { useBrandContext } from "../contexts/Brand";

const { useToken } = theme;
const { Text } = Typography;

const AdmissionTypeList = ({
  currentItem,
  admissionPurchaseQuantities,
  setAdmissionPurchaseQuantities,
  setPurchaseQuantityFlag,
}) => {
  const [eventItem, setEventItem] = useState(currentItem);
  const { token } = useToken();
  const { brandContextData } = useBrandContext();

  useEffect(() => {
    const quantityFilter = eventItem.evAdmissionTypes.filter(
      (admP) => admP.purchaseQuantity > 0
    );
    if (quantityFilter.length > 0) {
      setPurchaseQuantityFlag(true);
    } else {
      setPurchaseQuantityFlag(false);
    }
  }, [eventItem]);

  const onClickMinus = (evAdmisId) => {
    let apqModded = admissionPurchaseQuantities;

    // Find the admission to decrease
    // Decrease if found
    const admToDec = apqModded.find((admP) => admP.evAdmisId === evAdmisId);
    if (admToDec) admToDec.quantityToPurchase = admToDec.quantityToPurchase - 1;

    // If quantity to purchase is 0 remove it from the admissionPurchaseQuantities array
    apqModded = apqModded.filter((element) => {
      return element.quantityToPurchase !== 0;
    });

    setAdmissionPurchaseQuantities(apqModded);

    //Update eventItem obj
    const newEventItem = eventItem.evAdmissionTypes.map((admP) =>
      admP.evAdmisId === evAdmisId
        ? {
            ...admP,
            purchaseQuantity: admP.purchaseQuantity
              ? admP.purchaseQuantity - 1
              : 0,
          }
        : admP
    );
    setEventItem({ ...eventItem, evAdmissionTypes: newEventItem });
  };

  const onClickPlus = (admisType) => {
    const {
      evAdmisId,
      evAdmisBasePrice,
      evAdmisQuantity,
      evAdmisName,
      evAdmisCustMax,
    } = admisType;
    let apqModded = admissionPurchaseQuantities;

    if (evAdmisQuantity === 0) return;

    if (apqModded.length === 0) {
      apqModded.push({
        evAdmisId,
        quantityToPurchase: 1,
        evAdmisBasePrice,
        evAdmisQuantity,
        evAdmisName,
        evAdmisCustMax,
      });
    } else {
      var index = apqModded.findIndex((x) => x.evAdmisId === evAdmisId);

      if (index === -1) {
        apqModded.push({
          evAdmisId,
          quantityToPurchase: 1,
          evAdmisBasePrice,
          evAdmisQuantity,
          evAdmisName,
          evAdmisCustMax,
        });
      } else {
        const admToInc = apqModded.find((admP) => admP.evAdmisId === evAdmisId);
        if (admToInc && admToInc.quantityToPurchase < evAdmisQuantity) {
          admToInc.quantityToPurchase = admToInc.quantityToPurchase + 1;
        } else return;
      }
    }
    setAdmissionPurchaseQuantities(apqModded);

    //Update eventItem obj
    const newEventItem = eventItem.evAdmissionTypes.map((admP) =>
      admP.evAdmisId === evAdmisId
        ? {
            ...admP,
            purchaseQuantity: admP.purchaseQuantity
              ? admP.purchaseQuantity + 1
              : 1,
          }
        : admP
    );
    setEventItem({ ...eventItem, evAdmissionTypes: newEventItem });
  };

  return (
    eventItem.evAdmissionTypes.length > 0 &&
    eventItem.evAdmissionTypes.map((admisType) => {
      if (admisType.evAdmisActive) {
        let quantityFlag = false;
        if (admisType.purchaseQuantity > 0) {
          quantityFlag = true;
        }

        let isPlusButtonDisable = false;
        if (
          admisType.evAdmisCustMax &&
          admisType.purchaseQuantity >= admisType.evAdmisCustMax
        ) {
          isPlusButtonDisable = true;
        }

        let almostSoldOutText = null;
        if (admisType.evAdmisQuantity < 5 && admisType.evAdmisQuantity !== 0) {
          almostSoldOutText = (
            <Row>
              <Text className={styles.admRedText}>
                Hurry only {admisType.evAdmisQuantity}{" "}
                {admisType.evAdmisName.toLowerCase()} left!
              </Text>
            </Row>
          );
        }

        let soldOutText = null;
        if (admisType.evAdmisQuantity === 0) {
          soldOutText = (
            <Row>
              <Text className={styles.admRedText}>Sold Out!</Text>
            </Row>
          );
        }

        let maxTicketsText = null;
        if (admisType.evAdmisCustMax) {
          maxTicketsText = (
            <Row>
              <Text
                className={styles.admMaxText}
              >{`Maximum ${admisType.evAdmisCustMax} per customer.`}</Text>
            </Row>
          );
        }

        let basePrice = admisType.evAdmisBasePrice;
        // if (brandContextData._id === "64b80d8a5bdf376def855b1e") {
        //   basePrice = admisType.evAdmisBasePrice * 1.13;
        // }

        return (
          <Col key={admisType.evAdmisId}>
            <Row>
              <Text className={styles.admName}>{admisType.evAdmisName}</Text>
            </Row>
            {admisType.evAdmisDesc && admisType.evAdmisDesc.trim() !== "" ? (
              <Row>
                <Text className={styles.admDesc}>{admisType.evAdmisDesc} </Text>
              </Row>
            ) : null}
            {soldOutText ? soldOutText : maxTicketsText}
            {almostSoldOutText}
            <Row style={{ paddingTop: 10 }}>
              <Text className={styles.admPrice}>
                ${Number(basePrice).toFixed(2)}
              </Text>
              <Text style={{ alignSelf: "flex-end", paddingBottom: 4 }}>
                /each
              </Text>
            </Row>
            <Row className={styles.rowCenter}>
              <Col
                className={styles.quantityCol}
                xs={{ span: 22, offset: 1 }}
                md={{ span: 16, offset: 4 }}
              >
                <Button
                  style={{
                    borderColor: quantityFlag
                      ? token.colorPrimary
                      : "lightgray",
                    boxShadow: "2px 3px 4px #ccc",
                  }}
                  className={styles.quantityButtonMinus}
                  disabled={!quantityFlag}
                  onClick={() => onClickMinus(admisType.evAdmisId)}
                >
                  <CgMathMinus className={styles.quantityIcon} />
                </Button>
                <Text className={styles.quantityText}>
                  {admisType.purchaseQuantity ? admisType.purchaseQuantity : 0}
                </Text>
                <Button
                  style={{
                    boxShadow: "2px 3px 4px #ccc",
                    borderColor: isPlusButtonDisable
                      ? "lightgray"
                      : token.colorPrimary,
                  }}
                  disabled={isPlusButtonDisable}
                  className={styles.quantityButton}
                  onClick={() => onClickPlus(admisType)}
                >
                  <CgMathPlus className={styles.quantityIcon} />
                </Button>
              </Col>
            </Row>
            <Divider />
          </Col>
        );
      }
    })
  );
};

export default AdmissionTypeList;
