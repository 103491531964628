import React from "react";
import { Row, Col, Typography, Checkbox, Divider, theme } from "antd";
import styles from "../styles/FoodItemModal.module.css";
const { Text } = Typography;
const { useToken } = theme;

const FoodItemOptions = ({
  optionGroup,
  updateFoodModal,
  currentItem,
  showDivider,
}) => {
  const { token } = useToken();

  const onClickOption = (option) => {
    if (optionGroup.isMultiSelect) {
      if (
        optionGroup.isRequired &&
        optionGroup.zOptions.filter((e) => e.isSelected).length === 0
      ) {
        option.isSelected = true;
      }
    } else {
      // Check the other zOptions if any is clicked
      if (optionGroup.isRequired) {
        // Check if any option is selected
        if (optionGroup.zOptions.filter((e) => e.isSelected).length > 0) {
          // Check if the option we currently have selected is the one
          if (option.isSelected) {
            optionGroup.zOptions.map((o) => (o.isSelected = false));
            option.isSelected = true;
          } else {
            option.isSelected = true;
          }
        } else {
          option.isSelected = true;
        }
      } else {
        if (option.isSelected) {
          optionGroup.zOptions.map((o) => (o.isSelected = false));
          option.isSelected = true;
        }
      }
    }
    updateAddOnCost();
  };

  const updateAddOnCost = () => {
    // Initial cost is 0
    let addOnCost = 0;

    // Loop through all of the optiongroups and all of the options
    // inside of the option groups and check to see which ones have 'isSelected'
    // set to 'true'... If they are, then add the optionObjExtraCost to the total addOnCost
    const { optionGroups } = currentItem;
    for (const optionGroup of optionGroups) {
      const { zOptions } = optionGroup;
      for (const option of zOptions) {
        if (option.isSelected) {
          addOnCost = addOnCost + option.optionObjExtraCost;
        }
      }
    }

    // Call the function in FoodItemModal which will update the state
    // and re-render this component

    updateFoodModal(addOnCost);
  };

  return (
    <div>
      {optionGroup.zOptions.length &&
        optionGroup.zOptions.map((option, index) => {
          return (
            <Row
              key={option.optionObjId}
              onClick={() => {
                option.isSelected = option.isSelected
                  ? !option.isSelected
                  : true;
                onClickOption(option);
              }}
              className={styles.optionRow}
            >
              <Row
                style={{
                  height: "35px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={styles.scale}
              >
                <Col xs={{ span: 16 }} md={{ span: 16 }}>
                  <Text className={styles.smallText}>
                    {option.optionObjName}
                  </Text>
                </Col>
                <Col
                  xs={{ span: 8 }}
                  md={{ span: 8 }}
                  className={styles.optionColumn}
                >
                  {option.optionObjExtraCost === 0 ? (
                    <Text className={styles.smallText}></Text>
                  ) : (
                    <Text
                      className={styles.smallText}
                    >{`+ $${option.optionObjExtraCost.toFixed(2)}`}</Text>
                  )}
                  <Checkbox
                    key={option.isSelected}
                    checked={option.isSelected}
                    style={{
                      "--background-color": token.colorSecondary,
                      "--border-color": token.colorSecondary,
                    }}
                  />
                </Col>
              </Row>
              {(optionGroup.zOptions.length !== 1 &&
                optionGroup.zOptions.length - 1 !== index) ||
              showDivider ? (
                <Divider className={styles.divider} style={{ marginTop: 0 }} />
              ) : null}
            </Row>
          );
        })}
    </div>
  );
};
export default FoodItemOptions;
