import React from "react";
import { useLocation } from "react-router-dom";
import EventItem from "../components/EventItem";
import { Col, Row } from "antd";

const EventList = () => {
  const location = useLocation();
  const { eventsList, mobile } = location.state;
  const eventListByStadium = Object.groupBy(
    eventsList,
    ({ evStadiumId }) => evStadiumId
  );
  const stadiumIds = Object.keys(eventListByStadium);
  const colorLight = "#ededed";
  const colorDark = "#e0e0e0";

  const renderStadiumEvents = (stadiumEventList) => {
    return (
      <Row
        style={{
          marginRight: mobile ? 20 : 0,
          marginLeft: mobile ? 20 : 0,
        }}
      >
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            paddingTop: 20,
            paddingBottom: 20,
          }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 16, offset: 4 }}
        >
          {stadiumEventList.map((event, index) => {
            return (
              <EventItem
                mobile={mobile}
                event={event}
                index={index}
                customMarginBottom={30}
              />
            );
          })}
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {stadiumIds.map((stadiumId, index) => {
        const stadiumEvents = eventListByStadium[stadiumId];
        return (
          <div
            key={index}
            style={{
              backgroundColor: index % 2 === 0 ? colorLight : colorDark,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: mobile ? 20 : 26,
                fontWeight: "600",
                paddingTop: 20,
              }}
            >
              {stadiumEvents[0].evStadiumName}
            </div>
            {renderStadiumEvents(stadiumEvents)}
          </div>
        );
      })}
    </div>
  );
};

export default EventList;
