import React from "react";
import { Image, Col, Layout, Row, Typography, theme } from "antd";
import { useBrandContext } from "./contexts/Brand";
import { screenWidths } from "./const/const_functions";
import useWindowDimensions from "./hooks/useWindowDimensions";
import "./styles/Footer.css";
const { useToken } = theme;

const WhiteLabelFooter = () => {
  const { Text, Link } = Typography;
  const { Footer } = Layout;
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }
  const { token } = useToken();

  return (
    <Footer
      id="footer"
      style={{
        backgroundColor: token.colorPrimary,
        paddingTop: 0,
      }}
    >
      {!loadingBrandContextData && brandContextData.footerImage ? (
        <Row
          style={{
            backgroundImage: `url(${brandContextData.footerImage})`,
            height: mobile ? 100 : 150,
            width: "100%",
            backgroundSize: "cover",
            backgroundPositionY: "center",
          }}
        ></Row>
      ) : null}
      <Row
        style={{
          paddingTop: 15,
          justifyContent: mobile ? "center" : "end",
          textAlign: "center",
          paddingRight: 50,
          paddingLeft: 50,
        }}
      >
        <div
          style={{ display: mobile ? "grid" : "flex", alignItems: "baseline" }}
        >
          {!loadingBrandContextData && brandContextData.privacyPolicy ? (
            <Col>
              <Link href={brandContextData.privacyPolicy} target={"blank"}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: token.colorTertiary,
                  }}
                >
                  Privacy Policy
                </Text>
              </Link>
              {!mobile ? (
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: token.colorTertiary,
                  }}
                >
                  &nbsp;|&nbsp;
                </Text>
              ) : null}
            </Col>
          ) : null}
          {!loadingBrandContextData && brandContextData.termsOfService ? (
            <Col>
              <Link href={brandContextData.termsOfService} target={"blank"}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: token.colorTertiary,
                  }}
                >
                  Terms of Service
                </Text>
              </Link>
              {!mobile ? (
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    color: token.colorTertiary,
                  }}
                >
                  &nbsp;|&nbsp;
                </Text>
              ) : null}
            </Col>
          ) : null}
          {!loadingBrandContextData ? (
            <Col>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: token.colorTertiary,
                }}
              >
                ©2024 {brandContextData.brandName}
              </Text>
            </Col>
          ) : null}
        </div>
      </Row>
      {/* <Row
        style={{
          paddingTop: 5,
          alignItems: "center",
          paddingRight: 50,
          paddingLeft: 50,
        }}
        justify={mobile ? "center" : "end"}
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 10,
            paddingRight: 3,
            paddingTop: 3,
            color: token.colorTertiary,
          }}
        >
          Powered By
        </Text>
        <Col>
          <Link href="https://getitlocal.app/" target={"blank"}>
            <Image
              width={45}
              preview={false}
              src={require("./assets/images/getit_red.png")}
              className="logoImage"
            />
          </Link>
        </Col>
      </Row> */}
    </Footer>
  );
};

export default WhiteLabelFooter;
