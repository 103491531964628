import React from "react";
import { Row, Col, Typography, Image, Button } from "antd";
import styles from "../styles/InvoicePaymentConfirmation.module.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const InvoicePaymentConfirmation = ({
  paymentID,
  total,
  imageUrl,
  encodedStadiumId,
  orderAmount,
  serviceFee,
  feeLabel,
}) => {
  const navigate = useNavigate();
  return (
    <Col
      xs={{ span: 24 }}
      sm={{ span: 20, offset: 2 }}
      md={{ span: 20, offset: 2 }}
      lg={{ span: 18, offset: 3 }}
      xl={{ span: 16, offset: 4 }}
      className={styles.container}
    >
      {imageUrl ? (
        <Row justify={"center"} className={styles.logoContainer}>
          <Image preview={false} src={imageUrl} className={styles.imageLogo} />
        </Row>
      ) : null}
      <Col className={styles.content}>
        <Row justify={"center"}>
          <BsFillCheckCircleFill className={styles.checkIcon} />
        </Row>
        <Row justify={"center"}>
          <Text className={styles.greenText}>Thank you for your payment!</Text>
        </Row>
        <Row justify={"center"}>
          <Text className={styles.desc}>
            A receipt for this transaction has been sent to your email for your
            records.
          </Text>
        </Row>
        {Number(orderAmount) !== total ? (
          <>
            <Row justify={"center"}>
              <Text className={styles.confirmationText}>
                Subtotal Payment Amount
              </Text>
            </Row>
            <Row justify={"center"}>
              <Text className={styles.total}>
                ${Number(orderAmount).toFixed(2)}
              </Text>
            </Row>
          </>
        ) : null}
        {Number(serviceFee) ? (
          <>
            <Row justify={"center"}>
              <Text className={styles.confirmationText}>
                {feeLabel ? feeLabel : "Digital Service Fee"}
              </Text>
            </Row>
            <Row justify={"center"}>
              <Text className={styles.total}>
                ${Number(serviceFee).toFixed(2)}
              </Text>
            </Row>
          </>
        ) : null}

        <Row justify={"center"}>
          <Text className={styles.confirmationText}>Total Payment Amount</Text>
        </Row>
        <Row justify={"center"}>
          <Text className={styles.total}>${total.toFixed(2)}</Text>
        </Row>
        <Row justify={"center"}>
          <Text className={styles.confirmationText}>
            Invoice Payment Confirmation Number
          </Text>
        </Row>
        <Row justify={"center"}>
          <Text className={styles.confirmationNumber}>{paymentID}</Text>
        </Row>
        <Row justify={"center"} style={{ paddingBottom: 70 }}>
          <Col xl={10} lg={10} md={10} xs={24}>
            <Button
              className={styles.anotherPaymentButton}
              style={{ backgroundColor: "black", color: "white" }}
              onClick={() => navigate(`/${encodedStadiumId}/invoice`)}
            >
              Make Another Payment
            </Button>
          </Col>
        </Row>
        {/* <Row justify={"center"}>
          <Text className={styles.backLink}>Back to Bird Fuels</Text>
        </Row> */}
      </Col>
    </Col>
  );
};

export default InvoicePaymentConfirmation;
