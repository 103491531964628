import React, { useEffect, useState } from "react";
import FoodItemBrowser from "../components/FoodItemBrowser";
import RetailItemBrowser from "../components/RetailItemBrowser";
import StadiumBanner from "../components/StadiumBanner";
import { Layout, Col, Spin, Button, theme, Modal, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "../hooks/useQuery";
import EventItemBrowser from "../components/EventItemBrowser";
import { useCarts } from "../contexts/Carts";
import {
  decodeParamString,
  screenWidths,
  encodeParamString,
} from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useServer } from "../contexts/Server";
import CallServerButton from "../components/CallServerButton";
import { useAuth } from "../contexts/Auth";
const { useToken } = theme;

const StoreFront = () => {
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const [loadingCategoryItems, setloadingCategoryItems] = useState(true);
  const [stadium, setStadium] = useState(null);
  const [tempStadium, setTempStadium] = useState(null);
  const { serverData } = useServer();
  const { multiCart } = useCarts();
  const [isCallServerButtonDisabled, setIsCallServerButtonDisabled] =
    useState(false);
  const [isAnnounceModalOpen, setIsAnnounceModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [storePassword, setStorePassword] = useState("");
  const [activeStorePassword, setActiveStorePassword] = useState("");
  const { authData, loadingAuth } = useAuth();

  useEffect(() => {
    if (isCallServerButtonDisabled) {
      setTimeout(() => setIsCallServerButtonDisabled(false), 30000);
    }
  }, [isCallServerButtonDisabled]);

  useEffect(() => {
    // Check where the user is coming from
    if (!loadingAuth) {
      checkForStadiumId();
    }
  }, [serverData, loadingAuth]);

  const checkForStadiumId = async () => {
    if (!serverData) return;
    let stadium = null;
    if (location && location.state && location.state.stadium) {
      checkStadiumVisibilityToAccounts(location.state.stadium);
      stadium = location.state.stadium;
    } else {
      const id = query.get("glb");
      const decodedStoreId = decodeParamString(id);
      stadium = await serverData.call("USSR_getOneStadium", decodedStoreId);
      checkStadiumVisibilityToAccounts(stadium);
      const inhouseDirect = query.get("inhouse");

      if (inhouseDirect) {
        localStorage.setItem("inhouseDirect", true);
      } else {
        localStorage.removeItem("inhouseDirect");
      }
    }

    // Before setting stadium, check if this store is password protected.
    if (
      stadium?.stadiumPassword?.passwordEnabled &&
      stadium?.stadiumPassword?.password &&
      stadium?.stadiumPassword?.password.trim() !== ""
    ) {
      // Show password input modal
      setIsPasswordModalOpen(true);
      setActiveStorePassword(stadium?.stadiumPassword?.password);
    } else {
      setStadium(stadium);
    }
    setTempStadium(stadium);
  };

  // Check if there is any cart in the multicart that is from this stadium
  // If there is, then we want to show a checkout button
  const cartFromThisStadium = () => {
    if (multiCart.length === 0) return false;
    const foundCart = multiCart.find((cart) => cart.stadiumID === stadium._id);
    if (foundCart) {
      return true;
    } else {
      return false;
    }
  };

  // Check If the user is not logged in and the stadium's enableVisibleOnlyToAccounts is enabled, redirect to the home page
  const checkStadiumVisibilityToAccounts = (stadium) => {
    if (!authData && stadium?.enableVisibleOnlyToAccounts) {
      navigate("/");
      return;
    }
  };

  const { width } = useWindowDimensions();
  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  useEffect(() => {
    if (stadium?.stadiumAnnouncement?.announcementEnabled) {
      showModal();
    }
  }, [stadium]);

  const showModal = () => {
    setIsAnnounceModalOpen(true);
  };
  const handleOk = () => {
    setIsAnnounceModalOpen(false);
  };
  const handleCancel = () => {
    setIsAnnounceModalOpen(false);
  };
  const handlePasswordConfirm = () => {
    if (activeStorePassword === storePassword) {
      // Password entered is correct
      setIsPasswordModalOpen(false);
      setStadium(tempStadium);
    } else {
      if (!storePassword) {
        message.error("Please input a valid password");
      } else {
        message.error("Incorrect Password.");
      }
    }
  };

  const openCheckoutPage = () => {
    const foundCart = multiCart.find((cart) => cart.stadiumID === stadium._id);
    if (
      (stadium.stadTypes.includes("STT_RETAIL") ||
        stadium.stadTypes.includes("STT_RESTO")) &&
      stadium.catalogueMode
    ) {
      if (authData) {
        const encodedStoreId = encodeParamString(stadium._id);
        navigate(`/${encodedStoreId}/catalogue-checkout`, {
          state: {
            catalogueMode: stadium.catalogueMode,
          },
        });
      } else {
        navigate("/login");
      }
    } else {
      navigate(
        `/checkout/${foundCart.stadiumName.replaceAll(" ", "-")}?glb=${
          foundCart.stadiumID
        }`,
        {
          state: {
            cart: foundCart,
          },
        }
      );
    }
  };

  let announcementModalTitle = "Announcement";
  if (
    stadium?._id === "PuFJtkByNSq3Md9ua" ||
    stadium?._id === "zanDBkduyFHBm77rA"
  ) {
    announcementModalTitle = "Promotion";
  }

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Modal
        title="Password Protected Store"
        open={isPasswordModalOpen}
        onCancel={() => navigate("/")}
        footer={[
          <Button
            key="back"
            onClick={handlePasswordConfirm}
            style={{ color: "white", backgroundColor: "black" }}
          >
            Confirm
          </Button>,
        ]}
      >
        <p>
          This store is password protected. Please enter the password below to
          view this store's offerings.
        </p>
        <Input.Password
          maxLength={25}
          onChange={(e) => setStorePassword(e.target.value)}
          placeholder="Input password"
        />
      </Modal>
      {stadium?.stadiumAnnouncement ? (
        <Modal
          style={{
            top: 20,
          }}
          title={announcementModalTitle}
          open={isAnnounceModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Dismiss
            </Button>,
          ]}
        >
          {stadium?.stadiumAnnouncement?.announcementText ? (
            <p style={{ fontSize: 22 }}>
              {stadium?.stadiumAnnouncement?.announcementText}
            </p>
          ) : null}
          {stadium?.stadiumAnnouncement?.announcementImage ? (
            <img
              src={stadium?.stadiumAnnouncement?.announcementImage}
              alt={"Announcement"}
              width={"100%"}
            />
          ) : null}
        </Modal>
      ) : null}
      {stadium && (
        <StadiumBanner
          stadium={stadium}
          cartFromThisStadium={cartFromThisStadium}
          mobileSize={mobileSize}
        />
      )}
      <Spin
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        spinning={loadingCategoryItems}
        size="large"
      >
        <Col xs={{ span: 24 }} md={{ span: 16, offset: 4 }}>
          {stadium && stadium.stadTypes.includes("STT_RESTO") && (
            <FoodItemBrowser
              stadium={stadium}
              setloadingCategoryItems={setloadingCategoryItems}
            />
          )}
          {stadium && stadium.stadTypes.includes("STT_RETAIL") && (
            <RetailItemBrowser
              stadium={stadium}
              setloadingCategoryItems={setloadingCategoryItems}
            />
          )}
          {stadium && stadium.stadTypes.includes("STT_VENUE") && (
            <EventItemBrowser
              stadium={stadium}
              setloadingCategoryItems={setloadingCategoryItems}
            />
          )}
        </Col>
        {stadium &&
        (stadium._id === "PuFJtkByNSq3Md9ua" ||
          stadium?._id === "zanDBkduyFHBm77rA") ? (
          <div
            style={{
              fontWeight: "bold",
              position: "fixed",
              bottom: mobileSize && stadium && cartFromThisStadium() ? 70 : 0,
              width: "100%",
              zIndex: 9999,
              borderRadius: 0,
              height: 55,
              backgroundColor: token.colorSecondary,
              color: token.colorTertiary,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              borderTopWidth: 3,
              borderTopStyle: "solid",
              borderTopColor: token.colorPrimary,
            }}
          >
            <div
              style={{ textAlign: "center", paddingLeft: 12, paddingRight: 12 }}
            >
              NOTE: The Ottawa Team Shop will close for ordering at the end of
              the 2nd period.
            </div>
          </div>
        ) : null}

        {stadium && mobileSize && cartFromThisStadium() ? (
          <Button
            size="large"
            style={{
              fontWeight: "bold",
              position: "fixed",
              bottom: 0,
              width: "100%",
              zIndex: 9999,
              borderRadius: 0,
              height: 70,
              backgroundColor: token.colorSecondary,
              color: "white",
            }}
            onClick={openCheckoutPage}
          >
            Checkout
          </Button>
        ) : null}
        {stadium?.nowDoInHouse && stadium?.enableHaveAQuestion ? (
          <CallServerButton
            cartFromThisStadium={cartFromThisStadium}
            storeId={stadium._id}
            isCallServerButtonDisabled={isCallServerButtonDisabled}
            setIsCallServerButtonDisabled={setIsCallServerButtonDisabled}
          />
        ) : null}
      </Spin>
    </Layout>
  );
};

export default StoreFront;
