import React from "react";
import { Col, Typography, Drawer, theme } from "antd";

const { useToken } = theme;

const AnnouncementDrawer = ({
  showAnnouncementDrawer,
  setShowAnnouncementDrawer,
  brandContextData,
}) => {
  const { token } = useToken();

  const { Text } = Typography;

  const drawerTitle = `${brandContextData.brandName} Announcements`;

  const renderAnnouncement = (passedAnnouncement, passedIndex) => {
    const announcementTitle = passedAnnouncement.title
      ? passedAnnouncement.title
      : `Announcement`;
    const announcementText = passedAnnouncement.text
      ? passedAnnouncement.text
      : ``;

    return (
      <div
        key={passedIndex}
        className="announcementContainer"
        style={{ backgroundColor: token.colorSecondary }}
      >
        <Text className="announcementTitle" style={{ color: "white" }}>
          {announcementTitle}
        </Text>
        <div className="announcementTextContainer">
          <Text style={{ color: "white" }}>{announcementText}</Text>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      onClose={() => setShowAnnouncementDrawer(!showAnnouncementDrawer)}
      open={showAnnouncementDrawer}
    >
      <Col span={24}>
        {brandContextData?.announcements?.length > 0
          ? brandContextData.announcements.map((announcement, index) =>
              renderAnnouncement(announcement, index)
            )
          : null}
      </Col>
    </Drawer>
  );
};

export default AnnouncementDrawer;
