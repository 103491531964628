import { GiCookingPot, GiSuitcase } from "react-icons/gi";
import { TbTruckDelivery } from "react-icons/tb";
import { IoMdCheckmark } from "react-icons/io";
import { FiPackage } from "react-icons/fi";

import { AiFillBank, AiFillReconciliation } from "react-icons/ai";

// No longer used
// export const TIMEZONES = {
//   CALGARY: "America/Edmonton",
//   OTTAWA: "America/Toronto",
//   UTC: "UTC",
//   INDIA: "Asia/Kolkata",
//   JAPAN: "Asia/Tokyo",
// };

export const deliveryFee = 5;

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Used for the steps component (Pickup Orders)
export const pickupOrderSteps = [
  {
    step: 1,
    title: "Order Received",
    status: "Pending",
    description: "Order has been received",
    icon: <IoMdCheckmark />,
  },
  {
    step: 2,
    title: "Order Preparation",
    status: "Preparing Order",
    description: "Order is being prepared",
    icon: <GiCookingPot />,
  },
  {
    step: 3,
    title: "Ready For Pickup",
    status: "Ready For Pickup",
    description: "Waiting for customer to pickup their order",
    icon: <FiPackage />,
  },
  {
    step: 4,
    title: "Pickup Complete",
    status: "Delivered",
    description: "Order has been received by customer",
    icon: <GiSuitcase />,
  },
];

// Used for the steps component (Delivery Orders)
export const deliveryOrderSteps = [
  {
    step: 1,
    title: "Order Received",
    status: "Pending",
    description: "Order has been received",
    icon: <IoMdCheckmark />,
  },
  {
    step: 2,
    title: "Order Preparation",
    status: "Preparing Order",
    description: "Order is being prepared",
    icon: <GiCookingPot />,
  },
  {
    step: 3,
    title: "Ready for Driver Pickup",
    status: "Ready For Pickup",
    description: "Waiting for driver to pickup the order",
    icon: <FiPackage />,
  },
  {
    step: 4,
    title: "Delivery In Progress",
    status: "On the way",
    description: "Order is being delivered by the driver",
    icon: <TbTruckDelivery />,
  },
  {
    step: 5,
    title: "Delivery Completed",
    status: "Delivered",
    description: "Order has been received by customer",
    icon: <GiSuitcase />,
  },
];

// Used for the steps component (Rejected Orders)
export const orderRefundSteps = [
  {
    step: 1,
    title: "Order Abandoned",
    status: "Rejected",
    description: "Order needs to be refunded",
    icon: <AiFillReconciliation />,
  },
  {
    step: 2,
    title: "Refund Processing",
    status: "Awaiting Refund",
    description: "A refund is being processed",
    icon: <AiFillBank />,
  },
  {
    step: 3,
    title: "Refund Completed",
    status: "Refund Completed",
    description: "The refund has been completed",
    icon: <IoMdCheckmark />,
  },
];

// Used for the steps component (In-House Orders)
export const inhouseOrderSteps = [
  {
    step: 1,
    title: "Order Received",
    status: "Pending",
    description: "Order has been received",
    icon: <IoMdCheckmark />,
  },
  {
    step: 2,
    title: "Order Preparation",
    status: "Preparing Order",
    description: "Order is being prepared",
    icon: <GiCookingPot />,
  },
  {
    step: 3,
    title: "Order Complete",
    status: "Delivered",
    description: "Order has been received by customer",
    icon: <GiSuitcase />,
  },
];

// Used for the steps component (Venue Orders)
export const venueOrderSteps = [
  {
    step: 1,
    title: "Event Admission Received",
    status: "Pending",
    description: "",
    icon: <IoMdCheckmark />,
  },
  {
    step: 2,
    title: "Event Admission Confirmed",
    status: "Delivered",
    description: "",
    icon: <GiSuitcase />,
  },
];

export const DEMO_MERCHANTS = [
  {
    _id: "1",
    merchantName: "Dragon Boat Festival",
    vendorsTitleAboveListing: "Our Event Vendors",
    typeOfMerchant: "Festival",
    multipleVendors: true,
    announcements: [
      {
        announcementType: "article",
      },
      {
        announcementType: "link",
      },
    ],
    quickAccess: [
      {
        _id: "123456",
        qTitle: "Schedule",
        qIcon: "FaCalendarAlt",
        qLink: "https://getitlocal.app/",
      },
    ],
    privacyPolicy: "https://getitlocal.app/privacy-policy/",
    termsOfService: "https://getitlocal.app/terms-of-service/",
    footerImage:
      "https://extra-getit.s3.amazonaws.com/dragonboat-test-images/dragonboat-footer-banner.png",
    merchantLogo:
      "https://dragonboat.net/media/342426/festival-logo-186_white236x130odbf2019.png",
    donateLink: "https://getitlocal.app/",
    vendors: [
      {
        vName: "Poutine for days",
        vId: 13554391125,
        vFeaturedImage: "hjkfhdsjkfhjdsk",
      },
      {
        vName: "All Out Burger",
        vId: 13554391125,
        vFeaturedImage: "hjkfhdsjkfhjdsk",
      },
    ],
    theme: {
      colorPrimary: "#133956",
      colorSecondary: "#2596be",
      colorTertiary: "#FFFFFF",
    },
    adSpace: [
      {
        _id: 12,
        adImage:
          "https://getitlocal.s3.ca-central-1.amazonaws.com/stadiumFeaturedImages/stadiumFeaturedImage-Banner.png",
        adLink: "https://getitlocal.app/",
      },
    ],
    socialLinksHeader: "Hey check these cool links out!",
    // socialLinksIconColour: 'blue',
    socialLinks: [
      // iconName is a icon from FontAwesome
      {
        iconName: "FaFacebookSquare",
        navUrl: "https://www.facebook.com/ottawadragonboat",
      },
      {
        iconName: "FaInstagramSquare",
        navUrl: "https://www.instagram.com/ottawadragonboatfestival/",
      },
      {
        iconName: "FaTwitterSquare",
        navUrl: "https://twitter.com/ottdragonboat",
      },
      {
        iconName: "FaSnapchatSquare",
        navUrl: "https://twitter.com/ottdragonboat",
      },
    ],
  },
  {
    _id: "2",
    merchantName: "Blues Festival",
    typeOfMerchant: "Festival",
    vendorsTitleAboveListing: "Food Places",
    multipleVendors: true,
    announcements: [
      {
        announcementType: "article",
      },
      {
        announcementType: "link",
      },
    ],
    quickAccess: [
      {
        _id: "123456",
        qTitle: "Schedule",
        qIcon: "FaCalendarAlt",
        qLink: "https://getitlocal.app/",
      },
      {
        _id: "15442",
        qTitle: "Daycare Services",
        qIcon: "FaBabyCarriage",
        qLink: "https://getitlocal.app/",
      },
    ],
    privacyPolicy: "https://getitlocal.app/privacy-policy/",
    termsOfService: "https://getitlocal.app/terms-of-service/",
    footerImage:
      "https://getitlocal.s3.ca-central-1.amazonaws.com/stadiumFeaturedImages/stadiumFeaturedImage-Banner.png",
    merchantLogo:
      "https://consequence.net/wp-content/uploads/2019/12/Ottawa-Bluesfest-2020.png",
    donateLink: "https://getitlocal.app/",
    vendors: [
      {
        vName: "Poutine for days",
        vId: 13554391125,
        vFeaturedImage: "hjkfhdsjkfhjdsk",
      },
      {
        vName: "All Out Burger",
        vId: 13554391125,
        vFeaturedImage: "hjkfhdsjkfhjdsk",
      },
    ],
    theme: {
      colorPrimary: "#313ab5",
      colorSecondary: "#8e95f5",
      colorTertiary: "#080d4f",
    },
    adSpace: [
      {
        _id: 12,
        adImage:
          "https://getitlocal.s3.ca-central-1.amazonaws.com/stadiumFeaturedImages/stadiumFeaturedImage-Banner.png",
        adLink: "https://getitlocal.app/",
      },
    ],
  },
];
