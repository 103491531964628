import { Checkbox, Col, Form, Input, Row, Typography, theme } from "antd";
import React, { useEffect } from "react";
import { containsLetters, validateEmail } from "../../const/const_functions";

const ContactInformation = ({
  takeoutMethod,
  firstName,
  setFirstName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  lastName,
  setLastName,
  setEnableStatusSMSUpdates,
  enableStatusSMSUpdates,
  setSaveInfoForNextTime,
  saveInfoForNextTime,
}) => {
  const { Text } = Typography;
  const [form] = Form.useForm();
  const { useToken } = theme;
  const { token } = useToken();

  useEffect(() => {
    checkForSavedInfo();
  }, []);

  const checkForSavedInfo = () => {
    const userInfoForQuickCheckout = JSON.parse(
      localStorage.getItem("userInfoForQuickCheckout")
    );

    if (!userInfoForQuickCheckout) return;

    const { firstName, lastName, phoneNumber, email } =
      userInfoForQuickCheckout;

    if (!form || !form.setFieldsValue) {
      return;
    }
    form.setFieldsValue({
      firstname: firstName,
      lastname: lastName,
      phonenumber: phoneNumber,
      email: email,
    });

    setFirstName(firstName);
    setLastName(lastName);
    setPhoneNumber(phoneNumber);
    setEmail(email);
  };

  return (
    <>
      <Row justify="center">
        <Col xl={12} lg={18} md={22} xs={24}>
          <Form
            form={form}
            layout="vertical"
            size="large"
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Row gutter={[12]}>
              <Col md={12} xs={24}>
                <Form.Item
                  initialValue={firstName}
                  style={{ marginBottom: 10 }}
                  labelCol={{ span: 12 }}
                  label={
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                      First Name
                    </Text>
                  }
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                  ]}
                >
                  <Input
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  initialValue={lastName}
                  style={{ marginBottom: 10 }}
                  labelCol={{ span: 12 }}
                  label={
                    <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                      Last Name
                    </Text>
                  }
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                  ]}
                >
                  <Input
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              initialValue={phoneNumber}
              style={{ marginBottom: 10 }}
              label={
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>
                  Phone Number
                </Text>
              }
              name="phonenumber"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
                { whitespace: true },
                {
                  min: 10,
                  message: "Phone numbers must be 10 digits long",
                },
                {
                  validator: (_, value) => {
                    if (value && containsLetters(value)) {
                      return Promise.reject(
                        new Error(
                          "Phone numbers must only contain digits (no symbols)"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                value={phoneNumber}
                maxLength={10}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Form.Item>
            {takeoutMethod !== "admission" ? (
              <Checkbox
                onChange={(e) => setEnableStatusSMSUpdates(e.target.checked)}
                style={{
                  marginBottom: 20,
                  "--background-color": token.colorSecondary,
                  "--border-color": token.colorSecondary,
                }}
                checked={enableStatusSMSUpdates}
              >
                SMS Order Status Updates
              </Checkbox>
            ) : null}

            <Form.Item
              extra="We send receipts to your email"
              initialValue={email}
              label={
                <Text style={{ fontSize: 18, fontWeight: "bold" }}>Email</Text>
              }
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                { whitespace: true },
                {
                  validator: (_, value) => {
                    if (value && !validateEmail(value)) {
                      return Promise.reject(
                        new Error("Please input a valid email")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
            <Checkbox
              onChange={(e) => setSaveInfoForNextTime(e.target.checked)}
              style={{
                marginBottom: 30,
                "--background-color": token.colorSecondary,
                "--border-color": token.colorSecondary,
              }}
              checked={saveInfoForNextTime}
            >
              Save Info For Next Time
            </Checkbox>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ContactInformation;
