import React, { useContext } from "react";
import { Input, ConfigProvider } from "antd";
import LoginSubText from "./LoginSubText";
import LoginTitle from "./LoginTitle";
import styles from "../styles/CreateAccountBase.module.css";
import { validateEmail } from "../const/const_functions";

const AccountContactInfoSection = ({
  phone,
  setPhone,
  phoneError,
  accountRequiresExternalId,
  externalIdLabel,
  externalId,
  setExternalId,
  externalIdError,
  email,
  setEmail,
  setEmailError,
  accountRestrictsDomain,
  allowedEmailDomains,
  emailError,
  disableEmailField,
}) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const renderAllowedEmailDomains = () => {
    let retText = "";
    allowedEmailDomains.map((domain, index) => {
      retText = retText.concat(
        `@`,
        `${domain}`,
        `${index < allowedEmailDomains.length - 1 ? `, ` : ``}`
      );
    });
    return `Allowed domains: ${retText}`;
  };
  return (
    <>
      <LoginTitle titleText={`Phone Number`} required={true} />
      <Input
        className={styles.input}
        style={{ "--border-color": theme.token.colorSecondary }}
        value={phone}
        defaultValue={null}
        maxLength={10}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      {phoneError ? (
        <LoginSubText showError={phoneError} errorText={phoneError} />
      ) : null}
      {accountRequiresExternalId ? (
        <div>
          <LoginTitle titleText={`${externalIdLabel}`} required={true} />
          <Input
            className={styles.input}
            style={{ "--border-color": theme.token.colorSecondary }}
            value={externalId}
            defaultValue={null}
            onChange={(e) => {
              setExternalId(e.target.value);
            }}
          />
          {externalIdError ? (
            <LoginSubText
              showError={externalIdError}
              errorText={externalIdError}
            />
          ) : null}
        </div>
      ) : null}
      <LoginTitle titleText={`E-mail`} required={true} />
      <Input
        disabled={disableEmailField}
        className={styles.input}
        style={{ "--border-color": theme.token.colorSecondary }}
        value={email}
        defaultValue={null}
        onChange={(e) => {
          if (
            (e.target.value && validateEmail(e.target.value)) ||
            !e.target.value
          ) {
            setEmail(e.target.value);
            setEmailError(false);
          } else {
            setEmail(e.target.value);
            setEmailError(true);
          }
        }}
      />
      {accountRestrictsDomain && allowedEmailDomains?.length ? (
        <div style={{ marginTop: 2, color: "grey" }}>
          {renderAllowedEmailDomains()}
        </div>
      ) : null}
      {emailError ? (
        <LoginSubText showError={emailError} errorText={emailError} />
      ) : null}
    </>
  );
};

export default AccountContactInfoSection;
