import React, { useEffect, useState } from "react";
import { PaymentForm, CreditCard } from "react-square-web-payments-sdk";
import { useServer } from "../contexts/Server";
import { useBrandContext } from "../contexts/Brand";

const SquareAddCreditCard = ({
  tempName,
  tempEmail,
  tempPhone,
  disableSaveButton,
  handleSubmitCreditCard,
  title,
}) => {
  const { brandContextData } = useBrandContext();

  if (!brandContextData?.squareData) {
    return null;
  }

  return (
    <PaymentForm
      applicationId={brandContextData?.squareData?.squareId}
      createVerificationDetails={() => ({
        billingContact: {
          name: tempName ? `${tempName}` : "",
          email: tempEmail ? `${tempEmail}` : "",
          phone: tempPhone ? `${tempPhone}` : "",
        },
        currencyCode: brandContextData?.squareData?.currencyCode,
        intent: "STORE",
      })}
      locationId={brandContextData?.squareData.squareLocation}
      cardTokenizeResponseReceived={(token, verifiedBuyer) => {
        handleSubmitCreditCard(token, verifiedBuyer);
      }}
    >
      <CreditCard
        buttonProps={{
          isLoading: disableSaveButton && disableSaveButton() ? true : false,
          css: {
            backgroundColor: "black",
          },
        }}
      >
        {title ? title : "Save Credit Card and Register Account"}
      </CreditCard>
    </PaymentForm>
  );
};

export default SquareAddCreditCard;
