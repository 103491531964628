import React from "react";

const LoginTitle = ({ titleText, required = false }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <p style={{ fontWeight: "600", color: "black", marginBottom: 6 }}>
        {titleText}
        {required ? (
          <span style={{ fontWeight: "500", color: "red", marginBottom: 6 }}>
            *
          </span>
        ) : null}
      </p>
    </div>
  );
};

export default LoginTitle;
