import {
  Col,
  InputNumber,
  message,
  Radio,
  Row,
  Space,
  Typography,
  ConfigProvider,
} from "antd";
import React, { useContext } from "react";
import styles from "../../styles/Tipping.css";

const TippingSelection = ({
  tipPercentage,
  setTipPercentage,
  setTipDollarAmount,
  tipDollarAmount,
  takeoutMethod,
}) => {
  const { Text } = Typography;

  const { theme } = useContext(ConfigProvider.ConfigContext);

  const deliveryText = "100% of your tip goes to the driver.";
  const pickupText = "100% of your tip goes to the business.";
  const inhouseText = "100% of your tip goes to the staff/business.";
  let takeoutText = deliveryText;
  if (takeoutMethod === "inhouse") {
    takeoutText = inhouseText;
  } else if (takeoutMethod === "pickup") {
    takeoutText = pickupText;
  }

  const onChangeTipPercentage = (e) => {
    setTipPercentage(e.target.value);
    if (e.target.value !== "") {
      setTipDollarAmount(null);
    }
  };

  const onChangeDollarAmount = (val) => {
    if (val < 0) {
      message.warning(
        "Please enter a tip amount equal to or greater than 0.",
        5
      );
    }
    setTipDollarAmount(val);
  };

  return (
    <Row justify="center" style={{ paddingTop: 40 }}>
      <Col xl={12} lg={18} md={22} xs={24}>
        <Row>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Include a Tip?
          </Text>
        </Row>
        <Row>
          <Text>{takeoutText}</Text>
        </Row>
        <Radio.Group
          onChange={onChangeTipPercentage}
          value={tipPercentage}
          style={{
            marginTop: 20,
          }}
        >
          <Space direction="vertical">
            <Radio
              value={15}
              style={
                tipPercentage === 15
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              15%
            </Radio>
            <Radio
              value={18}
              style={
                tipPercentage === 18
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              18%
            </Radio>
            <Radio
              value={20}
              style={
                tipPercentage === 20
                  ? { fontWeight: "bold" }
                  : { fontWeight: "normal" }
              }
            >
              20%
            </Radio>
            <div style={{ display: "flex", alignItems: "center", height: 23 }}>
              <Radio value={""}>Custom Dollar Amount...</Radio>
              {tipPercentage === "" ? (
                <Col xs={10} xl={12} md={12}>
                  <InputNumber
                    min={0}
                    max={500}
                    value={tipDollarAmount}
                    onChange={(val) => onChangeDollarAmount(val)}
                    addonBefore="$"
                  />
                </Col>
              ) : null}
            </div>
          </Space>
        </Radio.Group>
      </Col>
    </Row>
  );
};

export default TippingSelection;
