import { Button, Col, Row, Slider, Typography } from "antd";
import React, { useState } from "react";

const { Text } = Typography;

const PointRedemption = ({
  setPointsRedemptionAmount,
  pointsRedemptionAmount,
  cartPointsObj,
  userPoints,
}) => {
  const [pointAmountFromSlider, setPointAmountFromSlider] = useState(0);
  const { pointsLabel } = userPoints;
  const { maxPointsCanSpend } = cartPointsObj;
  const pointsSliderStep = 1 / cartPointsObj.multiplyRedemptionRate;
  return (
    <Row justify="center" style={{ paddingTop: 40 }}>
      <Col xl={12} lg={18} md={22} xs={24}>
        <Row>
          <Col span={24}>
            <Text style={{ fontWeight: "bold", fontSize: 18 }}>
              Redeem Your {pointsLabel}?
            </Text>
          </Col>
          <Col span={24}>
            <Text style={{ fontSize: 14 }}>
              {`${userPoints.runningTotal.toLocaleString()} ${
                userPoints.pointsLabel
              } Available ($${(
                userPoints.runningTotal * cartPointsObj.multiplyRedemptionRate
              ).toFixed(2)})`}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Slider
              style={{ marginTop: 50 }}
              tooltip={{
                open: true,
              }}
              step={pointsSliderStep}
              min={0}
              max={maxPointsCanSpend}
              onChange={(value) => setPointAmountFromSlider(value)}
              value={
                typeof pointAmountFromSlider === "number"
                  ? pointAmountFromSlider
                  : 0
              }
            />
          </Col>
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Text style={{ fontSize: 14 }}>
              {`${pointAmountFromSlider.toLocaleString()} ${
                userPoints.pointsLabel
              } = $${(
                pointAmountFromSlider * cartPointsObj.multiplyRedemptionRate
              ).toFixed(2)}`}
            </Text>
          </Col>
          <Col
            span={24}
            style={{ display: "flex", justifyContent: "center", marginTop: 12 }}
          >
            <Button
              onClick={() => setPointsRedemptionAmount(pointAmountFromSlider)}
            >
              Redeem {pointAmountFromSlider.toLocaleString()}{" "}
              {userPoints.pointsLabel}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PointRedemption;
