import React, { useState } from "react";
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  message,
  Divider,
  Avatar,
  theme,
} from "antd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  screenWidths,
  mom4tzDateOnly,
  mom4tzTimeOnly,
} from "../const/const_functions";
import styles from "../styles/EventItemModal.module.css";
import AdmissionTypeList from "./AdmissionTypeList";
import moment from "moment";
import { useCarts } from "../contexts/Carts";
import { useFirebase } from "../contexts/Firebase";
import { logEvent } from "firebase/analytics";

const { Text } = Typography;
const { useToken } = theme;

const EventItemModal = ({
  showEventItemModal,
  setShowEventItemModal,
  currentItem,
  categoryItem,
  stadium,
}) => {
  const { firebaseAnalytics } = useFirebase();
  const { multiCart, setMultiCartData } = useCarts();
  const [admissionPurchaseQuantities, setAdmissionPurchaseQuantities] =
    useState([]);
  const { width } = useWindowDimensions();
  const {
    evName,
    evFeaturedImage,
    evDesc,
    evOperatingTime,
    evAddress,
    evTimeZone,
  } = currentItem;
  const [purchaseQuantityFlag, setPurchaseQuantityFlag] = useState(false);
  const { token } = useToken();

  let mySaneImgUrl = "";
  if (typeof evFeaturedImage === "string" && evFeaturedImage.trim() !== "") {
    mySaneImgUrl = evFeaturedImage; // no string trimming, this should be a url for sure
  }
  let mySaneDesc = "";
  if (typeof evDesc === "string" && evDesc.trim() !== "") {
    mySaneDesc = evDesc.trim(); // string trimming because desc could have space around it and be valid
  }

  let formattedDateWithTimeZone = null;
  let formattedStartTime = null;
  let formattedOpeningTime = null;
  let formattedEndTime = null;
  if (currentItem.evOperatingTime) {
    if (evOperatingTime.evStartTime) {
      const eventStartTime = evOperatingTime.evStartTime;
      formattedDateWithTimeZone = mom4tzDateOnly(
        new Date(eventStartTime).getTime(),
        evTimeZone
      );
      formattedStartTime = mom4tzTimeOnly(
        new Date(eventStartTime).getTime(),
        evTimeZone
      );
    }
    if (evOperatingTime.evOpening) {
      const eventOpeningTime = evOperatingTime.evOpening;
      formattedOpeningTime = mom4tzTimeOnly(
        new Date(eventOpeningTime).getTime(),
        evTimeZone
      );
    }
    if (evOperatingTime.evEndTime) {
      const eventEndTime = evOperatingTime.evEndTime;
      formattedEndTime = mom4tzTimeOnly(
        new Date(eventEndTime).getTime(),
        evTimeZone
      );
    }
  }

  const createNewCart = (time, currentDate) => {
    const createdCart = {};
    createdCart.stadiumID = stadium._id;
    createdCart.stadiumName = stadium.name;
    createdCart.stadiumAddress = stadium.address;
    createdCart.stadiumLogoUrl = stadium.imageUrl;
    createdCart.stadiumFeaturedImage = stadium.stadiumFeaturedImage;
    createdCart.stadTypes = stadium.stadTypes;
    createdCart.stadiumHomeRegion = stadium.homeRegion;
    createdCart.createdAt = currentDate;

    // Push the current event item onto the current cart
    createdCart.eventItems = [];
    createdCart.eventItems.push({
      evpRenderId: time,
      eventItemId: currentItem._id,
      evName: currentItem.evName,
      evFeaturedImage: currentItem.evFeaturedImage,
      evOperatingTime: currentItem.evOperatingTime,
      evTimeZone,
      admissionPurchaseQuantities: admissionPurchaseQuantities,
    });

    return createdCart;
  };

  const addEventItemToCart = () => {
    // Used in order to make the items unique when added to the cart
    // Some cases the user may go back and add the same item.
    // in this case, we cannot use the item's 'id'
    const currentDate = new Date();
    const time = currentDate.getTime();

    if (moment(currentDate).isAfter(currentItem.evOperatingTime.evEndTime)) {
      message.warning(
        `Event Complete,
      We wish we had a time machine (we're working on it), but in the meantime browse more events we have to offer!`,
        5
      );
      return;
    }

    // Boolean to check if there is a cart in the multicart with the same stadiumid
    // This boolean determines if we are going to have to create a new cart, or add
    // a new cart to the multicart

    let sameStadiumObj = multiCart.find(
      (cart) => cart.stadiumID === stadium._id
    );
    if (sameStadiumObj) {
      // The user is adding another item from a stadium that is already in his carts array
      if (!sameStadiumObj.eventItems) sameStadiumObj.eventItems = [];
      const newEventItem = {
        // Special id used for rendering the same item twice (frontend only)
        evpRenderId: time,
        eventItemId: currentItem._id,
        evName: currentItem.evName,
        evFeaturedImage: currentItem.evFeaturedImage,
        evOperatingTime: currentItem.evOperatingTime,
        evTimeZone,
        admissionPurchaseQuantities: admissionPurchaseQuantities,
      };

      sameStadiumObj.eventItems = [...sameStadiumObj.eventItems, newEventItem];
      const newCartItems = multiCart.map((cart) =>
        cart.stadiumID === stadium._id ? sameStadiumObj : cart
      );
      setMultiCartData(newCartItems);
    } else {
      const createdCart = createNewCart(time, currentDate);
      setMultiCartData([...multiCart, createdCart]);
    }
    setShowEventItemModal(false);
    setAdmissionPurchaseQuantities([]);
    setPurchaseQuantityFlag(false);
    const numberOfItems = admissionPurchaseQuantities.reduce(
      (sum, item) => sum + item.quantityToPurchase,
      0
    );
    message.open({
      type: "success",
      content: `${numberOfItems} ${
        numberOfItems > 1 ? "items have" : " item has"
      } been added to your cart`,
    });

    if (firebaseAnalytics) {
      logEvent(firebaseAnalytics, "add_to_cart", { name: currentItem?.evName });
    }
  };

  return (
    <Modal
      open={showEventItemModal}
      className={styles.modal}
      onCancel={() => setShowEventItemModal(false)}
      width={width > screenWidths.md ? "60%" : "100%"}
      footer={null}
      destroyOnClose={true}
    >
      <Row>
        <Text className={styles.evCatName}>
          {categoryItem?.evCatName ? categoryItem.evCatName : "Upcoming Events"}
        </Text>
      </Row>
      <Row className={styles.titleRow}>
        {mySaneImgUrl ? (
          <Col className={styles.imgCol}>
            <Avatar src={mySaneImgUrl} className={styles.evImg} />
          </Col>
        ) : null}
        <Col className={styles.titleColumn}>
          <div className={styles.descDiv}>
            <Text className={styles.name}>{evName}</Text>
            {mySaneDesc && width < screenWidths.sm ? (
              <Text className={styles.textDesc}>{mySaneDesc}</Text>
            ) : null}
            <Row>
              {formattedDateWithTimeZone ? (
                <Col style={{ paddingTop: 7 }}>
                  <Row>
                    <Text className={styles.generalInfo}>
                      General Information
                    </Text>
                  </Row>
                  <Row>
                    <Text>{`Date: ${formattedDateWithTimeZone}`}</Text>
                  </Row>
                  {formattedOpeningTime ? (
                    <Text>{`Opening: ${formattedOpeningTime}`}</Text>
                  ) : null}
                  {formattedStartTime ? (
                    <Row>
                      <Text>{`Start Time: ${formattedStartTime}`}</Text>
                    </Row>
                  ) : null}
                  {formattedEndTime ? (
                    <Text>{`End Time: ${formattedEndTime}`}</Text>
                  ) : null}
                </Col>
              ) : null}
            </Row>
            <Row style={{ paddingTop: 7 }}>
              <Col>
                <Text className={styles.location}>Location</Text>
                {evAddress && evAddress.physical ? (
                  <Row>
                    <Text>{evAddress.physical}</Text>
                  </Row>
                ) : null}
                {evAddress && evAddress.virtual ? (
                  <Row>
                    <Text>{evAddress.virtual}</Text>
                  </Row>
                ) : null}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {mySaneDesc && width > screenWidths.sm ? (
        <Row style={{ marginBotttom: 5 }}>
          <Text className={styles.textDesc}>{mySaneDesc}</Text>
        </Row>
      ) : null}
      <Divider
        style={{
          marginTop: 10,
        }}
      />
      <AdmissionTypeList
        currentItem={currentItem}
        admissionPurchaseQuantities={admissionPurchaseQuantities}
        setAdmissionPurchaseQuantities={setAdmissionPurchaseQuantities}
        setPurchaseQuantityFlag={setPurchaseQuantityFlag}
      />
      <Row className={styles.rowCenter}>
        <Col
          className={styles.quantityCol}
          xs={{ span: 22, offset: 1 }}
          md={{ span: 16, offset: 4 }}
        >
          <Button
            className={styles.addButton}
            style={{
              backgroundColor: purchaseQuantityFlag
                ? token.colorSecondary
                : "lightgray",
              color: "white",
              marginBottom: 30,
            }}
            disabled={!purchaseQuantityFlag}
            onClick={() => addEventItemToCart()}
          >
            Add to cart
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default EventItemModal;
