import React from "react";
import { Typography, Row, Col } from "antd";
import styles from "../styles/RetailItemModal.module.css";
const { Text } = Typography;

const VariationItem = ({
  item,
  selectedVariant1,
  variant1,
  selectVariant,
  selectedVariant2,
  variant2,
  selectedVariant3,
  variant3,
}) => {
  const variantVals = item.variantValues.map((val) => {
    if (item.variantNum === 1) {
      if (item.isSelected && selectedVariant1 === val) {
        return (
          <Text key={val} className={styles.variantButton}>
            {val}
          </Text>
        );
      } else {
        return (
          <Text
            key={val}
            className={styles.grayBtn}
            onClick={() => selectVariant(val, item.variantNum)}
          >
            {val}
          </Text>
        );
      }
    }

    if (item.variantNum === 2) {
      if (!variant1.isSelected) {
        return (
          <Text key={val} className={styles.textBtn}>
            {val}
          </Text>
        );
      } else if (variant1.isSelected && !variant2.isSelected) {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.grayBtn}
          >
            {val}
          </Text>
        );
      } else if (
        variant1.isSelected &&
        variant2.isSelected &&
        selectedVariant2 === val
      ) {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.variantButton}
          >
            {val}
          </Text>
        );
      } else {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.grayBtn}
          >
            {val}
          </Text>
        );
      }
    }

    if (item.variantNum === 3) {
      if (
        (!variant1.isSelected && !variant2.isSelected) ||
        (variant1.isSelected && !variant2.isSelected)
      ) {
        return (
          <Text key={val} className={styles.textBtn}>
            {val}
          </Text>
        );
      } else if (
        variant1.isSelected &&
        variant2.isSelected &&
        !variant3.isSelected
      ) {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.grayBtn}
          >
            {val}
          </Text>
        );
      } else if (
        variant1.isSelected &&
        variant2.isSelected &&
        selectedVariant3 === val
      ) {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.variantButton}
          >
            {val}
          </Text>
        );
      } else {
        return (
          <Text
            key={val}
            onClick={() => selectVariant(val, item.variantNum)}
            className={styles.grayBtn}
          >
            {val}
          </Text>
        );
      }
    }
    return null;
  });
  return (
    <Col
      style={{
        paddingTop: 20,
        width: "100%",
      }}
    >
      <Text className={styles.variantName}>{item.variantName}</Text>
      <Row style={{ paddingTop: 10, flexWrap: "wrap" }}>{variantVals}</Row>
    </Col>
  );
};

export default VariationItem;
