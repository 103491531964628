import { Tabs, notification, Collapse, Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import EventCatListing from "./EventCatListing";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  decodeParamString,
  screenWidths,
  showThisEventCategory,
} from "../const/const_functions";
import useQuery from "../hooks/useQuery";
import { useServer } from "../contexts/Server";
import { EventCategory } from "./EventCategory";
const { Panel } = Collapse;

const EventItemBrowser = ({ stadium, setloadingCategoryItems }) => {
  const { TabPane } = Tabs;
  const { width } = useWindowDimensions();
  const [eventCategories, setEventCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const query = useQuery();
  const { serverData } = useServer();

  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  useEffect(() => {
    getStadiumData();
  }, [serverData]);

  const getStadiumData = async () => {
    const storeId = query.get("glb");
    const decodedStoreId = decodeParamString(storeId);
    try {
      const stadiumCategoryEventItems = await serverData.call(
        "USSR_getVenueStoreFront",
        { stadiumId: decodedStoreId }
      );
      let { eventCategoriesAndItems, categoryWiseFoodItems } =
        stadiumCategoryEventItems;

      // Sort event categories
      eventCategoriesAndItems = eventCategoriesAndItems.sort(
        (a, b) => a.evCatPosition - b.evCatPosition
      );
      // categoryWiseFoodItems = categoryWiseFoodItems.sort(
      //   (a, b) => parseFloat(a.catPosition) - parseFloat(b.catPosition)
      // );

      let cleansedEventCats = [];
      let newCatPosition = 1;
      for (let [i, cat] of eventCategoriesAndItems.entries()) {
        if (cat.EventItems && cat.EventItems.length > 0) {
          cat.evCatPosition = newCatPosition;
          cleansedEventCats.push(cat);
          newCatPosition++;
        }
      }

      // for (let i = 0; i < categoryWiseFoodItems.length; i++) {
      //   categoryWiseFoodItems[i].properCatPosition =
      //     i + 1 + cleansedEventCats.length;
      // }
      // const eventAndFoodCategories = cleansedEventCats.concat(
      //   categoryWiseFoodItems
      // );

      setEventCategories(cleansedEventCats);
      setloadingCategoryItems(false);
    } catch (e) {
      console.log("Error fetching event items");
      console.log(e);
      venueStoreFrontLoadingError("top");
      return;
    }
  };

  const venueStoreFrontLoadingError = (placement) => {
    notification.info({
      message: "Store Loading Error",
      description:
        "Sorry, there is an error loading the store. Please contact support.",
      placement,
    });
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  if (!eventCategories.length) {
    return (
      <Col style={{ paddingTop: 20 }}>
        <Row justify="center" align="middle">
          <p>There are currently no events scheduled.</p>
        </Row>
      </Col>
    );
  }

  if (screenWidths.xl > width) {
    return (
      <Collapse defaultActiveKey={["0"]}>
        {eventCategories.length > 0 &&
          eventCategories.map((categoryItem) => {
            if (categoryItem.evCatPosition) {
              if (showThisEventCategory(categoryItem)) return null;
            }

            const tabName = categoryItem.evCatPosition
              ? categoryItem.evCatName
              : categoryItem.name;

            const eventItemData = !categoryItem.evCatPosition
              ? categoryItem.foodItems
              : categoryItem.EventItems;

            return (
              <Panel header={tabName} key={categoryItem.renderCatPosition}>
                {categoryItem.description ? (
                  <p
                    style={{
                      fontStyle: "italic",
                      paddingLeft: 6,
                      paddingRight: 6,
                      color: "gray",
                    }}
                  >
                    {categoryItem.description}
                  </p>
                ) : null}
                <EventCatListing
                  stadium={stadium}
                  categoryItem={categoryItem}
                  eventItemData={eventItemData}
                  mobileSize={mobileSize}
                />
              </Panel>
            );
          })}
      </Collapse>
    );
  } else {
    return (
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        centered
        renderTabBar={(props, TabNavList) => (
          <TabNavList {...props} mobile={false} />
        )}
        tabPosition={width > screenWidths.md ? "left" : "top"}
        style={{
          backgroundColor: "white",
          minHeight: screenWidths.xl > width ? "35vh" : "70vh",
        }}
      >
        {eventCategories.length > 0 &&
          eventCategories.map((categoryItem, index) => {
            if (categoryItem.evCatPosition) {
              if (showThisEventCategory(categoryItem)) return null;
            }

            const tabName = categoryItem.evCatPosition
              ? categoryItem.evCatName
              : categoryItem.name;

            const eventItemData = !categoryItem.evCatPosition
              ? categoryItem.foodItems
              : categoryItem.EventItems;

            return (
              <TabPane
                tab={
                  mobileSize ? (
                    tabName
                  ) : (
                    <EventCategory
                      tabName={tabName}
                      categoryItem={categoryItem}
                    />
                  )
                }
                key={
                  categoryItem.evCatPosition
                    ? categoryItem.evCatPosition
                    : categoryItem.properCatPosition
                }
              >
                <EventCatListing
                  stadium={stadium}
                  categoryItem={categoryItem}
                  eventItemData={eventItemData}
                  mobileSize={mobileSize}
                />
              </TabPane>
            );
          })}
      </Tabs>
    );
  }
};

export default EventItemBrowser;
