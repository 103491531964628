import { Button, Col, Row, Typography, Image } from "antd";
import React from "react";
const { Text } = Typography;

const QuickAccessBtnVar1 = ({ quickAccessItem, mobile }) => {
  return (
    <Col xs={6} sm={6} md={4} lg={3} xl={3}>
      <a href={quickAccessItem.qLink} target="_blank" rel="noreferrer">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col span={24}>
            <Button
              type={"text"}
              style={{
                width: "100%",
                height: mobile ? 60 : 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <Image
                preview={false}
                src={require("../assets/schedule.png")}
                style={{
                  height: mobile ? 60 : 70,
                  objectFit: "contain",
                }}
              />
            </Button>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: mobile ? 12 : 18,
                fontWeight: "500",
                textAlign: "center",
                textWrap: "wrap",
              }}
            >
              {quickAccessItem.qTitle}
            </Text>
          </Col>
        </Row>
      </a>
    </Col>
  );
};

export default QuickAccessBtnVar1;
