import React, { useState, useEffect } from "react";
import { Col, Typography, Row, Divider, Popconfirm } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { encodeParamString } from "../../const/const_functions";

const EditFoodItemCard = ({ currentCart, multiCart, setMultiCartData }) => {
  const [cart, setCart] = useState(currentCart);
  const { Text } = Typography;
  const navigate = useNavigate();

  useEffect(() => {
    if (cart && multiCart && multiCart.length) {
      updateMultiCart();
    }
  }, [cart]);

  const removeItemFromCart = (cartItem) => {
    const foundItem = cart.foodItems.some(
      (item) => item.apfRenderId === cartItem.apfRenderId
    );
    if (foundItem) {
      if (cart.foodItems.length !== 1) {
        //Remove current item
        const newFoodItems = cart.foodItems.filter(
          (item) => item.apfRenderId !== cartItem.apfRenderId
        );
        const newCart = { ...cart, foodItems: newFoodItems };
        setCart(newCart);
      } else {
        //If last item, remove current cart
        const newMultiCart = multiCart.filter(
          (mCart) => mCart.stadiumID !== cart.stadiumID
        );
        setMultiCartData(newMultiCart);
        let encodedStoreId = encodeParamString(currentCart.stadiumID);
        navigate(
          `/order/${currentCart.stadiumName.replaceAll(
            " ",
            "-"
          )}?glb=${encodedStoreId}`,
          false
        );
      }
    }
  };

  const increaseQuantityToPurchase = (cartItem) => {
    let tempCart = { ...currentCart };
    for (let foodItem of tempCart.foodItems) {
      if (cartItem.apfRenderId === foodItem.apfRenderId) {
        if (foodItem.availabilityStatus === "unlimited") {
          foodItem.quantityToPurchase += 1;
        } else {
          if (foodItem.quantityToPurchase < foodItem.quantity) {
            foodItem.quantityToPurchase += 1;
          }
        }
        break;
      }
    }
    setCart(tempCart);
  };

  const decreaseQuantityToPurchase = (cartItem) => {
    let tempCart = { ...currentCart };
    // Find the item to decrease in current cart
    if (cartItem.quantityToPurchase > 1) {
      for (let foodItem of tempCart.foodItems) {
        if (cartItem.apfRenderId === foodItem.apfRenderId) {
          foodItem.quantityToPurchase -= 1;
          break;
        }
      }
    }
    setCart(tempCart);
  };

  const updateMultiCart = () => {
    const newMultiCart = multiCart.map((mCart) =>
      mCart.stadiumID === cart.stadiumID ? cart : mCart
    );
    setMultiCartData(newMultiCart);
  };

  return (
    <>
      {cart.foodItems.length > 0 &&
        cart.foodItems.map((cartItem, index) => {
          let cartItemOptionGroups = [];
          let addOnCost = 0;
          const isLastItem = cart.foodItems.length === 1 ? true : false;

          if (cartItem.optionGroups) {
            cartItemOptionGroups = cartItem.optionGroups.map((optionGroup) => {
              const options = optionGroup.zOptions.map((option) => {
                if (option.isSelected === true) {
                  addOnCost = addOnCost + option.optionObjExtraCost;
                  return (
                    <Row key={option.optionObjId} style={{ paddingLeft: 10 }}>
                      <Text className="optionObjName">
                        {option.optionObjName}
                      </Text>

                      <Text className="optionObjExtraCost">
                        +${Number(option.optionObjExtraCost).toFixed(2)}
                      </Text>
                    </Row>
                  );
                }
              });
              return (
                <Col key={optionGroup.optionGroupId} style={{ paddingTop: 5 }}>
                  <Row>
                    <Text className="optionGroupName" style={{ fontSize: 14 }}>
                      {optionGroup.optionGroupName}
                    </Text>
                  </Row>
                  <Row>{options}</Row>
                </Col>
              );
            });
          }
          return (
            <Col key={cartItem.apfRenderId} className="cartItemCol">
              <Row>
                <Col span={20}>
                  <Row>
                    {cartItem.fXQImageURL ? (
                      <Col
                        className="imgCol"
                        style={{
                          backgroundImage: `url(${cartItem.fXQImageURL})`,
                        }}
                      ></Col>
                    ) : null}
                    <Col
                      style={{
                        paddingLeft: cartItem.fXQImageURL ? 10 : null,
                        width: cartItem.fXQImageURL ? "60%" : "80%",
                      }}
                    >
                      <Text className="cartItemName">{cartItem.name}</Text>
                      {cartItemOptionGroups}
                    </Col>
                  </Row>
                </Col>
                <Col span={4} style={{ paddingBottom: 20 }}>
                  <Row justify="end">
                    <Text
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: 16,
                        whiteSpace: "nowrap",
                      }}
                    >
                      $
                      {(
                        (Number(cartItem.price) + addOnCost) *
                        cartItem.quantityToPurchase
                      ).toFixed(2)}
                    </Text>
                  </Row>
                  <Row justify="end">
                    {isLastItem ? (
                      <Popconfirm
                        title={
                          "Are you sure you want to remove the only item in this cart? Doing so will remove this cart. Do you wish to proceed?"
                        }
                        placement="left"
                        style={{ margin: 20 }}
                        onConfirm={() => removeItemFromCart(cartItem)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          type: "danger",
                        }}
                      >
                        <Text
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          className="removeBtn"
                        >
                          Remove
                        </Text>
                      </Popconfirm>
                    ) : (
                      <Text
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        className="removeBtn"
                        onClick={() => removeItemFromCart(cartItem)}
                      >
                        Remove
                      </Text>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Row className="quantityRow">
                    <Col>
                      <FaMinus
                        name="minus"
                        style={{
                          fontSize: 16,
                          color:
                            cartItem.quantityToPurchase === 1
                              ? "lightgray"
                              : "gray",
                        }}
                        onClick={() =>
                          cartItem.quantityToPurchase !== 1
                            ? decreaseQuantityToPurchase(cartItem)
                            : null
                        }
                      />
                    </Col>
                    <Text className="quantityToPurchase">
                      {cartItem.quantityToPurchase}
                    </Text>
                    <Col>
                      <FaPlus
                        name="plus"
                        style={{ fontSize: 15, color: "gray" }}
                        onClick={() => {
                          increaseQuantityToPurchase(cartItem);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {cart.foodItems.length - 1 !== index && <Divider />}
            </Col>
          );
        })}
    </>
  );
};

export default EditFoodItemCard;
