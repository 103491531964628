import { Col, Row } from "antd";
import React from "react";
import MerchantBanner from "../MerchantBanner";

const JumbotronVar1 = ({ mobile, jumbotronData }) => {
  if (!jumbotronData || !jumbotronData.length) {
    return null;
  }

  return (
    <Col span={24}>
      <MerchantBanner mobile={mobile} jumbotronData={jumbotronData} />
    </Col>
  );
};

export default JumbotronVar1;
