import { Image, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useBrandContext } from "../contexts/Brand";
const { Text } = Typography;

const EventItem = ({ event, mobile, customMarginBottom = 10 }) => {
  const {
    evName,
    evFeaturedImage,
    evStartDisplayString,
    evStadiumName,
    evLowestPrice,
    evLocation,
    evSoldOut,
  } = event;
  const navigate = useNavigate();
  const { brandContextData } = useBrandContext();

  let lowestPrice = evLowestPrice;

  // if (brandContextData._id === "64b80d8a5bdf376def855b1e") {
  //   lowestPrice = evLowestPrice * 1.13;
  // }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flex: mobile ? "initial" : 0,
        justifyContent: "flex-start",
        marginBottom: customMarginBottom,
        marginLeft: mobile ? 0 : 20,
        marginRight: mobile ? 0 : 20,
      }}
    >
      <div
        onClick={() =>
          navigate(
            `/event/${evStadiumName.replaceAll(" ", "-")}?gle=${event._id}`,
            {
              state: {
                eventId: event._id,
              },
            }
          )
        }
        style={{
          width: mobile ? "100%" : 300,
          marginBottom: mobile ? 10 : 0,
          borderRadius: 12,
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: mobile ? "row" : "column",
          cursor: "pointer",
        }}
      >
        {evFeaturedImage ? (
          <Image
            preview={false}
            src={evFeaturedImage}
            style={{
              width: mobile ? 120 : "-webkit-fill-available",
              height: mobile ? "100%" : 300,
              borderTopLeftRadius: 12,
              borderTopRightRadius: mobile ? 0 : 12,
              borderBottomLeftRadius: mobile ? 12 : 0,
              backgroundRepeat: "no-repeat",
              objectFit: "contain",
              backgroundColor: "black",
            }}
          />
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: mobile ? 12 : 18,
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              ellipsis
              style={{
                fontSize: mobile ? 12 : 14,
                color: "grey",
              }}
            >
              {evStartDisplayString}
            </Text>
            <Text
              style={{
                fontSize: mobile ? 14 : 24,
                color: "#000000",
                fontWeight: "600",
              }}
            >
              {evName}
            </Text>
            <Text style={{ fontSize: mobile ? 12 : 14, color: "grey" }}>
              {evLocation}
            </Text>
          </div>

          <div
            style={{
              display: "flex",
              alignSelf: "flex-end",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: mobile ? 6 : 4,
              marginRight: mobile ? 0 : 4,
              marginTop: mobile ? 25 : 45,
            }}
          >
            {evSoldOut ? (
              <Text
                style={{
                  fontSize: mobile ? 12 : 14,
                  fontWeight: "600",
                  color: "red",
                }}
              >
                Sold Out
              </Text>
            ) : (
              <Text style={{ fontSize: mobile ? 12 : 14, fontWeight: "600" }}>
                {brandContextData?._id === "66a8023aeb802bf5bf7b0096" ? (
                  "Register Now"
                ) : (
                  <>
                    {lowestPrice === 0
                      ? `Free Event`
                      : `Starts at $${lowestPrice.toFixed(2)}`}
                  </>
                )}
              </Text>
            )}
            <img
              style={{
                width: 20,
                height: 20,
                fill: "black",
                marginLeft: 6,
              }}
              src={`https://extra-getit.s3.amazonaws.com/whitelabel-icons/external.png`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventItem;
