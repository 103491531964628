import React, { useEffect, useRef } from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import InvoicePaymentConfirmation from "../components/InvoicePaymentConfirmation";
import { useLocation } from "react-router-dom";

const PaymentConfirmation = () => {
  const location = useLocation();
  const confirmRef = useRef(null);
  useEffect(() => {
    if (confirmRef?.current) {
      confirmRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Content
        ref={confirmRef}
        style={{
          marginTop: 20,
          marginBottom: 100,
          flexDirection: "column",
          display: "flex",
        }}
      >
        {location?.state ? (
          <InvoicePaymentConfirmation
            paymentID={location.state.paymentID}
            total={location.state.total}
            imageUrl={location.state.imageUrl}
            encodedStadiumId={location.state.encodedStadiumId}
            orderAmount={location.state.orderAmount}
            serviceFee={location.state.serviceFee}
            feeLabel={location.state.feeLabel}
          />
        ) : null}
      </Content>
    </Layout>
  );
};

export default PaymentConfirmation;
