import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ServerProvider } from "./contexts/Server";
import { BrandContextProvider } from "./contexts/Brand";
import { CartsProvider } from "./contexts/Carts";
import AppSetup from "./AppSetup";
import { FirebaseProvider } from "./contexts/Firebase";
import { ChainProvider } from "./contexts/Chain";
import { AuthProvider } from "./contexts/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ServerProvider>
    <BrandContextProvider>
      <ChainProvider>
        <CartsProvider>
          <FirebaseProvider>
            <AuthProvider>
              <AppSetup />
            </AuthProvider>
          </FirebaseProvider>
        </CartsProvider>
      </ChainProvider>
    </BrandContextProvider>
  </ServerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
