import { Col, Typography, Row, Divider } from "antd";
import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const RetailCart = ({
  currentCart,
  removeItemFromCart,
  increaseQuantityToPurchase,
  decreaseQuantityToPurchase,
}) => {
  const { Text } = Typography;

  return (
    <>
      {currentCart.merchItems.map((cartItem) => {
        const selectedVariants = [];
        if (!cartItem.isSimpleProduct) {
          if (cartItem.variant1.isSelected) {
            selectedVariants.push(
              <Row key={1} style={{ paddingTop: 10 }}>
                <Col>
                  <Row>
                    <Text style={{ fontWeight: "600" }}>
                      {cartItem.variant1.variantName}
                    </Text>
                  </Row>
                  <Row>
                    <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                      {cartItem.variant1.selectedVariant}
                    </Text>
                  </Row>
                </Col>
              </Row>
            );
          }
          if (cartItem.variant2.isSelected) {
            selectedVariants.push(
              <Row key={2} style={{ paddingTop: 5 }}>
                <Col>
                  <Row>
                    <Text style={{ fontWeight: "600" }}>
                      {cartItem.variant2.variantName}
                    </Text>
                  </Row>
                  <Row>
                    <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                      {cartItem.variant2.selectedVariant}
                    </Text>
                  </Row>
                </Col>
              </Row>
            );
          }
          if (cartItem.variant3.isSelected) {
            selectedVariants.push(
              <Row key={3} style={{ paddingTop: 5 }}>
                <Col>
                  <Row>
                    <Text style={{ fontWeight: "600" }}>
                      {cartItem.variant3.variantName}
                    </Text>
                  </Row>
                  <Row>
                    <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                      {cartItem.variant3.selectedVariant}
                    </Text>
                  </Row>
                </Col>
              </Row>
            );
          }
        }

        return (
          <Col key={cartItem.apfRenderId} className="cartItemCol">
            <Row>
              <Col span={20}>
                <Row>
                  {cartItem.image ? (
                    <Col
                      className="imgCol"
                      style={{
                        backgroundImage: `url(${cartItem.image})`,
                      }}
                    ></Col>
                  ) : null}
                  <Col
                    style={{
                      paddingLeft: cartItem.image ? 10 : null,
                      width: cartItem.image ? "60%" : "80%",
                    }}
                  >
                    <Row>
                      <Text className="cartItemName ">{cartItem.pTitle}</Text>
                    </Row>
                    {selectedVariants}
                  </Col>
                </Row>
              </Col>
              <Col span={4} style={{ paddingBottom: 20 }}>
                <Row justify="end">
                  <Text
                    style={{
                      textAlign: "right",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                    }}
                  >
                    $
                    {(
                      Number(cartItem.price) * cartItem.quantityToPurchase
                    ).toFixed(2)}
                  </Text>
                </Row>
                <Row justify="end">
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => removeItemFromCart(cartItem)}
                  >
                    Remove
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Row className="quantityRow">
                  <Col>
                    <FaMinus
                      name="minus"
                      style={{
                        fontSize: 16,
                        color:
                          cartItem.quantityToPurchase === 1
                            ? "lightgray"
                            : "gray",
                      }}
                      onClick={() =>
                        cartItem.quantityToPurchase !== 1
                          ? decreaseQuantityToPurchase(cartItem)
                          : null
                      }
                    />
                  </Col>
                  <Text className="quantityToPurchase">
                    {cartItem.quantityToPurchase}
                  </Text>
                  <Col>
                    <FaPlus
                      name="plus"
                      style={{ fontSize: 15, color: "gray" }}
                      onClick={() => {
                        increaseQuantityToPurchase(cartItem);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
          </Col>
        );
      })}
    </>
  );
};

export default RetailCart;
