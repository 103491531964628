import { Col, Typography, Row, Divider } from "antd";
import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa";

const FoodCart = ({
  currentCart,
  removeItemFromCart,
  increaseQuantityToPurchase,
  decreaseQuantityToPurchase,
}) => {
  const { Text } = Typography;

  return (
    <>
      {currentCart.foodItems.map((cartItem) => {
        let cartItemOptionGroups = [];
        let addOnCost = 0;

        if (cartItem.optionGroups) {
          cartItemOptionGroups = cartItem.optionGroups.map((optionGroup) => {
            const options = optionGroup.zOptions.map((option) => {
              if (option.isSelected === true) {
                addOnCost = addOnCost + option.optionObjExtraCost;
                return (
                  <Row key={option.optionObjId} style={{ paddingLeft: 10 }}>
                    <Text className="optionObjName">
                      {option.optionObjName}
                    </Text>

                    <Text className="optionObjExtraCost">
                      +${Number(option.optionObjExtraCost).toFixed(2)}
                    </Text>
                  </Row>
                );
              } else {
                return null;
              }
            });
            return (
              <Col key={optionGroup.optionGroupId} style={{ paddingTop: 5 }}>
                <Row>
                  <Text className="optionGroupName">
                    {optionGroup.optionGroupName}
                  </Text>
                </Row>
                <Row>{options}</Row>
              </Col>
            );
          });
        }
        return (
          <Col key={cartItem.apfRenderId} className="cartItemCol">
            <Row>
              <Col span={20}>
                <Row>
                  {cartItem.fXQImageURL ? (
                    <Col
                      className="imgCol"
                      style={{
                        backgroundImage: `url(${cartItem.fXQImageURL})`,
                      }}
                    ></Col>
                  ) : null}
                  <Col
                    style={{
                      paddingLeft: cartItem.fXQImageURL ? 10 : null,
                      width: cartItem.fXQImageURL ? "60%" : "80%",
                    }}
                  >
                    <Text className="cartItemName ">{cartItem.name}</Text>
                    {cartItemOptionGroups}
                  </Col>
                </Row>
              </Col>
              <Col span={4} style={{ paddingBottom: 20 }}>
                <Row justify="end">
                  <Text
                    style={{
                      textAlign: "right",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                    }}
                  >
                    $
                    {(
                      (Number(cartItem.price) + addOnCost) *
                      cartItem.quantityToPurchase
                    ).toFixed(2)}
                  </Text>
                </Row>
                <Row justify="end">
                  <Text
                    style={{
                      textDecorationLine: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => removeItemFromCart(cartItem)}
                  >
                    Remove
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Row className="quantityRow">
                  <Col>
                    <FaMinus
                      name="minus"
                      style={{
                        fontSize: 16,
                        color:
                          cartItem.quantityToPurchase === 1
                            ? "lightgray"
                            : "gray",
                      }}
                      onClick={() =>
                        cartItem.quantityToPurchase !== 1
                          ? decreaseQuantityToPurchase(cartItem)
                          : null
                      }
                    />
                  </Col>
                  <Text className="quantityToPurchase">
                    {cartItem.quantityToPurchase}
                  </Text>
                  <Col>
                    <FaPlus
                      name="plus"
                      style={{ fontSize: 15, color: "gray" }}
                      onClick={() => {
                        increaseQuantityToPurchase(cartItem);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider />
          </Col>
        );
      })}
    </>
  );
};

export default FoodCart;
