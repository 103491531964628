import {
  Breadcrumb,
  Col,
  Image,
  Layout,
  Row,
  Spin,
  Typography,
  notification,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SquarePayment from "../components/SquarePayment";
import { encodeParamString, screenWidths } from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useCarts } from "../contexts/Carts";
import { useServer } from "../contexts/Server";
import { trace } from "firebase/performance";
import { useFirebase } from "../contexts/Firebase";
import { useBrandContext } from "../contexts/Brand";
import { logEvent } from "firebase/analytics";
import { useAuth } from "../contexts/Auth";

const Payment = () => {
  const { multiCart, setMultiCartData } = useCarts();
  const { brandContextData } = useBrandContext();
  const { getUserPoints } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [orderTotalWithEverything, setOrderTotalWithEverything] = useState(
    location.state && location.state.orderTotalWithEverything
      ? location.state.orderTotalWithEverything
      : 0
  );
  const [currOrder, setCurrOrder] = useState(null);
  const { serverData } = useServer();
  const { firebaseAnalytics } = useFirebase();
  useEffect(() => {
    if (
      !location.state ||
      !location.state.orderTotalWithEverything ||
      !location.state.orderID ||
      !location.state.orderTotalWithTax ||
      !location.state.tipAmount
    ) {
      navigate("/cart", false);
    }
    checkOrderPaidFor();
  }, []);

  // Checks if the order is already paid for
  // If so, navigate them to the order screen
  const checkOrderPaidFor = async () => {
    if (!serverData) return;
    const order = await serverData.call(
      "fetchOrderById",
      location.state.orderID
    );
    if (order && order.status !== "Pending for Payment") {
      navigate("/order", false);
    }
  };

  const [loading, setLoading] = useState(false);
  const { Title } = Typography;

  const { width } = useWindowDimensions();
  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  const paymentErrorNotification = (placement, description) => {
    notification.info({
      message: "Payment Error",
      description,
      placement,
    });
  };

  const cantRetrieveOrderNotification = (placement, description) => {
    notification.success({
      message: "Order Placed!",
      description,
      placement,
      duration: 0,
    });
  };

  const makeThePayment = async (token, verifiedBuyer) => {
    // TODO Integrate this
    // const t = trace(firebasePerf, "order_placement_trace");
    // t.start();

    const { orderID, orderTotalWithTax, tipAmount } = location.state;
    setLoading(true);
    let paymentXYZObj = {
      paymentXType: "xpayment-entered-card",
      nonce: token.token,
    };

    // Need to handle flow without credit card for cash payments
    if (parseInt(orderTotalWithTax) === 0) {
      // Process as cash.
      paymentXYZObj = {
        paymentXType: "xpayment-at-store",
      };
    }

    try {
      await serverData.call("USSR_makePayment", {
        orderID,
        userAppCalculatedOrder: orderTotalWithEverything,
        userAppTipped: tipAmount,
        paymentXYZObj,
        verifiedBuyer,
      });
    } catch (error) {
      if (error.reason) {
        setLoading(false);
        paymentErrorNotification("top", error.reason);
        return;
      } else {
        setLoading(false);
        paymentErrorNotification(
          "top",
          "Something went wrong with paying for your order. Please try again later"
        );
        return;
      }
    }

    //Remove current cart
    const newMultiCart = multiCart.filter(
      (cart) => cart.stadiumID !== location.state.cart.stadiumID
    );
    setMultiCartData(newMultiCart);

    let order = null;

    try {
      order = await serverData.call("fetchOrderById", orderID);
      setCurrOrder(order);
    } catch (e) {
      setLoading(false);
      cantRetrieveOrderNotification(
        "bottom",
        // eslint-disable-next-line quotes
        `Thank you for using Getit Local!`
      );
      navigate("/order");
      return;
    }

    const { stadium, orderNo } = order;

    setLoading(false);

    let items = [];
    if (order?.ORDER_QTYPE === "QVENUE") {
      order?.eventAdmissions?.forEach((admission) => {
        items.push({ item_id: admission?.evId, item_name: admission?.evName });
      });
    }

    if (order?.ORDER_QTYPE === "QFOOD") {
      order?.v2_foodCart?.forEach((foodItem) => {
        items.push({ item_id: foodItem?._id, item_name: foodItem?.name });
      });
    }

    if (order?.ORDER_QTYPE === "QRETAIL") {
      order?.omprodItems?.forEach((retailItem) => {
        items.push({
          item_id: retailItem?.retailItemId,
          item_name: retailItem?.omPtitle,
        });
      });
    }

    let payCurrency = order?.paymentCurrency;
    if (!order?.paymentCurrency) {
      payCurrency = "CAD";
    }

    if (firebaseAnalytics) {
      logEvent(firebaseAnalytics, "purchase", {
        orderNo: order?.orderNo,
        tip: order?.tipping?.amount,
        transaction_id: String(orderNo),
        currency: payCurrency,
        value: Number(order?.paymentInfo?.orderAmount), // Total Revenue
        tax: Number(order?.paymentInfo?.hst),
        items,
      });
    }
    getUserPoints(brandContextData._id);
    navigate(`/myorders/${orderNo}`, {
      state: {
        order,
        stadium,
      },
    });
  };

  const showBusinessOfferings = () => {
    const { stadium = { _id: "", name: "" } } = currOrder;
    const { _id: stadId = "", name: stadName = "" } = stadium;
    const stadiumNameDashes = stadName.replaceAll(" ", "-");
    let encodedStoreId = encodeParamString(stadId);
    const newStoreUrl = `/order/${stadiumNameDashes}?glb=${encodedStoreId}`;
    navigate(newStoreUrl);
  };

  const showCheckout = () => {
    const { stadium = { _id: "", name: "" } } = currOrder;
    const { _id: stadId = "", name: stadName = "" } = stadium;
    const stadiumNameDashes = stadName.replaceAll(" ", "-");
    const foundCart = multiCart.find((cart) => cart.stadiumID === stadId);
    const newStoreUrl = `/checkout/${stadiumNameDashes}?glb=${foundCart.stadiumID}`;

    navigate(newStoreUrl, {
      state: {
        cart: foundCart,
      },
    });
  };

  const getCurrOrder = async () => {
    let order = null;
    const { orderID } = location.state;
    try {
      order = await serverData.call("fetchOrderById", orderID);
      setCurrOrder(order);
    } catch (e) {
      console.log(e);
    }
  };

  // Need to get the current order when the page loads now,
  // since we are building a breadcrumb for the user to be able to navigate back
  useEffect(() => {
    getCurrOrder();
  }, []);

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Spin
        size="large"
        spinning={loading}
        tip="Placing Order... Please do not navigate away or refresh the page."
      >
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {currOrder ? (
            <Row>
              <Col
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 12, offset: 3 }}
                xl={{ span: 12, offset: 6 }}
                xs={{ span: 22, offset: 1 }}
              >
                <Breadcrumb
                  items={[
                    {
                      title: (
                        <a onClick={() => showBusinessOfferings()}>Menu</a>
                      ),
                    },
                    {
                      title: <a onClick={() => showCheckout()}>Checkout</a>,
                    },
                    { title: "Payment" },
                  ]}
                  style={{ marginBottom: 6 }}
                />
              </Col>
            </Row>
          ) : null}

          {currOrder?.stadium?.imageUrl ? (
            <Row>
              <Col
                xs={24}
                style={{ justifyContent: "center", display: "flex" }}
              >
                <Image
                  src={currOrder.stadium.imageUrl}
                  style={{ width: mobileSize ? 150 : 150 }}
                  preview={false}
                />
              </Col>
            </Row>
          ) : null}

          <Row>
            <Col
              sm={{ span: 22, offset: 1 }}
              md={{ span: 22, offset: 1 }}
              lg={{ span: 12, offset: 3 }}
              xl={{ span: 12, offset: 6 }}
              xs={{ span: 22, offset: 1 }}
            >
              <Title
                style={mobileSize ? { fontSize: 22 } : { fontSize: "default" }}
              >
                Payment
              </Title>
            </Col>
          </Row>
          <Row style={{ height: "100%" }}>
            <Col span={24}>
              <SquarePayment
                orderTotalWithEverything={orderTotalWithEverything}
                makeThePayment={makeThePayment}
                firstName={location.state.firstName}
                lastName={location.state.lastName}
                email={location.state.email}
                phoneNumber={location.state.phoneNumber}
              />
            </Col>
          </Row>
        </Content>
      </Spin>
    </Layout>
  );
};

export default Payment;
