import React, { createContext, useContext, useEffect, useState } from "react";
import { useBrandContext } from "./Brand";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// Create the FirebaseContext with the data type specified
// and a empty object
const FirebaseContext = createContext();

const FirebaseProvider = ({ children }) => {
  const { brandContextData } = useBrandContext();
  const [firebaseApp, setFirebaseApp] = useState(null);
  const [firebaseAnalytics, setFirebaseAnalytics] = useState(null);
  const [firebasePerf, setFirebasePerf] = useState(null);

  useEffect(() => {
    if (brandContextData) {
      initFirebase();
    }
  }, [brandContextData]);

  const initFirebase = () => {
    if (process.env.REACT_APP_BUILD_MODE === "prod") {
      const {
        firebaseApiKey,
        firebaseAuthDomain,
        firebaseProjectId,
        firebaseStorageBucket,
        firebaseMessagingSenderId,
        firebaseAppId,
        firebaseMeasurementId,
      } = brandContextData;

      // Your web app's Firebase configuration
      // For Firebase JS SDK v7.20.0 and later, measurementId is optional
      const firebaseConfig = {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        storageBucket: firebaseStorageBucket,
        messagingSenderId: firebaseMessagingSenderId,
        appId: firebaseAppId,
        measurementId: firebaseMeasurementId,
      };

      const app = initializeApp(firebaseConfig);
      const analytics = getAnalytics(app);
      const perf = getPerformance(app);

      setFirebaseApp(app);
      setFirebaseAnalytics(analytics);
      setFirebasePerf(perf);
    }
  };

  return (
    // This component will be used to encapsulate the whole App,
    // so all components will have access to the Context
    <FirebaseContext.Provider
      value={{
        firebaseApp,
        firebasePerf,
        firebaseAnalytics,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

// A simple hooks to facilitate the access to the FirebaseContext
// and permit components to subscribe to FirebaseContext updates
function useFirebase() {
  const context = useContext(FirebaseContext);

  if (!context) {
    throw new Error("useServer must be used within an ServerProvider");
  }

  return context;
}

export { FirebaseContext, FirebaseProvider, useFirebase };
