import React from "react";
import { Col, Typography } from "antd";
import FeaturedItem from "./FeaturedItem";
import { useChain } from "../contexts/Chain";
const { Title} = Typography;

const FeaturedItemsSection = ({ mobile }) => {
  const { featuredItems, locationSelected } = useChain();
  if (!featuredItems.length || !locationSelected) {
    return;
  }
  return (
    <Col style={{ paddingTop: 10, paddingBottom: 10 }}>
      <Title
        style={{
          textAlign: mobile ? "center" : "left",
          fontSize: mobile ? 22 : 38,
          fontWeight: "bold",
          paddingBottom: 20,
        }}
      >
        Featured Items
      </Title>
      <div
        style={{
          display: "flex",
          overflowX: "auto",
          paddingBottom: 20,
          gap: mobile ? 17 : 25,
        }}
      >
        {featuredItems.map((item) => {
          return <FeaturedItem key={item._id} featuredItem={item} />;
        })}
      </div>
    </Col>
  );
};

export default FeaturedItemsSection;
