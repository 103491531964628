import { Button, Col, Row, Typography, theme } from "antd";
import React from "react";
const { useToken } = theme;
const { Text } = Typography;

const QuickAccessBtn = ({ quickAccessItem, mobile }) => {
  const { token } = useToken();
  const iconLink = quickAccessItem.qIcon;
  return (
    <Col
      style={{
        display: "flex",
        flex: 1,
        maxWidth: 200,
        justifyContent: "center",
      }}
    >
      <a href={quickAccessItem.qLink} target="_blank" rel="noreferrer">
        <Row style={{ width: "100%", justifyContent: "center" }}>
          <Col span={24}>
            <Button
              ghost
              style={{
                width: "100%",
                height: mobile ? 70 : 125,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ width: mobile ? 85 : 100, height: mobile ? 60 : 100 }}
                src={iconLink}
              />
            </Button>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 14 }}>
            <Text
              style={{
                fontSize: mobile ? 14 : 22,
                fontWeight: "600",
                textAlign: "center",
                wordBreak: "normal",
              }}
            >
              {quickAccessItem.qTitle}
            </Text>
          </Col>
        </Row>
      </a>
    </Col>
  );
};

export default QuickAccessBtn;
