import React, { useEffect, useState, useRef } from "react";
import { Layout, Row, Image, Spin, notification, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SquarePayment from "../components/SquarePayment";
import { useServer } from "../contexts/Server";
import {
  decodeParamString,
  encodeParamString,
  screenWidths,
} from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import styles from "../styles/InvoicePayment.module.css";

const InvoicePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { serverData } = useServer();
  const paymentRef = useRef(null);

  const { encodedOrderId } = useParams();
  const [currOrder, setCurrOrder] = useState(null);
  const [placingOrder, setPlacingOrder] = useState(false);

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  useEffect(() => {
    if (paymentRef?.current) {
      paymentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  useEffect(() => {
    if (serverData) {
      if (location?.state?.orderID) {
        getOrderData(location.state.orderID);
      } else {
        try {
          const orderId = decodeParamString(encodedOrderId);
          getOrderData(orderId);
        } catch (e) {
          notification.error({
            message:
              "There was an error with retreiving the invoice. Please try creating it again.",
          });
        }
      }
    }
  }, [serverData]);

  const getOrderData = async (orderID) => {
    try {
      const res = await serverData.call("USSR_getOrderId", {
        orderId: orderID,
      });
      setCurrOrder(res);
    } catch (error) {
      console.log(error);
      notification.error({
        message:
          "There was an error with retreiving the invoice. Please try creating it again.",
      });
    }
  };

  const makeThePayment = async (token, verifiedBuyer) => {
    setPlacingOrder(true);
    const { amount, _id, paymentInfo } = currOrder;
    let paymentXYZObj = {
      paymentXType: "xpayment-entered-card",
      nonce: token.token,
    };
    try {
      const res = await serverData.call("USSR_makePayment", {
        orderID: _id,
        userAppCalculatedOrder: amount,
        userAppTipped: 0,
        paymentXYZObj,
        verifiedBuyer,
      });
      const { paymentID } = res;
      const encodedOrderId = encodeParamString(_id);
      const imageUrl = currOrder?.stadium?.imageUrl
        ? currOrder.stadium.imageUrl
        : null;
      const newStoreUrl = `/${encodedOrderId}/invoiceconfirmation`;
      navigate(newStoreUrl, {
        state: {
          paymentID,
          total: amount,
          imageUrl,
          encodedStadiumId: location?.state?.encodedStadiumId
            ? location.state.encodedStadiumId
            : null,
          feeLabel: location?.state?.feeLabel ? location.state.feeLabel : null,
          orderAmount: paymentInfo.orderAmount,
          serviceFee: paymentInfo.merchantServiceFee,
        },
      });
    } catch (error) {
      console.log(error);
      if (error?.message) {
        message.error(error?.message);
      }
    }
    setPlacingOrder(false);
  };

  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Spin tip="Loading..." spinning={placingOrder}>
        <Content
          ref={paymentRef}
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {currOrder?.stadium?.imageUrl ? (
            <Row
              justify={"center"}
              className={styles.logoContainer}
              style={{ paddingBottom: 50 }}
            >
              <Image
                width={mobile ? "50%" : 180}
                preview={false}
                src={currOrder.stadium.imageUrl}
                style={{
                  maxHeight: 180,
                  minHeight: 75,
                  objectFit: "contain",
                }}
              />
            </Row>
          ) : null}
          {currOrder ? (
            <SquarePayment
              makeThePayment={makeThePayment}
              orderTotalWithEverything={currOrder.amount.toFixed(2)}
              firstName={null}
              lastName={null}
              email={currOrder.orderedBy.email}
              phoneNumber={null}
            />
          ) : null}
        </Content>
      </Spin>
    </Layout>
  );
};

export default InvoicePayment;
