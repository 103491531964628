import React, { useState } from "react";
import EventItemList from "./EventItemList";
// import EventFoodItemList from "./EventFoodItemList";
// import FoodItemModal from "./FoodItemModal";
import EventItemModal from "./EventItemModal";

const EventCatListing = ({
  stadium,
  categoryItem,
  eventItemData,
  mobileSize,
}) => {
  const [showEventItemModal, setShowEventItemModal] = useState(false);
  // const [showFoodItemModal, setShowFoodItemModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  return (
    <>
      {
        categoryItem.evCatPosition ? (
          <EventItemList
            categoryItem={categoryItem}
            eventItemData={eventItemData}
            stadium={stadium}
            setCurrentItem={setCurrentItem}
            setShowEventItemModal={setShowEventItemModal}
            mobileSize={mobileSize}
          />
        ) : null
        // ( eventItemData.length > 0 && (
        //     <EventFoodItemList
        //       foodItems={eventItemData}
        //       setShowFoodItemModal={setShowFoodItemModal}
        //       setCurrentItem={setCurrentItem}
        //     />
        //   ))
      }
      {/* <FoodItemModal
        showFoodItemModal={showFoodItemModal}
        setShowFoodItemModal={setShowFoodItemModal}
        currentItem={currentItem}
        categoryItem={categoryItem}
        stadium={stadium}
      /> */}
      <EventItemModal
        showEventItemModal={showEventItemModal}
        setShowEventItemModal={setShowEventItemModal}
        currentItem={currentItem}
        categoryItem={categoryItem}
        stadium={stadium}
      />
    </>
  );
};

export default EventCatListing;
