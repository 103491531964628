import { Col, Typography, Row, Divider, Avatar, ConfigProvider } from "antd";
import React, { useContext } from "react";
import {
  showThisEvent,
  momEventStartTime,
  screenWidths,
} from "../const/const_functions";
import useWindowDimensions from "../hooks/useWindowDimensions";
import styles from "../styles/EventItemList.module.css";
import moment from "moment";
import { AiOutlineEnvironment } from "react-icons/ai";
const { Text } = Typography;

const EventItemList = ({
  eventItemData,
  categoryItem,
  stadium,
  setCurrentItem,
  setShowEventItemModal,
  mobileSize,
}) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { width } = useWindowDimensions();
  const imgSize = screenWidths.xxl > width ? 80 : 160;
  const viewSelectedItem = (item) => {
    setCurrentItem(item);
    setShowEventItemModal(true);
  };

  return (
    <Col className={styles.eventContainer}>
      {screenWidths.xl > width ? (
        <Row className={styles.evCatRow}>
          <Col className={styles.evCatNameCol}>
            <Text className={styles.evCatName}>{categoryItem.evCatName}</Text>
            <Text className={styles.evCatDesc}>{categoryItem.evCatDesc}</Text>
          </Col>
        </Row>
      ) : null}

      {eventItemData.length > 0 &&
        eventItemData.map((item) => {
          if (item.evCatId) {
            if (!showThisEvent(item)) return null;
          }
          let evImgUrl = "";
          if (
            typeof item.evFeaturedImage === "string" &&
            item.evFeaturedImage.trim() !== ""
          ) {
            evImgUrl = item.evFeaturedImage; // no string trimming, this should be a url for sure
          }
          let mySaneDesc = "";
          if (typeof item.evDesc === "string" && item.evDesc.trim() !== "") {
            mySaneDesc = item.evDesc.trim(); // string trimming because desc could have space around it and be valid
          }
          const isValid = item.evAdmissionTypes.every(
            (item) => item.evAdmisQuantity > 0
          );
          if (isValid) {
            // TODO
            // At least one show in categoery has at least one ticket available
          }

          let admisTypeToShow = null;
          let foundAvailableAdmissionType = false;
          for (const admisType of item.evAdmissionTypes) {
            if (
              admisType.evAdmisActive &&
              admisType.evAdmisQuantity > 0 &&
              item.evAdmissionTypes.length > 0
            ) {
              admisTypeToShow = admisType;
              foundAvailableAdmissionType = true;
              break;
            } else {
              admisTypeToShow = admisType;
            }
          }

          let formattedDateWithTimeZone = null;
          if (item.evOperatingTime) {
            const eventStartTime = item.evOperatingTime.evStartTime;

            formattedDateWithTimeZone = momEventStartTime(
              new Date(eventStartTime).getTime(),
              item.evTimeZone
            );
          }

          const currentDate = new Date();
          let eventConcluded = false;
          if (moment(currentDate).isAfter(item.evOperatingTime.evEndTime)) {
            eventConcluded = true;
          }

          const priceRow = (
            <div className={styles.priceRow}>
              <div
                style={{
                  display: "flex",
                  alignItems: "self-end",
                  maxWidth: "70%",
                }}
              >
                <AiOutlineEnvironment
                  style={{
                    fontSize: mobileSize ? 22 : 26,
                    color: theme.token.colorSecondary,
                  }}
                />
                <Text className={styles.evAddress}>
                  {item.evAddress.physical}
                </Text>
              </div>
              <div
                style={{
                  paddingLeft: 5,
                  alignItems: "self-end",
                  display: "flex",
                }}
              >
                {foundAvailableAdmissionType === false ? (
                  <Text className={styles.evRedText}>Sold Out</Text>
                ) : (
                  <Text className={styles.evPrice}>
                    ${Number(admisTypeToShow.evAdmisBasePrice).toFixed(2)}
                  </Text>
                )}
              </div>
            </div>
          );

          return (
            <Col key={item._id} className={styles.evMain}>
              <Row
                onClick={() => viewSelectedItem(item)}
                style={{ cursor: "pointer" }}
              >
                <div className={styles.evTimeBox}>
                  <div className={styles.evGrayTimeBox}>
                    <Text className={styles.evDate}>
                      {formattedDateWithTimeZone.month}
                    </Text>
                    <Text className={styles.evDay}>
                      {formattedDateWithTimeZone.day}
                    </Text>
                    <Text className={styles.evDate}>
                      {formattedDateWithTimeZone.year}
                    </Text>
                  </div>
                  <div className={styles.evBlackTimeBox}>
                    <Text className={styles.evTime}>
                      {formattedDateWithTimeZone.time}
                    </Text>
                  </div>
                </div>
                <div className={styles.evDetailsCol}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Text className={styles.evName}>{item.evName}</Text>
                    <Text className={styles.evDesc}>{mySaneDesc}</Text>
                    {eventConcluded && (
                      <Row>
                        <Text className={styles.evRedText}>
                          Event Completed
                        </Text>
                      </Row>
                    )}
                  </div>
                  {!mobileSize ? priceRow : null}
                </div>
                {evImgUrl ? (
                  <Avatar
                    className={styles.evImg}
                    src={evImgUrl}
                    style={{
                      width: mobileSize ? 75 : imgSize,
                      height: mobileSize ? 75 : imgSize,
                    }}
                  />
                ) : null}
              </Row>
              {mobileSize ? priceRow : null}
              <Divider />
            </Col>
          );
        })}
    </Col>
  );
};

export default EventItemList;
