import { Col, Image, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useBrandContext } from "../contexts/Brand";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths } from "../const/const_functions";
import { useServer } from "../contexts/Server";

const AboutUs = () => {
  const { Text, Link } = Typography;
  const { brandContextData } = useBrandContext();
  const { serverData } = useServer();
  const [aboutSection, setAboutSection] = useState(null);

  const getAboutSection = async (passedContextId) => {
    try {
      const aboutSectionRes = await serverData.call("MIKO_getAboutSection", {
        contextId: passedContextId,
      });
      setAboutSection(aboutSectionRes.aboutSection);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (serverData && brandContextData._id) {
      getAboutSection(brandContextData._id);
    }
  }, [serverData]);

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }
  let imageWidth = width * (9 / 24) - 15;

  const renderImage = (image, index, column) => {
    if (column === 1 && index % 2 === 1) return null;
    if (column === 2 && index % 2 === 0) return null;
    return (
      <Link
        key={index}
        href={image.galleryNavUrl ? image.galleryNavUrl : null}
        target={"blank"}
      >
        <Image
          style={{
            marginTop: mobile ? 0 : 20,
            width: mobile ? "100%" : imageWidth,
            height: "auto",
          }}
          preview={false}
          src={image.galleryImageUrl}
          alt="Opening Background Image"
        />
      </Link>
    );
  };

  return (
    <>
      {aboutSection?.openingBackgroundImage ? (
        <Row
          style={{
            backgroundImage: `url(${aboutSection?.openingBackgroundImage})`,
            height: mobile ? 200 : 400,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      ) : null}
      {mobile ? (
        <>
          <Row
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: 30,
              paddingTop: 40,
              paddingBottom: aboutSection?.openingAccImage ? 40 : 10,
            }}
          >
            <Text
              style={{
                fontSize: 20,
                color: brandContextData.brandTheme.colorPrimary,
              }}
            >
              {aboutSection?.openingHeading}
            </Text>
            <Text
              style={{ fontSize: 14, marginTop: 10, whiteSpace: "pre-line" }}
            >
              {aboutSection?.openingDescription}
            </Text>
          </Row>
          {aboutSection?.openingAccImage ? (
            <Image
              preview={false}
              src={aboutSection?.openingAccImage}
              alt="Opening Background Image"
            />
          ) : null}
        </>
      ) : (
        <Row style={{ marginTop: 30 }} gutter={20}>
          <Col
            span={aboutSection?.openingAccImage ? 9 : 18}
            offset={3}
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 32,
                maxWidth: "100%",
                color: brandContextData.brandTheme.colorPrimary,
              }}
            >
              {aboutSection?.openingHeading}
            </Text>
            <Text
              style={{ fontSize: 18, marginTop: 10, whiteSpace: "pre-line" }}
            >
              {aboutSection?.openingDescription}
            </Text>
          </Col>
          <Col span={aboutSection?.openingAccImage ? 9 : 0} style={{ flex: 1 }}>
            <Row
              style={{
                marginLeft: 30,
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                paddingTop: 50,
                paddingBottom: 50,
              }}
            >
              {aboutSection?.openingAccImage ? (
                <Image
                  style={{ maxWidth: "100%", height: "auto", borderRadius: 12 }}
                  preview={false}
                  src={aboutSection?.openingAccImage}
                  alt=""
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      )}
      {aboutSection?.quoteText ? (
        <Row
          style={{
            margin: mobile ? 30 : 50,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: mobile ? 20 : 30,
              color: brandContextData.brandTheme.colorSecondary,
            }}
          >
            "{aboutSection?.quoteText}"
          </Text>
          {aboutSection?.quotePersonName ? (
            <Text
              style={{
                fontSize: mobile ? 14 : 18,
                color: brandContextData.brandTheme.colorSecondary,
              }}
            >
              - {aboutSection.quotePersonName}
            </Text>
          ) : null}
        </Row>
      ) : null}
      {aboutSection?.imageGallery ? (
        mobile ? (
          <Row>
            {aboutSection.imageGallery.map((item, index) => {
              return renderImage(item, index, 3);
            })}
          </Row>
        ) : (
          <Row gutter={[20, 20]}>
            <Col offset={3} span={9} style={{ flexDirection: "row" }}>
              {aboutSection.imageGallery.map((item, index) => {
                return renderImage(item, index, 1);
              })}
            </Col>
            <Col
              span={9}
              style={{ flexDirection: "row", justifyContent: "flex-end" }}
            >
              {aboutSection.imageGallery.map((item, index) => {
                return renderImage(item, index, 2);
              })}
            </Col>
          </Row>
        )
      ) : null}
    </>
  );
};

export default AboutUs;
