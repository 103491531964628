import React, { createContext, useState, useContext, useEffect } from "react";
import { useBrandContext } from "./Brand";
import { useChain } from "../contexts/Chain";
import { removeThisCart } from "../const/const_functions";

// Create the Cart Context with the data type specified
// and a empty object
const CartsContext = createContext();

const CartsProvider = ({ children }) => {
  const [multiCart, setMultiCart] = useState([]);
  const [filteredMultiCart, setFilteredMultiCart] = useState([]);
  const { brandContextData } = useBrandContext();
  const { locationSelected } = useChain();

  useEffect(() => {
    if (brandContextData) {
      // Everytime a page is opened we get carts
      fetchMultiCartData();
    }
  }, [brandContextData, locationSelected]);

  const fetchMultiCartData = () => {
    let multiCart = JSON.parse(
      localStorage.getItem(`multiCart${brandContextData._id}`)
    );

    if (!multiCart) {
      setMultiCart([]);
    } else {
      //Remove a cart if createdAt greater than 24 houres or if a cart doesnt have createdAt field
      multiCart = multiCart.filter((cart) => !removeThisCart(cart));

      setMultiCartData(multiCart);
    }
  };

  const setMultiCartData = (multiCartSent) => {
    localStorage.setItem(
      `multiCart${brandContextData._id}`,
      JSON.stringify(multiCartSent)
    );
    setMultiCart(multiCartSent);

    ///If brand isChain show events and selected store carts
    let newFilteredMultiCart = multiCartSent;
    if (brandContextData?.isChain && locationSelected) {
      const filteredCarts = multiCartSent.filter(
        (cart) =>
          cart.stadiumID === locationSelected._id ||
          cart.stadTypes.includes("STT_VENUE")
      );
      newFilteredMultiCart = filteredCarts;
    }
    setFilteredMultiCart(newFilteredMultiCart);
  };

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <CartsContext.Provider
      value={{ multiCart, setMultiCartData, filteredMultiCart }}
    >
      {children}
    </CartsContext.Provider>
  );
};

function useCarts() {
  const context = useContext(CartsContext);

  if (!context) {
    throw new Error("useCarts must be used within an CartsContext");
  }

  return context;
}

export { CartsContext, CartsProvider, useCarts };
