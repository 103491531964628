import React, { useEffect, useRef } from "react";
import { Layout, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import InvoicePaymentForm from "../components/InvoicePaymentForm";
import CataloguePaymentForm from "../components/CataloguePaymentForm";

const Invoice = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const invoiceRef = useRef(null);
  useEffect(() => {
    if (invoiceRef?.current) {
      invoiceRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  const paymentForm = state?.catalogueMode ? (
    <CataloguePaymentForm />
  ) : (
    <InvoicePaymentForm />
  );
  return (
    <Layout style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <Spin
        size="large"
        spinning={loading}
        tip="Paying invoice... Please do not navigate away or refresh the page."
      >
        <Content
          ref={invoiceRef}
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          {paymentForm}
        </Content>
      </Spin>
    </Layout>
  );
};

export default Invoice;
