import { Row, Typography } from "antd";
import * as FontAwesome from "react-icons/fa";
import styles from "../styles/SocialLink.module.css";

const SocialLinks = ({ brandSocial, titleText, iconColor, mobile }) => {
  const { Text, Link } = Typography;

  const headerTitleText = titleText ? titleText : "Connect with us";
  const iconColourStyle = iconColor ? iconColor : "black";

  const iconSize = mobile ? "2.5em" : "3em";

  const renderSocialIcon = (passedSocialLink, passedIndex) => {
    if (!passedSocialLink.iconName || !passedSocialLink.navUrl) return null;

    const iconLink = passedSocialLink.iconName;

    return (
      <Link
        key={passedIndex}
        href={passedSocialLink.navUrl}
        target={"blank"}
        style={{ color: iconColourStyle, marginLeft: 3, marginRight: 3 }}
      >
        <img style={{ width: iconSize, height: iconSize }} src={iconLink} />
      </Link>
    );
  };

  return (
    <Row className={styles.container} style={{ marginTop: 40 }}>
      <Text
        className={
          mobile ? styles.mobileHeaderTitleText : styles.desktopHeaderTitleText
        }
      >
        {headerTitleText}
      </Text>
      <div className={styles.iconContainer}>
        {brandSocial.map((socialLink, index) =>
          renderSocialIcon(socialLink, index)
        )}
      </div>
    </Row>
  );
};

export default SocialLinks;
