import React, { useState, useEffect } from "react";
import { Col, Typography, Row, Divider, Popconfirm } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa";
import {
  encodeParamString,
  mom4tzDateAndTime,
} from "../../const/const_functions";
import EditFoodItemCard from "./EditFoodItemCard";
import { useNavigate } from "react-router-dom";
import { useBrandContext } from "../../contexts/Brand";

const EditEventItemCard = ({ currentCart, multiCart, setMultiCartData }) => {
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const [cart, setCart] = useState(currentCart);
  const { Text } = Typography;
  const navigate = useNavigate();

  const isEventItem =
    currentCart.eventItems && currentCart.eventItems.length > 0 ? true : false;
  const isFoodItem =
    currentCart.foodItems && currentCart.foodItems.length > 0 ? true : false;

  useEffect(() => {
    if (cart && multiCart && multiCart.length) {
      updateMultiCart();
    }
  }, [cart]);

  const removeItemFromCart = (cartItem, evAdmisId, evpRenderId) => {
    if (cartItem.apfRenderId) {
      //If Food item
      const foundItem = currentCart.foodItems.some(
        (item) => item.apfRenderId === cartItem.apfRenderId
      );
      if (foundItem) {
        let newCart = {};
        if (currentCart.foodItems.length === 1) {
          //Remove foodItems from currentCart
          newCart = { ...cart };
          delete newCart.foodItems;
        } else {
          //Remove current item
          const newFoodItems = currentCart.foodItems.filter(
            (item) => item.apfRenderId !== cartItem.apfRenderId
          );
          newCart = { ...cart, foodItems: newFoodItems };
        }
        setCart(newCart);
      }
    } else if (cartItem.evpRenderId) {
      //If Event item
      const rightEvent = currentCart.eventItems.find(
        (event) => event.evpRenderId === evpRenderId
      );
      const newAdmissionPurchaseQuantities =
        rightEvent.admissionPurchaseQuantities.filter(
          (admisType) => admisType.evAdmisId !== evAdmisId
        );
      let newCart = {};

      if (newAdmissionPurchaseQuantities.length === 0) {
        //Remove current event item
        const newEventItems = cart.eventItems.filter(
          (eventItem) => eventItem.evpRenderId !== evpRenderId
        );
        newCart = { ...cart, eventItems: newEventItems };
      } else {
        const newEventItems = cart.eventItems.map((eventItem) =>
          eventItem.evpRenderId === evpRenderId
            ? {
                ...eventItem,
                admissionPurchaseQuantities: newAdmissionPurchaseQuantities,
              }
            : eventItem
        );
        newCart = { ...cart, eventItems: newEventItems };
      }
      if (newCart.eventItems && newCart.eventItems.length === 0) {
        //Remove eventItems from currentCart
        delete newCart.eventItems;
      }
      setCart(newCart);
    }
  };

  const decreaseQuantityToPurchase = (cartItem, evAdmisId) => {
    let tempCart = { ...currentCart };
    if (cartItem.apfRenderId) {
      if (cartItem.quantityToPurchase > 1) {
        for (let foodItem of tempCart.foodItems) {
          if (cartItem.apfRenderId === foodItem.apfRenderId) {
            foodItem.quantityToPurchase -= 1;
            break;
          }
        }
      }
    } else if (cartItem.evpRenderId) {
      // Decrease the quantity of the admission type
      for (let admisType of cartItem.admissionPurchaseQuantities) {
        if (admisType.evAdmisId === evAdmisId) {
          if (admisType.quantityToPurchase > 1) {
            admisType.quantityToPurchase -= 1;
            break;
          }
        }
      }
    }
    setCart(tempCart);
  };

  const increaseQuantityToPurchase = (cartItem, evAdmisId) => {
    let tempCart = { ...currentCart };

    if (cartItem.apfRenderId) {
      for (let foodItem of tempCart.foodItems) {
        if (cartItem.apfRenderId === foodItem.apfRenderId) {
          if (foodItem.availabilityStatus === "unlimited") {
            foodItem.quantityToPurchase += 1;
          } else {
            if (foodItem.quantityToPurchase < foodItem.quantity) {
              foodItem.quantityToPurchase += 1;
            }
          }
          break;
        }
      }
    } else if (cartItem.evpRenderId) {
      // Increase the quantity of the admission type
      for (let admisType of cartItem.admissionPurchaseQuantities) {
        if (admisType.evAdmisId === evAdmisId) {
          if (admisType.evAdmisQuantity <= admisType.quantityToPurchase) return;
          admisType.quantityToPurchase += 1;
          break;
        }
      }
    }
    setCart(tempCart);
  };

  const removeCurrentCart = () => {
    const newMultiCart = multiCart.filter(
      (cart) => cart.stadiumID !== currentCart.stadiumID
    );
    setMultiCartData(newMultiCart);
    let encodedStoreId = encodeParamString(currentCart.stadiumID);

    if (
      !loadingBrandContextData &&
      brandContextData.ghostEventStores &&
      brandContextData.ghostEventStores === true &&
      cart.stadTypes.includes("STT_VENUE")
    ) {
      navigate("/", { state: { navigateToEvents: "true" } });
    } else {
      navigate(
        `/order/${currentCart.stadiumName.replaceAll(
          " ",
          "-"
        )}?glb=${encodedStoreId}`,
        false
      );
    }
  };

  const updateMultiCart = () => {
    if (!cart.foodItems && !cart.eventItems) {
      removeCurrentCart();
    } else {
      const newMultiCart = multiCart.map((mCart) =>
        mCart.stadiumID === cart.stadiumID ? cart : mCart
      );
      setMultiCartData(newMultiCart);
    }
  };

  return (
    <>
      {currentCart.eventItems &&
        currentCart.eventItems.map((cartItem, index) => {
          const isLastItem =
            currentCart.eventItems.length === 1 &&
            currentCart.eventItems[0].admissionPurchaseQuantities.length ===
              1 &&
            !isFoodItem
              ? true
              : false;
          const { admissionPurchaseQuantities } = cartItem;
          const admissionPQMapping = admissionPurchaseQuantities.map(
            (admissionType) => {
              let basePrice = admissionType.evAdmisBasePrice;
              // if (brandContextData._id === "64b80d8a5bdf376def855b1e") {
              //   basePrice = admissionType.evAdmisBasePrice * 1.13;
              // }
              return (
                <Col key={admissionType.evAdmisId}>
                  <Row>
                    <Col span={20} style={{ paddingTop: 10 }}>
                      <Text style={{ fontSize: 16 }}>
                        {admissionType.evAdmisName}
                      </Text>
                    </Col>
                    <Col span={4} style={{ paddingBottom: 20 }}>
                      <Row justify="end">
                        <Text
                          style={{
                            textAlign: "right",
                            fontWeight: "500",
                            fontSize: 16,
                            paddingTop: 10,
                            whiteSpace: "nowrap",
                          }}
                        >
                          $
                          {(
                            Number(basePrice) * admissionType.quantityToPurchase
                          ).toFixed(2)}
                        </Text>
                      </Row>
                      <Row justify="end">
                        {isLastItem ? (
                          <Popconfirm
                            title={
                              "Are you sure you want to remove the only item in this cart? Doing so will remove this cart. Do you wish to proceed?"
                            }
                            placement="left"
                            style={{ margin: 20 }}
                            onConfirm={() =>
                              removeItemFromCart(
                                cartItem,
                                admissionType.evAdmisId,
                                cartItem.evpRenderId
                              )
                            }
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{
                              type: "danger",
                            }}
                          >
                            <Text
                              style={{
                                textDecorationLine: "underline",
                                cursor: "pointer",
                              }}
                              className="removeBtn"
                            >
                              Remove
                            </Text>
                          </Popconfirm>
                        ) : (
                          <Text
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                            className="removeBtn"
                            onClick={() =>
                              removeItemFromCart(
                                cartItem,

                                admissionType.evAdmisId,
                                cartItem.evpRenderId
                              )
                            }
                          >
                            Remove
                          </Text>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="end">
                    <Col>
                      <Row className="quantityRow">
                        <Col>
                          <FaMinus
                            name="minus"
                            style={{
                              fontSize: 16,
                              color:
                                admissionType.quantityToPurchase === 1
                                  ? "lightgray"
                                  : "gray",
                            }}
                            onClick={() =>
                              admissionType.quantityToPurchase !== 1
                                ? decreaseQuantityToPurchase(
                                    cartItem,

                                    admissionType.evAdmisId
                                  )
                                : null
                            }
                          />
                        </Col>
                        <Text className="quantityToPurchase">
                          {admissionType.quantityToPurchase}
                        </Text>
                        <Col>
                          <FaPlus
                            name="plus"
                            style={{ fontSize: 15, color: "gray" }}
                            onClick={() => {
                              increaseQuantityToPurchase(
                                cartItem,

                                admissionType.evAdmisId
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {currentCart.eventItems.length - 1 !== index && <Divider />}
                </Col>
              );
            }
          );

          const eventStartTime = cartItem.evOperatingTime.evStartTime;
          const formattedDateWithTimeZone = mom4tzDateAndTime(
            new Date(eventStartTime).getTime(),
            cartItem.evTimeZone
          );

          return (
            <Col key={cartItem.evpRenderId}>
              <Row>
                <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                  {cartItem.evName}
                </Text>
              </Row>
              <Row style={{ paddingBottom: 10 }}>
                <Text
                  style={{ color: "gray", fontSize: 16 }}
                  className="eventDate"
                >
                  {formattedDateWithTimeZone}
                </Text>
              </Row>
              {admissionPQMapping}
            </Col>
          );
        })}
      {currentCart.foodItems &&
        currentCart.foodItems.map((cartItem, index) => {
          let cartItemOptionGroups = [];
          let addOnCost = 0;
          const isLastItem =
            currentCart.foodItems.length === 1 && !isEventItem ? true : false;
          if (cartItem.optionGroups) {
            cartItemOptionGroups = cartItem.optionGroups.map((optionGroup) => {
              const options = optionGroup.zOptions.map((option) => {
                if (option.isSelected === true) {
                  addOnCost = addOnCost + option.optionObjExtraCost;
                  return (
                    <Row key={option.optionObjId} style={{ paddingLeft: 10 }}>
                      <Text className="optionObjName">
                        {option.optionObjName}
                      </Text>

                      <Text className="optionObjExtraCost">
                        +${Number(option.optionObjExtraCost).toFixed(2)}
                      </Text>
                    </Row>
                  );
                }
              });
              return (
                <Col key={optionGroup.optionGroupId} style={{ paddingTop: 5 }}>
                  <Row>
                    <Text className="optionGroupName">
                      {optionGroup.optionGroupName}
                    </Text>
                  </Row>
                  <Row>{options}</Row>
                </Col>
              );
            });
          }
          return (
            <Col key={cartItem.apfRenderId} className="cartItemCol">
              <Row>
                <Col span={20}>
                  <Row>
                    {cartItem.fXQImageURL ? (
                      <Col
                        className="imgCol"
                        style={{
                          backgroundImage: `url(${cartItem.fXQImageURL})`,
                        }}
                      ></Col>
                    ) : null}
                    <Col
                      style={{
                        paddingLeft: cartItem.fXQImageURL ? 10 : null,
                        width: cartItem.fXQImageURL ? "60%" : "80%",
                      }}
                    >
                      <Text className="cartItemName ">{cartItem.name}</Text>
                      {cartItemOptionGroups}
                    </Col>
                  </Row>
                </Col>
                <Col span={4} style={{ paddingBottom: 20 }}>
                  <Row justify="end">
                    <Text
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: 16,
                        whiteSpace: "normal",
                      }}
                    >
                      $
                      {(
                        (Number(cartItem.price) + addOnCost) *
                        cartItem.quantityToPurchase
                      ).toFixed(2)}
                    </Text>
                  </Row>
                  <Row justify="end">
                    {isLastItem ? (
                      <Popconfirm
                        title={
                          "Are you sure you want to remove the only item in this cart? Doing so will remove this cart. Do you wish to proceed?"
                        }
                        placement="left"
                        style={{ margin: 20 }}
                        onConfirm={() => removeItemFromCart(cartItem)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          type: "danger",
                        }}
                      >
                        <Text
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          className="removeBtn"
                        >
                          Remove
                        </Text>
                      </Popconfirm>
                    ) : (
                      <Text
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        className="removeBtn"
                        onClick={() => removeItemFromCart(cartItem)}
                      >
                        Remove
                      </Text>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Row className="quantityRow">
                    <Col>
                      <FaMinus
                        name="minus"
                        style={{
                          fontSize: 16,
                          color:
                            cartItem.quantityToPurchase === 1
                              ? "lightgray"
                              : "gray",
                        }}
                        onClick={() =>
                          cartItem.quantityToPurchase !== 1
                            ? decreaseQuantityToPurchase(cartItem)
                            : null
                        }
                      />
                    </Col>
                    <Text className="quantityToPurchase">
                      {cartItem.quantityToPurchase}
                    </Text>
                    <Col>
                      <FaPlus
                        name="plus"
                        style={{ fontSize: 15, color: "gray" }}
                        onClick={() => {
                          increaseQuantityToPurchase(cartItem);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {currentCart.foodItems.length - 1 !== index && <Divider />}
            </Col>
          );
        })}
    </>
  );
};

export default EditEventItemCard;
