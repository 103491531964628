import React from "react";
import { Col, Row, Collapse } from "antd";
import EditFoodItemCard from "./EditFoodItemCard";
import EditRetailItemCard from "./EditRetailItemCard";
import EditEventItemCard from "./EditEventItemCard";

const EditItemSection = ({
  cart,
  multiCart,
  setMultiCartData,
  catalogueMode,
}) => {
  const { stadTypes } = cart;
  const { Panel } = Collapse;
  return (
    <Row justify="center" style={{ paddingBottom: 0, paddingTop: 20 }}>
      <Col
        xl={catalogueMode ? 24 : 12}
        lg={catalogueMode ? 24 : 18}
        md={catalogueMode ? 24 : 22}
        xs={catalogueMode ? 24 : 24}
      >
        <Collapse defaultActiveKey={0}>
          <Panel
            header="View/Edit Cart"
            style={{ fontSize: 18, fontWeight: "bold" }}
          >
            {stadTypes[0] === "STT_RESTO" && (
              <EditFoodItemCard
                currentCart={cart}
                multiCart={multiCart}
                setMultiCartData={setMultiCartData}
              />
            )}
            {stadTypes[0] === "STT_RETAIL" && (
              <EditRetailItemCard
                currentCart={cart}
                multiCart={multiCart}
                setMultiCartData={setMultiCartData}
              />
            )}
            {stadTypes[0] === "STT_VENUE" && (
              <EditEventItemCard
                currentCart={cart}
                multiCart={multiCart}
                setMultiCartData={setMultiCartData}
              />
            )}
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default EditItemSection;
