import { Col, Typography, Row, Divider } from "antd";
import React from "react";
import { mom4tzDateAndTime } from "../const/const_functions";
import { FaPlus, FaMinus } from "react-icons/fa";
import FoodCart from "./FoodCart";
import { useBrandContext } from "../contexts/Brand";

const EventCart = ({
  currentCart,
  removeItemFromCart,
  increaseQuantityToPurchase,
  decreaseQuantityToPurchase,
}) => {
  const { Text } = Typography;
  const { brandContextData } = useBrandContext();
  return (
    <>
      {currentCart.eventItems &&
        currentCart.eventItems.map((cartItem) => {
          const { admissionPurchaseQuantities } = cartItem;
          const admissionPQMapping = admissionPurchaseQuantities.map(
            (admissionType) => {
              let basePrice = admissionType.evAdmisBasePrice;
              // if (brandContextData._id === "64b80d8a5bdf376def855b1e") {
              //   basePrice = admissionType.evAdmisBasePrice * 1.13;
              // }
              return (
                <Col key={admissionType.evAdmisId}>
                  <Row>
                    <Col span={20} style={{ paddingTop: 10 }}>
                      <Text style={{ paddingLeft: 10, fontSize: 16 }}>
                        {admissionType.evAdmisName}
                      </Text>
                    </Col>
                    <Col span={4} style={{ paddingBottom: 20 }}>
                      <Row justify="end">
                        <Text
                          style={{
                            textAlign: "right",
                            fontWeight: "500",
                            paddingTop: 10,
                            whiteSpace: "nowrap",
                          }}
                        >
                          $
                          {(
                            Number(basePrice) * admissionType.quantityToPurchase
                          ).toFixed(2)}
                        </Text>
                      </Row>
                      <Row justify="end">
                        <Text
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            removeItemFromCart(
                              cartItem,
                              admissionType.evAdmisId,
                              cartItem.evpRenderId
                            )
                          }
                        >
                          Remove
                        </Text>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="end">
                    <Col>
                      <Row className="quantityRow">
                        <Col>
                          <FaMinus
                            name="minus"
                            style={{
                              fontSize: 16,
                              color:
                                admissionType.quantityToPurchase === 1
                                  ? "lightgray"
                                  : "gray",
                            }}
                            onClick={() =>
                              admissionType.quantityToPurchase !== 1
                                ? decreaseQuantityToPurchase(
                                    cartItem,
                                    admissionType.evAdmisId
                                  )
                                : null
                            }
                          />
                        </Col>
                        <Text className="quantityToPurchase">
                          {admissionType.quantityToPurchase}
                        </Text>
                        <Col>
                          <FaPlus
                            name="plus"
                            style={{ fontSize: 15, color: "gray" }}
                            onClick={() => {
                              increaseQuantityToPurchase(
                                cartItem,
                                admissionType.evAdmisId
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                </Col>
              );
            }
          );

          const stadiumHomeRegionCAPS =
            currentCart.stadiumHomeRegion.toUpperCase();
          const eventStartTime = cartItem.evOperatingTime.evStartTime;
          const formattedDateWithTimeZone = mom4tzDateAndTime(
            new Date(eventStartTime).getTime(),
            cartItem.evTimeZone
          );
          return (
            <Col style={{ padding: 10 }} key={cartItem.evpRenderId}>
              <Row>
                <Text style={{ fontWeight: "bold" }}>{cartItem.evName}</Text>
              </Row>
              <Row>
                <Text style={{ color: "gray" }} className="eventDate">
                  {formattedDateWithTimeZone}
                </Text>
              </Row>
              {admissionPQMapping}
            </Col>
          );
        })}
      {currentCart.foodItems && (
        <FoodCart
          currentCart={currentCart}
          removeItemFromCart={removeItemFromCart}
          increaseQuantityToPurchase={increaseQuantityToPurchase}
          decreaseQuantityToPurchase={decreaseQuantityToPurchase}
        />
      )}
    </>
  );
};

export default EventCart;
