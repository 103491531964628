import { List, Typography, message, Row, Avatar, Image } from "antd";
import React, { useState } from "react";
import RetailItemModal from "./RetailItemModal";
import { useBrandContext } from "../contexts/Brand";
import { formatPrice } from "../const/const_functions";

const { Text } = Typography;

const RetailItemList = ({ retailItems, stadium, shopType }) => {
  const [showRetailItemModal, setShowRetailItemModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const { brandContextData } = useBrandContext();

  const viewSelectedItem = (item) => {
    if (
      brandContextData._id === "655d24864a91a07f4e8fb68b" &&
      item?.simpleProductData?.smpPriceCents === 0
    ) {
      message.warning(
        "This item cannot be purchased online - it has custom pricing.",
        5
      );
      return;
    }

    if (
      item.simpleProductData &&
      item.simpleProductData.smpQuantity !== null &&
      item.simpleProductData.smpQuantity <= 0
    ) {
      message.warning("Sorry! This item is sold out.", 5);
    } else {
      setCurrentItem(item);
      setShowRetailItemModal(true);
    }
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={retailItems}
        style={{ backgroundColor: "white" }}
        renderItem={(item) => {
          let imgUrl = "";
          if (
            typeof item.DefaultImages[0] === "string" &&
            item.DefaultImages[0].trim() !== ""
          ) {
            imgUrl = item.DefaultImages[0];
          }
          return (
            <List.Item
              onClick={() => viewSelectedItem(item)}
              style={{
                paddingTop: 25,
                paddingBottom: 25,
                cursor: "pointer",
              }}
            >
              <List.Item.Meta
                style={{ alignItems: "center" }}
                avatar={
                  imgUrl ? (
                    <Image
                      preview={false}
                      src={imgUrl}
                      style={{
                        height: 50,
                        width: 50,
                        objectFit: "contain",
                      }}
                    />
                  ) : null
                }
                title={
                  <Row>
                    <Text style={{ marginTop: 10, marginRight: 20 }}>
                      {item.Ptitle}
                    </Text>
                  </Row>
                }
                description={
                  <>
                    {brandContextData._id === "655d24864a91a07f4e8fb68b" &&
                    item?.simpleProductData?.smpPriceCents === 0 ? (
                      <Text style={{ fontSize: 12, color: "#ed1620" }}>
                        {item.Description}
                      </Text>
                    ) : (
                      <>
                        {item.simpleProductData &&
                        item.simpleProductData.smpQuantity !== null &&
                        item.simpleProductData.smpQuantity <= 0 ? (
                          <Text style={{ fontSize: 12, color: "#ed1620" }}>
                            Out of Stock
                          </Text>
                        ) : null}
                      </>
                    )}
                  </>
                }
              />
              {item.isSimpleProduct ? (
                <>
                  {brandContextData._id === "655d24864a91a07f4e8fb68b" &&
                  item?.simpleProductData?.smpPriceCents === 0 ? (
                    <Text style={{ marginTop: 5, fontWeight: "bold" }}>
                      Custom
                    </Text>
                  ) : (
                    <Text style={{ marginTop: 5, fontWeight: "bold" }}>
                      {formatPrice(item.simpleProductData.smpPriceCents / 100)}
                    </Text>
                  )}
                </>
              ) : (
                <Text style={{ marginTop: 5, fontWeight: "bold" }}>
                  {formatPrice(item.lowestPriceVeep.VeepPriceCents / 100)}
                </Text>
              )}
            </List.Item>
          );
        }}
      ></List>
      <RetailItemModal
        showRetailItemModal={showRetailItemModal}
        setShowRetailItemModal={setShowRetailItemModal}
        currentItem={currentItem}
        stadium={stadium}
        shopType={shopType}
      />
    </>
  );
};

export default RetailItemList;
