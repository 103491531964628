import React from "react";
import { Col, Typography, Input, Row, theme } from "antd";
import "../styles/SearchBar.css";
const { Search } = Input;
const { Text } = Typography;

const { useToken } = theme;

const SearchBar = ({
  mobile,
  setSearchValue,
  vendorTitleListing,
  stadiumListing,
  placerholderText,
  mobileSpanWidth,
  webSpanWidth,
}) => {
  const { token } = useToken();
  return (
    <Col span={24} style={{ paddingBottom: 20 }}>
      <Row style={{ justifyContent: "center" }}>
        <Col
          xs={{ span: mobileSpanWidth ? mobileSpanWidth : 22 }}
          md={{ span: webSpanWidth ? webSpanWidth : 12 }}
        >
          <Search
            placeholder={
              placerholderText ? placerholderText : "Search for a Vendor..."
            }
            onChange={(e) => setSearchValue(e.target.value)}
            enterButton
            style={{
              "--backgroundColor": token.colorSecondary,
            }}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default SearchBar;
