import { Col, Layout, Row, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useLocation } from "react-router-dom";
import "../styles/OrderHistoryDetails.css";
import OrderStatusDetails from "../components/OrderStatusDetails";
import { BsFillCheckCircleFill } from "react-icons/bs";
import styles from "../styles/InvoicePaymentConfirmation.module.css";

const { Text } = Typography;
const CataloguePaymentConfirmation = () => {
  const location = useLocation();
  const { order, stadium } = location.state;
  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Content
        style={{
          marginTop: 40,
          minHeight: "100vh",
          marginBottom: 100,
        }}
      >
        <Row justify={"center"}>
          <BsFillCheckCircleFill className={styles.checkIcon} />
        </Row>
        <Row justify={"center"}>
          <Text className={styles.greenText}>
            Purchase Order Request Placed!
          </Text>
        </Row>
        <Row justify={"center"}>
          <Text className={styles.desc}>
            Thank you for submitting your request. Our team will follow up and
            confirm your order for processing once it has been reviewed.
          </Text>
        </Row>
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
            <OrderStatusDetails order={order} stadium={stadium} />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CataloguePaymentConfirmation;
