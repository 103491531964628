import { Button, Input, Modal, Typography, notification, theme } from "antd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useState } from "react";
import { screenWidths } from "../const/const_functions";
import { useServer } from "../contexts/Server";
import { useBrandContext } from "../contexts/Brand";
const { useToken } = theme;

const CallServerButton = ({
  storeId,
  tableNumber,
  isCallServerButtonDisabled,
  setIsCallServerButtonDisabled,
  cartFromThisStadium,
}) => {
  const { brandContextData } = useBrandContext();
  const { token } = useToken();
  const [showInputTableNumberModal, setShowInputTableNumberModal] =
    useState(false);
  const [inputTableNumber, setInputTableNumber] = useState(tableNumber);
  const [modalErrorText, setModalErrorText] = useState(null);
  const { width } = useWindowDimensions();
  let isMobile = false;
  if (screenWidths.md > width) {
    isMobile = true;
  }

  const { serverData } = useServer();
  const { Text } = Typography;

  const openServerNotification = (passedCallServerRes) => {
    notification.open({
      message: passedCallServerRes.success
        ? "A server has been called"
        : "Failed to call server",
      description: passedCallServerRes.msg,
    });
  };

  const notifyServer = async (passedTableNumber) => {
    try {
      const callServerRes = await serverData.call("USSR_callStoreAssist", {
        storeId,
        tableNumber: passedTableNumber,
        contextId: brandContextData._id,
      });
      if (callServerRes) {
        setShowInputTableNumberModal(false);
        setIsCallServerButtonDisabled(true);
        openServerNotification(callServerRes);
      }
    } catch (e) {
      console.log("Error calling server", e);
    }
  };

  const handleConfirm = () => {
    if (inputTableNumber) {
      setModalErrorText(null);
      notifyServer(inputTableNumber);
    } else {
      setModalErrorText("Invalid table number");
    }
  };

  const handleCancel = () => {
    setModalErrorText(null);
    setInputTableNumber(null);
    setShowInputTableNumberModal(false);
  };

  let topHeight = 50;
  if (cartFromThisStadium && cartFromThisStadium()) {
    topHeight = 80;
  }

  return (
    <>
      <Button
        disabled={isCallServerButtonDisabled}
        onClick={() => {
          if (tableNumber) {
            notifyServer(tableNumber);
          } else {
            setShowInputTableNumberModal(true);
          }
        }}
        style={{
          position: "fixed",
          bottom: isMobile ? topHeight : 36,
          right: isMobile ? 20 : 64,
          zIndex: 1,
          borderRadius: 26,
          height: 50,
          padding: 12,
          color: "#FFF",
          fontSize: 14,
          fontWeight: "600",
          borderWidth: 0,
          backgroundColor: isCallServerButtonDisabled ? "grey" : "black",
        }}
      >
        Have a question?
      </Button>
      <Modal
        title="Have a question?"
        visible={showInputTableNumberModal}
        onOk={handleConfirm}
        onCancel={handleCancel}
        okButtonProps={{ style: { backgroundColor: "black", color: "white" } }}
      >
        <Text>
          Dining in and have a question? Enter your table number and someone
          will be with you shortly.
        </Text>
        <Input
          style={{ marginTop: 4 }}
          placeholder="Ex. 22"
          value={inputTableNumber}
          onChange={(t) => setInputTableNumber(t.target.value)}
        />
        <Text style={{ color: "red", fontSize: 12 }}>{modalErrorText}</Text>
      </Modal>
    </>
  );
};

export default CallServerButton;
