import { Col, message, Row, Tooltip, Typography } from "antd";
import React from "react";
import { useState, useEffect } from "react";
import {
  calculateFoodItemTotal,
  calculateRetailItemTotal,
  calculateVenueItemTotal,
  formatPrice,
  getDeliveryCost,
  rfx_v2_xnew_transformOrderObject,
  roundTo3DecimalPlaces,
} from "../../const/const_functions";
import { useCarts } from "../../contexts/Carts";
import styles from "../../styles/OrderBreakdown.css";
import { useServer } from "../../contexts/Server";
import { useBrandContext } from "../../contexts/Brand";
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from "../../contexts/Auth";

const OrderBreakdown = ({
  stadiumData,
  stadiumChosenToCheckoutID,
  takeoutMethod,
  tipPercentage,
  tipDollarAmount,
  addressSelected,
  setOrderTotalWithEverything,
  orderTotalWithEverything,
  hstToShow,
  setHstToShow,
  deliveryPrice,
  setDeliveryPrice,
  orderSubtotal,
  setOrderSubtotal,
  tipAmount,
  setTipAmount,
  orderTotalWithTax,
  setOrderTotalWithTax,
  showAddressTooFar,
  setShowAddressTooFar,
  setDigitalFeeObj,
  digitalFeeObj,
  setServiceFeeToShow,
  serviceFeeToShow,
  setComputingBreakdown,
  pointsRedemptionAmount,
  userPoints,
  cartPointsObj,
}) => {
  const { Text } = Typography;
  const { serverData } = useServer();
  const { multiCart } = useCarts();
  const { authData } = useAuth();

  // const [tipAmount, setTipAmount] = useState(0);
  const [showNoAddress, setShowNoAddress] = useState(false);
  const [ccpCartAdjustmentDelivery, setCcpCartAdjustmentDelivery] = useState(0);
  const [ccpCartAdjustmentPickup, setCcpCartAdjustmentPickup] = useState(0);
  const [minOrderAmount, setMinOrderAmount] = useState(false);
  const { brandContextData } = useBrandContext();
  const [taxesAndFees, setTaxesAndFees] = useState([]);
  const [feesToShow, setFeesToShow] = useState(0);

  useEffect(() => {
    if (serverData) {
      computeBreakdown();
    }
  }, [
    takeoutMethod,
    tipPercentage,
    tipDollarAmount,
    addressSelected,
    multiCart,
    pointsRedemptionAmount,
    cartPointsObj,
  ]);

  const computeBreakdown = async () => {
    setComputingBreakdown(true);
    setShowAddressTooFar(false);
    setShowNoAddress(false);
    const currentCart = multiCart.find(
      (cart) => cart.stadiumID === stadiumChosenToCheckoutID
    );
    const { stadTypes, foodItems, merchItems, eventItems } = currentCart;

    if (!stadTypes) {
      return;
    }

    // Compute order subtotal
    let orderSubtotal = 0;

    if (stadTypes.includes("STT_RESTO")) {
      orderSubtotal = calculateFoodItemTotal(foodItems);
    } else if (stadTypes.includes("STT_RETAIL")) {
      orderSubtotal = calculateRetailItemTotal(merchItems);
    } else if (stadTypes.includes("STT_VENUE")) {
      orderSubtotal = calculateVenueItemTotal(foodItems, eventItems);
    }
    setOrderSubtotal(orderSubtotal.toFixed(2));

    // TODO delivery addition
    // // Get delivery price
    // let deliveryPrice = 0;
    // if (takeoutMethod === "delivery" && addressSelected) {
    //   setShowNoAddress(false);
    //   setShowAddressTooFar(false);

    //   if (Object.keys(addressSelected).length === 0) {
    //     setShowNoAddress(true);
    //     return;
    //   }

    //   try {
    //     deliveryPrice = await getDeliveryCost(
    //       serverData,
    //       addressSelected,
    //       stadiumChosenToCheckoutID
    //     );
    //   } catch (e) {
    //     console.log(e);
    //     message.error("An error occured, please try again.", 5);
    //     return;
    //   }

    //   if (deliveryPrice === null) {
    //     setShowAddressTooFar(true);
    //   } else {
    //     setDeliveryPrice(deliveryPrice);
    //   }
    // }

    // // User has no address inputted!
    // if (takeoutMethod === "delivery" && !addressSelected) {
    //   setShowNoAddress(true);
    // }

    // Get promotions from the user cart
    let cartMods = [];
    try {
      cartMods = await serverData.call("USSR_v2_getCartMods", {
        stadiumId: stadiumChosenToCheckoutID,
        cartAmount: orderSubtotal,
      });
    } catch (e) {
      console.log(e);
      message.error("An error occured, please try again.", 5);
      return;
    }

    let ccpCartAdjustmentPickup = 0;
    let ccpCartAdjustmentDelivery = 0;

    if (cartMods) {
      for (const cMod of cartMods) {
        if (cMod.ccpAppliesToDelivery) {
          ccpCartAdjustmentDelivery += cMod.ccpChangeAmount;
        }
        if (cMod.ccpAppliesToPickup) {
          ccpCartAdjustmentPickup += cMod.ccpChangeAmount;
        }
      }
    }
    setCcpCartAdjustmentDelivery(ccpCartAdjustmentDelivery);
    setCcpCartAdjustmentPickup(ccpCartAdjustmentPickup);

    const dataObjToBeTransformed = {
      foodItems: currentCart,
      amount: null,
      // address: takeoutMethod === "delivery" ? addressSelected : null,
      address: null,
      // deliveryComment: takeoutMethod === "delivery" ? deliveryComment : null,
      deliveryComment: null,
      note: null,
      deliveryOption: null,
      stadium: stadiumData,
      paymentInfo: { platform: "web", appVersion: null },
      zone: null,
      clientPickup: null,
      tableNumber: null,
    };

    let transformedDataObj = null;
    transformedDataObj = rfx_v2_xnew_transformOrderObject(
      dataObjToBeTransformed
    );

    // Compute order tax rate
    let totalTax = 0;
    let totalItemFees = 0;
    try {
      let taxAndFeeObj = await serverData.call("USSR_getCartTaxesAndFees", {
        stadiumId: stadiumChosenToCheckoutID,
        subtotal: orderSubtotal,
        foodsOrdered: transformedDataObj.foodsOrdered
          ? transformedDataObj.foodsOrdered
          : [],
        merchItems: transformedDataObj.merchItems
          ? transformedDataObj.merchItems
          : [],
        evCartItems: transformedDataObj.evCartItems
          ? transformedDataObj.evCartItems
          : [],
      });
      let { totalCalculatedFees, totalCalculatedTax } = taxAndFeeObj;
      setTaxesAndFees(taxAndFeeObj);
      totalTax = totalCalculatedTax;
      totalItemFees = totalCalculatedFees;
    } catch (e) {
      console.log(e);
      message.error("An error occured, please try again.", 5);
      return;
    }

    // Compute order digital fee
    let digitalFeeObj = {};
    try {
      digitalFeeObj = await serverData.call("MIKO_getContextServiceFee", {
        contextId: brandContextData._id,
        exemptFee: stadiumData && stadiumData.catalogueMode ? true : false
      });
      setDigitalFeeObj(digitalFeeObj);
    } catch (e) {
      console.log(e);
      message.error("An error occured, please try again.", 5);
      return;
    }

    let dollarEquivalentPointRedemptionAmount = 0;
    if (cartPointsObj) {
      dollarEquivalentPointRedemptionAmount = Number(
        (pointsRedemptionAmount * cartPointsObj.multiplyRedemptionRate).toFixed(
          2
        )
      );
    }

    let orderTotal = 0;
    if (takeoutMethod === "delivery") {
      orderTotal =
        orderSubtotal +
        deliveryPrice +
        ccpCartAdjustmentDelivery +
        totalTax +
        totalItemFees;
    } else if (
      takeoutMethod === "pickup" ||
      takeoutMethod === "inhouse" ||
      takeoutMethod === "admission"
    ) {
      orderTotal =
        orderSubtotal + ccpCartAdjustmentPickup + totalTax + totalItemFees;
    }

    setHstToShow(totalTax.toFixed(2));
    setFeesToShow(totalItemFees);

    // SERVICE FEE CALCULATION
    let serviceFeeToShow = 0;
    if (digitalFeeObj?.applyServiceFee) {
      const feePercentage = digitalFeeObj.serviceFeeInfo.feePercentage;
      const feeCents = digitalFeeObj.serviceFeeInfo.feeCents;

      serviceFeeToShow =
        (orderTotal - dollarEquivalentPointRedemptionAmount) *
        (feePercentage / 100) +
        feeCents / 100;
    }

    // orderTotal = orderTotal + serviceFeeToShow;
    setServiceFeeToShow(serviceFeeToShow.toFixed(2));
    setOrderTotalWithTax(orderTotal.toFixed(2));

    // Compute Tip Breakdown
    let orderTotalWithEverything = 0;
    let tipAmount = 0;
    if (takeoutMethod === "admission") {
      tipAmount = 0;
    } else if (tipPercentage) {
      tipAmount = orderTotal * (tipPercentage / 100);
    } else {
      tipAmount = Number(tipDollarAmount);
    }

    if (
      brandContextData._id === "64ee4ca45e7521f5e54045fc" ||
      brandContextData._id === "64ad6cccf9ce9655bf2f36f9" ||
      brandContextData._id === "655d24864a91a07f4e8fb68b" ||
      stadiumData?._id === "f6fquSWYZXQqtmS2t"
    ) {
      tipAmount = 0;
    }
    setTipAmount(tipAmount.toFixed(2));

    orderTotalWithEverything =
      orderTotal +
      tipAmount -
      dollarEquivalentPointRedemptionAmount +
      serviceFeeToShow;

    // Check if minimum order value hit, if so, set flag and also set order value:
    // If subtotal is between 0 and 1 (0.39) => Set to 1 (Minimum payment)
    // If subtotal is 0 or less (-ve) => Set to 0 (Cash transaction)
    // If subtotal is greater than or equal to 1 => Keep amount as is
    if (
      (orderTotalWithEverything > 0 && orderTotalWithEverything < 1) ||
      orderTotalWithEverything < 0
    ) {
      orderTotalWithEverything = 1;
      setMinOrderAmount(true);
    } else {
      setMinOrderAmount(false);
    }

    setOrderTotalWithEverything(orderTotalWithEverything.toFixed(2));
    setComputingBreakdown(false);
  };

  if (orderTotalWithEverything === "0.00" && !pointsRedemptionAmount) {
    return null;
  }

  const feePercentage = digitalFeeObj?.serviceFeeInfo?.feePercentage;
  const feeDollarAmount = digitalFeeObj?.serviceFeeInfo?.feeCents
    ? digitalFeeObj?.serviceFeeInfo?.feeCents / 100
    : 0;

  let feeString = "";
  if (feePercentage && !feeDollarAmount) {
    feeString = `(${feePercentage}%) `;
  } else if (feePercentage && feeDollarAmount) {
    feeString = `(${feePercentage}% + $${feeDollarAmount}) `;
  }

  const orderDIFF = Math.abs(parseFloat(orderTotalWithEverything) - parseFloat(orderTotalWithTax));


  return (
    <Row justify="center" style={{ paddingTop: 40 }}>
      <Col xl={12} lg={18} md={22} xs={24} style={{ fontSize: 18 }}>
        <Row>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>Summary</Text>
        </Row>
        <Row justify="space-between">
          <Text>Subtotal</Text>
          <Text>{formatPrice(orderSubtotal)}</Text>
        </Row>

        {takeoutMethod === "delivery" && (
          <Row justify="space-between">
            <Text>Delivery Fee</Text>
            {showAddressTooFar && (
              <Text style={{ color: "red" }}>Too Far Away!</Text>
            )}
            {showNoAddress && <Text style={{ color: "red" }}>No Address!</Text>}
            {!showAddressTooFar && !showNoAddress && (
              <Text>{formatPrice(deliveryPrice)}</Text>
            )}
          </Row>
        )}

        {ccpCartAdjustmentDelivery !== 0 && takeoutMethod === "delivery" && (
          <Row justify="space-between">
            <Text>Promotions</Text>
            {showAddressTooFar || showNoAddress ? (
              <Text>...</Text>
            ) : (
              <Text>-{formatPrice(Math.abs(ccpCartAdjustmentDelivery))}</Text>
            )}
          </Row>
        )}

        {ccpCartAdjustmentPickup !== 0 &&
          (takeoutMethod === "pickup" ||
            takeoutMethod === "inhouse" ||
            takeoutMethod === "admission") && (
            <Row justify="space-between">
              <Text>Promotions</Text>
              {showAddressTooFar || showNoAddress ? (
                <Text>...</Text>
              ) : (
                <Text>-{formatPrice(Math.abs(ccpCartAdjustmentPickup))}</Text>
              )}
            </Row>
          )}

        {brandContextData._id !== "64b80d8a5bdf376def855b1e" ? (
          <Row justify="space-between">
            <Row align={"middle"}>
              <Text style={{ marginRight: 4 }}>Tax</Text>
              <Tooltip
                overlayStyle={{ minWidth: "250px" }}
                title={
                  <>
                    {taxesAndFees?.applicableTaxes?.map((taxItem, index) => (
                      <>
                        <Row style={{ justifyContent: "space-between" }}>
                          <Row>
                            <span style={{ marginRight: 4 }}>
                              {taxItem.taxLabel}
                            </span>
                            <span>
                              (
                              {roundTo3DecimalPlaces(
                                taxItem.taxPercentMultiply * 100
                              )}
                              %)
                            </span>
                          </Row>

                          <span>${taxItem.calculatedTaxAmount.toFixed(2)}</span>
                        </Row>
                      </>
                    ))}
                  </>
                }
              >
                <FaInfoCircle
                  name="info"
                  style={{ fontSize: 15, color: "gray" }}
                />
              </Tooltip>
            </Row>

            {showAddressTooFar || showNoAddress ? (
              <Text>...</Text>
            ) : (
              <Text>{formatPrice(taxesAndFees?.totalCalculatedTax)}</Text>
            )}
          </Row>
        ) : null}

        {taxesAndFees?.applicableFees?.length ? (
          <Row justify={"space-between"}>
            <Row align={"middle"}>
              <Text style={{ marginRight: 4 }}>Fees</Text>
              <Tooltip
                overlayStyle={{ minWidth: "275px" }}
                title={
                  <>
                    {taxesAndFees?.applicableFees?.map((feeItem, index) => (
                      <>
                        <Row style={{ justifyContent: "space-between" }}>
                          <Row>
                            <span style={{ marginRight: 4 }}>
                              {feeItem.feeLabel}
                            </span>
                            {feeItem.feePercentMultiply &&
                              feeItem.feeCentsDecimal ? (
                              <span>
                                (
                                {roundTo3DecimalPlaces(
                                  feeItem.feePercentMultiply * 100
                                )}
                                % + ${feeItem?.feeCentsDecimal?.toFixed(2)})
                              </span>
                            ) : null}

                            {feeItem.feePercentMultiply &&
                              !feeItem.feeCentsDecimal ? (
                              <span>
                                (
                                {roundTo3DecimalPlaces(
                                  feeItem.feePercentMultiply * 100
                                )}
                                %)
                              </span>
                            ) : null}

                            {!feeItem.feePercentMultiply &&
                              feeItem.feeCentsDecimal ? (
                              <span>
                                (${feeItem?.feeCentsDecimal?.toFixed(2)})
                              </span>
                            ) : null}
                          </Row>

                          <span>
                            ${feeItem?.calculatedFeeAmount?.toFixed(2)}
                          </span>
                        </Row>
                      </>
                    ))}
                  </>
                }
              >
                <FaInfoCircle
                  name="info"
                  style={{ fontSize: 15, color: "gray" }}
                />
              </Tooltip>
            </Row>
            <Text>{formatPrice(feesToShow)}</Text>
          </Row>
        ) : null}

        {takeoutMethod !== "admission" ? (
          <Row
            justify="space-between"
            className={styles.totalSection}
            style={{ marginTop: 20 }}
          >
            <Text>Order Total</Text>
            {showAddressTooFar || showNoAddress ? (
              <Text>...</Text>
            ) : (
              <Text>{formatPrice(orderTotalWithTax)}</Text>
            )}
          </Row>
        ) : null}

        {takeoutMethod !== "admission" &&
          brandContextData._id !== "64ee4ca45e7521f5e54045fc" &&
          brandContextData._id !== "64ad6cccf9ce9655bf2f36f9" &&
          brandContextData._id !== "655d24864a91a07f4e8fb68b" &&
          stadiumData?._id !== "f6fquSWYZXQqtmS2t" ? (
          <Row justify="space-between">
            <Text>Tip</Text>
            {showAddressTooFar || showNoAddress ? (
              <Text>...</Text>
            ) : (
              <div>
                {tipPercentage && (
                  <Text style={{ paddingRight: 6 }}>({tipPercentage}%)</Text>
                )}
                <Text>{formatPrice(tipAmount)}</Text>
              </div>
            )}
          </Row>
        ) : null}

        {authData && pointsRedemptionAmount && cartPointsObj ? (
          <Row justify="space-between">
            <Text
              color={"grey"}
            >{`${userPoints?.pointsLabel} Redemption`}</Text>
            <Text color={"grey"}>
              {`(${pointsRedemptionAmount.toLocaleString()}) -$${(
                pointsRedemptionAmount * cartPointsObj.multiplyRedemptionRate
              ).toFixed(2)}`}
            </Text>
          </Row>
        ) : null}

        {digitalFeeObj?.applyServiceFee ? (
          <Row justify="space-between">
            <Text>{digitalFeeObj?.serviceFeeInfo?.feeLabel}</Text>
            <div>
              <Text>
                {feeString}
                {formatPrice(serviceFeeToShow)}
              </Text>
            </div>
          </Row>
        ) : null}

        {minOrderAmount ? (
          <Row
            justify="space-between"
            className={styles.totalSection}
            style={{ marginTop: 20 }}
          >
            <Text>Minimum Order amount</Text>

            <Text>$1.00</Text>
          </Row>
        ) : null}
        {
          // Don't show grand total if grand total and order total matches
          orderDIFF > 0 ?
            <Row
              justify="space-between"
              style={{ marginTop: 20, fontWeight: "bold" }}
            >
              <Text color={"grey"}>Grand Total</Text>
              <Text color={"grey"}>${orderTotalWithEverything}</Text>
            </Row>
            :
            null
        }
      </Col>
    </Row>
  );
};

export default OrderBreakdown;
