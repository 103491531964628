import React, { createContext, useContext, useEffect, useState } from "react";
import { connectToServer } from "../const/const_functions";

// Create the ServerContext with the data type specified
// and a empty object
const ServerContext = createContext();

const ServerProvider = ({ children }) => {
  const [serverData, setServerData] = useState();
  const [loadingServerData, setLoadingServerData] = useState(true);
  const [serverWebSocket, setServerWebSocket] = useState(null);

  useEffect(() => {
    // Everytime a page is opened we make a server connection
    fetchAsync();
  }, []);

  useEffect(() => {
    if (serverWebSocket) {
      startServerConnection();
    }
  }, [serverWebSocket]);

  const startServerConnection = async () => {
    const server = await connectToServer(serverWebSocket);
    setServerData(server);
    setLoadingServerData(false);
  };

  const fetchAsync = async () => {
    let buildMode = process.env.REACT_APP_BUILD_MODE;

    if (buildMode === "local") {
      setServerWebSocket(process.env.REACT_APP_LOCAL_SERVER_WEBSOCKET);
    } else if (buildMode === "staging") {
      setServerWebSocket(process.env.REACT_APP_STAGING_SERVER_WEBSOCKET);
    } else if (buildMode === "prod") {
      setServerWebSocket(process.env.REACT_APP_PRODUCTION_SERVER_WEBSOCKET);
    }
  };

  return (
    // This component will be used to encapsulate the whole App,
    // so all components will have access to the Context
    <ServerContext.Provider
      value={{
        fetchAsync,
        serverData,
        loadingServerData,
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};

// A simple hooks to facilitate the access to the ServerContext
// and permit components to subscribe to ServerContext updates
function useServer() {
  const context = useContext(ServerContext);

  if (!context) {
    throw new Error("useServer must be used within an ServerProvider");
  }

  return context;
}

export { ServerContext, ServerProvider, useServer };
