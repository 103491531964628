import React, { useState } from "react";
import { Row, Typography, Image, Button } from "antd";
import styles from "../styles/FeaturedItem.module.css";
import { useBrandContext } from "../contexts/Brand";
import { useChain } from "../contexts/Chain";
import FoodItemModal from "./FoodItemModal";
const { Text } = Typography;

const FeaturedItem = ({ featuredItem }) => {
  const { brandContextData } = useBrandContext();
  const { locationSelected } = useChain();
  const [showFoodItemModal, setShowFoodItemModal] = useState(false);
  return (
    <div className={styles.itemCard}>
      <Row style={{ height: 150 }}>
        {featuredItem.fXQImageURL ? (
          <Image
            className={styles.ItemImage}
            preview={false}
            src={featuredItem.fXQImageURL}
            alt="Featured Image"
          />
        ) : null}
      </Row>
      <Row>
        <Text
          className={styles.itemName}
          style={{ color: brandContextData.brandTheme.colorSecondary }}
        >
          {featuredItem.name}
        </Text>
      </Row>
      <Row>
        <Text className={styles.itemPrice}>${featuredItem.price}</Text>
      </Row>
      <Row justify={"center"}>
        <Button
          className={styles.itemButton}
          style={{
            backgroundColor: brandContextData.brandTheme.colorSecondary,
          }}
          onClick={() => setShowFoodItemModal(true)}
        >
          Add to Order
        </Button>
      </Row>
      <FoodItemModal
        showFoodItemModal={showFoodItemModal}
        setShowFoodItemModal={setShowFoodItemModal}
        currentItem={featuredItem}
        categoryItem={featuredItem.categoryDOC}
        stadium={locationSelected}
      />
    </div>
  );
};

export default FeaturedItem;
