import {
  Button,
  Col,
  ConfigProvider,
  Image,
  Input,
  Layout,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useBrandContext } from "../contexts/Brand";
import styles from "../styles/CreateAccountBase.module.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  containsNumber,
  containsUpperCaseLetter,
  screenWidths,
  userAccountInfoValidation,
  validateEmail,
} from "../const/const_functions";
import LoginTitle from "../components/LoginTitle";
import LoginSubText from "../components/LoginSubText";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import AccountNameSection from "../components/AccountNameSection";
import AccountContactInfoSection from "../components/AccountContactInfoSection";
import AccountSuccessModal from "../components/AccountSuccessModal";

const CreateAccountBase = () => {
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [externalId, setExternalId] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [externalIdError, setExternalIdError] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [allInfoFieldsValid, setAllInfoFieldsValid] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { brandContextData } = useBrandContext();
  const { handleAccountCreation } = useAuth();
  const navigate = useNavigate();
  const { theme } = useContext(ConfigProvider.ConfigContext);

  const accountRequiresExternalId =
    brandContextData?.accountUserInfo?.doesAccountHaveExternalId;
  const externalIdLabel = brandContextData?.accountUserInfo?.externalIdLabel;

  const accountRestrictsDomain =
    brandContextData?.accountUserInfo?.applyDomainRestriction;
  const allowedEmailDomains =
    brandContextData?.accountUserInfo?.allowedEmailDomains;

  useEffect(() => {
    if (brandContextData?.brandType === "Invoice") {
      navigate("/");
    }
  }, [brandContextData]);

  useEffect(() => {
    checkAccountInfoError();
  }, [firstName, lastName, phone, email, externalId]);

  useEffect(() => {
    checkAllInfoFieldsValid();
  }, [
    firstNameError,
    lastNameError,
    phoneError,
    emailError,
    externalIdError,
    externalId,
  ]);

  const checkAccountInfoError = () => {
    if (firstName && !userAccountInfoValidation(firstName, "isName")) {
      setFirstNameError("First name is too short");
    } else {
      setFirstNameError("");
    }

    if (lastName && !userAccountInfoValidation(lastName, "isName")) {
      setLastNameError("Last name is too short");
    } else {
      setLastNameError("");
    }

    if (phone && !userAccountInfoValidation(phone, "isPhone")) {
      setPhoneError("Phone numbers must be 10 digits long");
    } else {
      setPhoneError("");
    }

    if (email && !validateEmail(email)) {
      setEmailError("Please input a valid email");
    } else {
      setEmailError("");
    }

    if (accountRestrictsDomain && allowedEmailDomains && email) {
      const emailDomain = email.split("@");
      if (emailDomain[1]) {
        const trimmedEmailDomain = emailDomain[1].trim().toLowerCase();
        if (!allowedEmailDomains.includes(trimmedEmailDomain)) {
          setEmailError("Email domain not allowed");
        } else {
          setEmailError("");
        }
      }
    }
  };

  const checkAllInfoFieldsValid = () => {
    if (
      firstName &&
      !firstNameError &&
      phone &&
      !phoneError &&
      lastName &&
      !lastNameError &&
      ((accountRequiresExternalId && externalId) ||
        !accountRequiresExternalId) &&
      !externalIdError &&
      email &&
      !emailError &&
      ((phone && !phoneError) || !phone)
    ) {
      setAllInfoFieldsValid(true);
    } else {
      setAllInfoFieldsValid(false);
    }
  };

  useEffect(() => {
    checkPassword();
  }, [password, confirmPassword]);

  const checkPassword = () => {
    if (password && password.length < 6) {
      setPasswordError("Passwords must be at least 6 characters");
      return;
    }
    if (password && !containsNumber(password)) {
      setPasswordError("Passwords must container at least one number");
      return;
    }
    if (password && !containsUpperCaseLetter(password)) {
      setPasswordError("Passwords must contain an upper case letter");
      return;
    }
    if (password && confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError("Password does not match");
    } else {
      setConfirmPasswordError("");
    }
    setPasswordError("");
  };

  const createAccount = async () => {
    try {
      setLoading(true);
      const res = await handleAccountCreation({
        firstName,
        lastName,
        externalId,
        email,
        phone,
        password,
        brandContextId: brandContextData._id,
      });
      setShowSuccessModal(true);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  let pageTitle = `Create Account`;
  let pageSubtitle = `Welcome to ${brandContextData.brandName}.`;
  let enterButtonText = `Sign Up`;

  return (
    <Layout
      style={{
        backgroundColor: "rgb(237, 237, 237)",
        minHeight: "100vh",
      }}
    >
      <AccountSuccessModal
        message={
          "Check your inbox for a verification link to start placing orders!"
        }
        handleCloseModal={handleCloseModal}
        showSuccessModal={showSuccessModal}
      />
      <Spin size="large" spinning={loading} tip={"Creating Account..."}>
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Col
            style={{ backgroundColor: "white", padding: 48, borderRadius: 12 }}
            xs={{ span: 22, offset: 1 }}
            md={{ span: 8, offset: 8 }}
          >
            {brandContextData?.brandLogo ? (
              <Row justify={"center"} className={styles.logoContainer}>
                <Image
                  width={mobile ? "50%" : 200}
                  preview={false}
                  src={brandContextData.brandLogo}
                  style={{
                    maxHeight: 180,
                    minHeight: 75,
                    objectFit: "contain",
                  }}
                />
              </Row>
            ) : null}
            <p
              style={{
                fontWeight: "600",
                color: "black",
                fontSize: 24,
                marginBottom: 0,
              }}
            >
              {pageTitle}
            </p>
            <p
              style={{
                fontWeight: "500",
                color: "grey",
                marginBottom: 40,
                fontSize: 14,
                marginTop: 4,
              }}
            >
              {pageSubtitle}
            </p>
            <AccountNameSection
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
            />
            <AccountContactInfoSection
              phone={phone}
              setPhone={setPhone}
              phoneError={phoneError}
              accountRequiresExternalId={accountRequiresExternalId}
              externalIdLabel={externalIdLabel}
              externalId={externalId}
              setExternalId={setExternalId}
              externalIdError={externalIdError}
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              accountRestrictsDomain={accountRestrictsDomain}
              allowedEmailDomains={allowedEmailDomains}
              emailError={emailError}
            />
            <LoginTitle titleText={`Password`} required={true} />
            <Input
              className={styles.input}
              type="password"
              style={{ "--border-color": theme.token.colorSecondary }}
              value={password}
              defaultValue={null}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            {passwordError ? (
              <LoginSubText
                showError={passwordError}
                errorText={passwordError}
              />
            ) : null}
            <LoginTitle titleText={`Confirm Password`} required={true} />
            <Input
              className={styles.input}
              type="password"
              style={{ "--border-color": theme.token.colorSecondary }}
              value={confirmPassword}
              defaultValue={null}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            {confirmPasswordError ? (
              <LoginSubText
                showError={confirmPasswordError}
                errorText={confirmPasswordError}
              />
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 22,
              }}
            >
              <Button
                className={styles.loginBtn}
                style={{
                  "--border-color":
                    password &&
                    !passwordError &&
                    allInfoFieldsValid &&
                    password === confirmPassword
                      ? theme.token.colorSecondary
                      : "#FFFFFF",
                  width: "100%",
                  backgroundColor:
                    password &&
                    !passwordError &&
                    allInfoFieldsValid &&
                    password === confirmPassword
                      ? theme.token.colorSecondary
                      : "lightgray",
                }}
                disabled={
                  password &&
                  !passwordError &&
                  allInfoFieldsValid &&
                  password === confirmPassword
                    ? false
                    : true
                }
                onClick={() => {
                  if (brandContextData.enableAccounts) {
                    createAccount();
                  } else {
                    navigate("/");
                  }
                }}
              >
                <span style={{ color: "white", fontWeight: "600" }}>
                  {enterButtonText}
                </span>
              </Button>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>
                  Already have an account?{" "}
                  <a
                    onClick={() => navigate("/login")}
                    style={{
                      color: theme.token.colorSecondary,
                      fontWeight: "600",
                    }}
                  >
                    Log in
                  </a>
                </p>
              </div>
            </div>
          </Col>
        </Content>
      </Spin>
    </Layout>
  );
};

export default CreateAccountBase;
