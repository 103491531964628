import React from "react";
import { Modal } from "antd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { screenWidths } from "../const/const_functions";
import SimpleProductCard from "./SimpleProductCard";
import ProductVariantCard from "./ProductVariantCard";

const RetailItemModal = ({
  showRetailItemModal,
  setShowRetailItemModal,
  currentItem,
  stadium,
  shopType,
}) => {
  const { width } = useWindowDimensions();

  return (
    <Modal
      style={{ top: 20, paddingBottom: 100 }}
      open={showRetailItemModal}
      className="modal"
      onCancel={() => setShowRetailItemModal(false)}
      width={width > screenWidths.md ? "60%" : "100%"}
      footer={null}
      destroyOnClose={true}
    >
      {currentItem.simpleProductData ? (
        <SimpleProductCard
          setShowRetailItemModal={setShowRetailItemModal}
          currentItem={currentItem}
          stadium={stadium}
          shopType={shopType}
        />
      ) : (
        <ProductVariantCard
          setShowRetailItemModal={setShowRetailItemModal}
          currentItem={currentItem}
          stadium={stadium}
          shopType={shopType}
        />
      )}
    </Modal>
  );
};

export default RetailItemModal;
