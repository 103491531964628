import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

const ScrollToTop = () => {
  const childDiv = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (childDiv?.current && childDiv?.current.scrollIntoView) {
      childDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.pathname]);

  return <div ref={childDiv}></div>;
};

export default ScrollToTop;
