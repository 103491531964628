import { Button, Col, Modal, Row } from "antd";
import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useBrandContext } from "../contexts/Brand";

const SuccessRegistration = ({ successModalVisible, closeSuccessModal }) => {
  const { brandContextData } = useBrandContext();
  return (
    <Modal
      open={successModalVisible}
      destroyOnClose={true}
      title={"Account Registered."}
      footer={[
        <Button key="back" onClick={closeSuccessModal} type="secondary">
          Close
        </Button>,
      ]}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Col>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <BsFillCheckCircleFill color="#42c369" size={"50"} />
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 12,
            }}
          >
            <p style={{ textAlign: "center" }}>
              Your account with {brandContextData?.brandName} has been
              registered.
            </p>
            <p style={{ textAlign: "center" }}>
              A {brandContextData?.brandName} representative has been notified
              of your registration. If there are any issues, they will be in
              touch. Thank you for your business!
            </p>
          </Row>
        </Col>
      </div>
    </Modal>
  );
};

export default SuccessRegistration;
