import React, { useContext } from "react";
import { Input, ConfigProvider } from "antd";
import LoginSubText from "./LoginSubText";
import LoginTitle from "./LoginTitle";
import styles from "../styles/CreateAccountBase.module.css";

const AccountNameSection = ({
  firstName,
  setFirstName,
  firstNameError,
  lastName,
  setLastName,
  lastNameError,
}) => {
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const spaceBetween = 10;
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginRight: spaceBetween,
        }}
      >
        <LoginTitle titleText={`First Name`} required={true} />
        <Input
          className={styles.input}
          style={{ "--border-color": theme.token.colorSecondary }}
          value={firstName}
          defaultValue={null}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        {firstNameError ? (
          <LoginSubText showError={firstNameError} errorText={firstNameError} />
        ) : null}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginLeft: spaceBetween,
        }}
      >
        <LoginTitle titleText={`Last Name`} required={true} />
        <Input
          className={styles.input}
          style={{ "--border-color": theme.token.colorSecondary }}
          value={lastName}
          defaultValue={null}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        {lastNameError ? (
          <LoginSubText showError={lastNameError} errorText={lastNameError} />
        ) : null}
      </div>
    </div>
  );
};
export default AccountNameSection;
