import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Image,
  Input,
  Divider,
  Checkbox,
  theme,
  Form,
  Button,
  notification,
  Tooltip,
} from "antd";
import styles from "../styles/InvoicePayment.module.css";
import { useBrandContext } from "../contexts/Brand";
import { useServer } from "../contexts/Server";
import {
  screenWidths,
  validateEmail,
  rfx_v2_xnew_transformOrderObject,
  containsLetters,
  decodeParamString,
  encodeParamString,
  formatNumberWithCommas,
} from "../const/const_functions";
import { AiFillStar } from "react-icons/ai";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useNavigate, useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";

const allRules = {
  amountToPay: [
    {
      required: true,
      message: "Please input Payment Amount!",
    },
    {
      validator: (_, value) => {
        if (value && value <= 0) {
          return Promise.reject(new Error("Payment amount is invalid"));
        }
        return Promise.resolve();
      },
    },
  ],
  customerEmail: [
    {
      required: true,
      message: "Please input your Email!",
    },
    { whitespace: true },
    {
      validator: (_, value) => {
        const trimmedValue = value.trim();
        if (trimmedValue && !validateEmail(trimmedValue)) {
          return Promise.reject(new Error("Please input a valid email"));
        }

        return Promise.resolve();
      },
    },
  ],
  customerPhone: [
    { whitespace: false },
    {
      validator: (_, value) => {
        if (Number(value) < 0) {
          return Promise.reject(
            new Error("Please enter a valid 10 digit phone number.")
          );
        }

        if (isNaN(Number(value))) {
          return Promise.reject(
            new Error("Phone Numbers should only consist of digits.")
          );
        }

        if (value && value.length !== 10) {
          return Promise.reject(
            new Error("Phone Numbers should be 10 digits long.")
          );
        }

        return Promise.resolve();
      },
    },
  ],
};

const Bird = {
  accountId: {
    label: "Account Number",
    type: "or",
    sibling: "invoiceId",
    display: true,
    allowSave: true,
  },
  invoiceId: {
    label: "Invoice Number",
    sibling: "accountId",
    type: "or",
    display: false,
    allowSave: false,
  },
  amountToPay: {
    label: "Payment Amount",
    type: "required",
    display: true,
    allowSave: true,
  },
  serviceDescription: {
    label: "Service Description",
    type: "required",
    display: true,
    allowSave: true,
  },
  customerName: {
    label: "Personal/Business Name",
    type: "required",
    display: true,
    allowSave: true,
  },
  customerEmail: {
    label: "Email",
    type: "required",
    display: true,
    allowSave: true,
  },
  customerPhone: {
    label: "Phone",
    type: "optional",
    display: true,
    allowSave: true,
  },
};

const { Text } = Typography;

const InvoicePaymentForm = () => {
  const { brandContextData, loadingBrandContextData } = useBrandContext();
  const { serverData } = useServer();
  const { useToken } = theme;
  const navigate = useNavigate();
  const { token } = useToken();
  const [form] = Form.useForm();

  const { encodedStadiumId } = useParams();
  const stadiumId = decodeParamString(encodedStadiumId);

  const [stadiumData, setStadiumData] = useState(null);

  const [saveInfoForNextTime, setSaveInfoForNextTime] = useState(true);
  const [showSaveCheckbox, setShowSaveCheckbox] = useState(true);
  // No longer used
  // const [accountNumber, setAccountNumber] = useState("");
  // const [email, setEmail] = useState("");
  // const [name, setName] = useState("");
  // const [serviceDetails, setServiceDetails] = useState("");
  // const [phone, setPhone] = useState("");
  // const [invoiceNumber, setInvoiceNumber] = useState("");

  const [orderTotalWithEverything, setOrderTotalWithEverything] = useState(0);
  const [orderSubtotal, setOrderSubtotal] = useState(0);
  const [digitalFeeObj, setDigitalFeeObj] = useState(null);
  const [serviceFeeToShow, setServiceFeeToShow] = useState(0);
  const [serviceFeeAgreement, setServiceFeeAgreement] = useState(false);

  const [submittable, setSubmittable] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const [invoiceDetails, setInvoiceDetails] = useState({});

  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }

  useEffect(() => {
    handleFormChange();
  }, [serviceFeeAgreement]);

  const checkForSavedInfo = () => {
    const userInfo = JSON.parse(
      localStorage.getItem("userInfoForInvoiceCheckout")
    );
    if (userInfo) {
      setInvoiceDetails(userInfo);
    }
  };

  useEffect(() => {
    if (serverData) {
      getStadiumData();
      getServiceFee();
      checkForSavedInfo();
    }
  }, [serverData]);

  useEffect(() => {
    if (digitalFeeObj) {
      computeOrderTotal();
    }
  }, [orderSubtotal]);

  const validateInvoiceDetails = (invoiceDetails) => {
    const errors = {};

    const requiredFields = new Set();

    if (Object.keys(invoiceDetails).length === 0) {
      return { noInvoiceDetails: "There are no invoice details" };
    }

    if (!stadiumData?.invoiceTemplate) {
      return errors;
    }

    Object.keys(stadiumData?.invoiceTemplate).forEach((field) => {
      const { type, sibling, display, label } =
        stadiumData.invoiceTemplate[field];

      if (display) {
        const value = invoiceDetails[field];

        if (type === "required" && (!value || String(value).trim() === "")) {
          errors[field] = `${label} is required.`;
        }

        if (sibling) {
          requiredFields.add(field);

          if (invoiceDetails[field] && invoiceDetails[sibling]) {
            // If both siblings are present, clear the errors for both
            delete errors[field];
            delete errors[sibling];
          }
        }
      }
    });

    // Check if at least one field from each sibling pair is present
    requiredFields.forEach((field) => {
      const sibling = stadiumData.invoiceTemplate[field].sibling;
      if (!invoiceDetails[field] && !invoiceDetails[sibling]) {
        errors[
          field
        ] = `${stadiumData.invoiceTemplate[field].label} or ${stadiumData.invoiceTemplate[sibling].label} is required.`;
      }
    });

    return errors;
  };

  const getStadiumData = async () => {
    setCreatingOrder(true);
    try {
      const stadiumData = await serverData.call(
        "USSR_getOneStadium",
        stadiumId
      );
      if (stadiumData) {
        const _ = require("lodash");
        const newStadiumData = _.cloneDeep(stadiumData);
        newStadiumData.stadTypes = ["STT_INVOICE"];

        if (!newStadiumData.invoiceTemplate) {
          // Mocked templating
          newStadiumData.invoiceTemplate = Bird;
        }

        let isOneAllowSaveTrue = false;
        for (const key in newStadiumData.invoiceTemplate) {
          if (newStadiumData.invoiceTemplate.hasOwnProperty(key)) {
            if (newStadiumData.invoiceTemplate[key].allowSave === true) {
              isOneAllowSaveTrue = true;
              break;
            }
          }
        }

        if (!isOneAllowSaveTrue) {
          setSaveInfoForNextTime(false);
          setShowSaveCheckbox(false);
        }

        setStadiumData(newStadiumData);
      } else {
        navigate("/error");
      }
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
    setCreatingOrder(false);
  };

  const getServiceFee = async () => {
    // Compute order digital fee
    let digitalFeeObj = {};
    try {
      digitalFeeObj = await serverData.call("MIKO_getContextServiceFee", {
        contextId: brandContextData._id,
        exemptFee: stadiumData && stadiumData.catalogueMode ? true : false
      });
      setDigitalFeeObj(digitalFeeObj);
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const computeOrderTotal = () => {
    let serviceFee = 0;
    if (digitalFeeObj?.applyServiceFee) {
      const feePercentage = digitalFeeObj.serviceFeeInfo.feePercentage;
      const feeCents = digitalFeeObj.serviceFeeInfo.feeCents;

      serviceFee = orderSubtotal * (feePercentage / 100) + feeCents / 100;
    }
    setServiceFeeToShow(serviceFee);

    setOrderTotalWithEverything(orderSubtotal + serviceFee);
  };

  const saveThisInfoForNextSession = () => {
    let userInfoForInvoiceCheckout = {};
    for (const [key, value] of Object.entries(stadiumData.invoiceTemplate)) {
      if (value.allowSave && invoiceDetails[key]) {
        userInfoForInvoiceCheckout[key] = invoiceDetails[key];
      }
    }

    localStorage.removeItem("userInfoForInvoiceCheckout");

    if (saveInfoForNextTime) {
      localStorage.setItem(
        "userInfoForInvoiceCheckout",
        JSON.stringify(userInfoForInvoiceCheckout)
      );
    }
  };

  const createTheOrder = async () => {
    saveThisInfoForNextSession();

    let paymentInfo = {
      totalAmount: orderTotalWithEverything,
      hst: 0,
      deliveryFee: null,
      orderAmount: orderSubtotal,
      appVersion: null,
      platform: "web",
    };

    let region = null;
    let storeInstructions = "N/A";

    const dataObjToBeTransformed = {
      foodItems: [],
      amount: orderTotalWithEverything,
      address: null,
      deliveryComment: null,
      note: storeInstructions,
      deliveryOption: "invoice",
      stadium: stadiumData,
      paymentInfo,
      zone: region,
      invoicesToPay: [
        {
          accountId: invoiceDetails["accountId"],
          invoiceId: invoiceDetails["invoiceId"],
          serviceDetails: invoiceDetails["serviceDescription"]
            ? invoiceDetails["serviceDescription"]
            : null,
          amountToPay: orderSubtotal,
        },
      ],
    };

    let transformedDataObj = null;
    transformedDataObj = rfx_v2_xnew_transformOrderObject(
      dataObjToBeTransformed
    );

    transformedDataObj.guestInfo = {
      name: invoiceDetails["customerName"],
      phoneNumber: invoiceDetails["customerPhone"]
        ? invoiceDetails["customerPhone"]
        : null,
      email: invoiceDetails["customerEmail"].trim(),
    };

    transformedDataObj.guestCheckout = true;

    transformedDataObj.contextId = brandContextData._id;
    let orderID = null;
    try {
      const res = await serverData.call(
        "rfx_v2_xmod_addOrder",
        transformedDataObj
      );
      orderID = res;
      const encodedOrderId = encodeParamString(orderID);
      const feeLabel = digitalFeeObj?.serviceFeeInfo?.feeLabel
        ? digitalFeeObj.serviceFeeInfo.feeLabel
        : null;
      const newStoreUrl = `/${encodedOrderId}/payinvoice`;
      navigate(newStoreUrl, {
        state: {
          orderID,
          encodedStadiumId,
          feeLabel,
        },
      });
    } catch (e) {
      notification.error({
        message: "Failed to Create Payable Invoice",
        description: e.reason,
      });
      return;
    }
  };

  const handleFormChange = () => {
    let isFormValid = false;
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);

    const validationErrors = validateInvoiceDetails(invoiceDetails);

    if (Object.keys(validationErrors).length === 0 && !hasErrors) {
      if (!digitalFeeObj?.applyServiceFee) {
        isFormValid = true;
      }

      if (digitalFeeObj?.applyServiceFee && serviceFeeAgreement) {
        isFormValid = true;
      }
    } else {
      // console.log("Validation errors:", validationErrors);
    }

    setSubmittable(isFormValid);
  };

  let submitButtonTitle = "Input Payment Amount";
  if (orderSubtotal && !creatingOrder) {
    submitButtonTitle = `Continue to Payment - $${formatNumberWithCommas(
      orderTotalWithEverything
    )}`;
  } else if (orderSubtotal && creatingOrder) {
    submitButtonTitle = "Creating order...";
  }

  const _renderFormField = (formObj, fieldName) => {
    const { display, label, type, sibling = false } = formObj;

    const showFormField = display;
    const formLabel = label;
    const isRequired = type === "required";

    const txtLabel = <Text className={styles.grayText}>{formLabel}</Text>;
    const requiredStar = isRequired ? (
      <AiFillStar
        fontSize={5}
        color="red"
        style={{ marginTop: 2, marginRight: 3 }}
      />
    ) : null;

    if (!showFormField) {
      return null;
    }

    if (sibling) {
      const siblingObj = stadiumData.invoiceTemplate[sibling];
      return (
        <Form.Item
          initialValue={invoiceDetails[fieldName]}
          className={styles.formItems}
          name={fieldName}
          rules={[
            {
              validator: (_, value) => {
                if (!value && !invoiceDetails[fieldName]) {
                  return Promise.reject(
                    new Error(
                      `Please input ${formLabel} and/or ${siblingObj.label}!`
                    )
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Row style={{ alignItems: "center" }}>
            <Col
              xs={{ span: 9 }}
              sm={{ span: 9 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 11 }}
            >
              <Row>
                <AiFillStar
                  fontSize={5}
                  color="red"
                  style={{ marginTop: 2, marginRight: 3 }}
                />
                <Text className={styles.grayText}>{formLabel}</Text>
              </Row>
              <Input
                maxLength={50}
                value={invoiceDetails[fieldName]}
                onChange={(e) =>
                  takeInput({ inputVal: e.target.value, fieldName })
                }
              />
            </Col>
            <Col
              xs={{ span: 6 }}
              sm={{ span: 6 }}
              md={{ span: 4 }}
              lg={{ span: 4 }}
              xl={{ span: 2 }}
              className={styles.spaceCol}
            >
              <Text className={styles.text}>and/or</Text>
            </Col>
            <Col
              xs={{ span: 9 }}
              sm={{ span: 9 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              xl={{ span: 11 }}
            >
              <Row>
                <AiFillStar
                  fontSize={5}
                  color="red"
                  style={{ marginTop: 2, marginRight: 3 }}
                />
                <Text className={styles.grayText}>{siblingObj.label}</Text>
              </Row>
              <Input
                value={invoiceDetails[sibling]}
                onChange={(e) =>
                  takeInput({ inputVal: e.target.value, fieldName: sibling })
                }
              />
            </Col>
          </Row>
        </Form.Item>
      );
    }

    let rules = [];
    if (allRules[fieldName]) {
      rules = allRules[fieldName];
    } else {
      rules = [
        {
          required: isRequired,
          message: `Please input a ${formLabel}`,
        },
      ];
    }

    return (
      <>
        <Row>
          {requiredStar}
          {txtLabel}
        </Row>
        <Form.Item
          initialValue={
            invoiceDetails[fieldName] ? invoiceDetails[fieldName] : null
          }
          className={styles.formItems}
          name={fieldName}
          rules={rules}
        >
          <Input
            prefix={fieldName === "amountToPay" ? "$" : null}
            type={fieldName === "amountToPay" ? "number" : null}
            value={invoiceDetails[fieldName]}
            placeholder={isRequired ? null : "(Optional)"}
            onChange={(e) => takeInput({ inputVal: e.target.value, fieldName })}
          />
        </Form.Item>
      </>
    );
  };

  const takeInput = ({ inputVal, fieldName }) => {
    if (fieldName === "amountToPay") {
      inputVal = Number(inputVal);
      setOrderSubtotal(inputVal);
    }
    setInvoiceDetails((prevState) => ({ ...prevState, [fieldName]: inputVal }));
  };

  const formatServiceFee = (digitalFeeObj) => {
    const feePercentage = digitalFeeObj?.serviceFeeInfo?.feePercentage;
    const feeCents = digitalFeeObj?.serviceFeeInfo?.feeCents;

    const percentageString = feePercentage ? `${feePercentage}%` : "";
    const feeString = feeCents ? `$${(feeCents / 100).toFixed(2)}` : "";

    if (percentageString && feeString) {
      return `${percentageString} + ${feeString}`;
    } else {
      return percentageString || feeString;
    }
  };

  let imgWidth = 180;

  if (
    stadiumData?._id === "vWbhEfxtsfbK82CKv" ||
    stadiumData?._id === "CakDLtXvDiB7xZRCR"
  ) {
    imgWidth = 280;
  }

  if (!stadiumData) {
    return null;
  }

  const formFields = [];
  for (const [key, value] of Object.entries(stadiumData?.invoiceTemplate)) {
    const newObj = { ...value, key };
    formFields.push(newObj);
  }

  // Sort the formFields array based on the renderPosition property
  formFields.sort((a, b) => (a.renderPosition || 0) - (b.renderPosition || 0));

  return (
    <div>
      {!loadingBrandContextData && brandContextData && stadiumData ? (
        <Col
          xs={{ span: 24 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 18, offset: 3 }}
          xl={{ span: 16, offset: 4 }}
          className={styles.container}
        >
          {stadiumData?.imageUrl ? (
            <Row justify={"center"} className={styles.logoContainer}>
              <Image
                width={mobile ? "50%" : imgWidth}
                preview={false}
                src={stadiumData.imageUrl}
                style={{
                  maxHeight: 180,
                  minHeight: 75,
                  objectFit: "contain",
                }}
                onClick={() => navigate("/")}
              />
            </Row>
          ) : null}
          <Col className={styles.content}>
            {stadiumData?.name ? (
              <Row style={{ marginBottom: 20, justifyContent: "center" }}>
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Text style={{ fontWeight: "bold" }}>PAYEE</Text>
                </Col>
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Text className={styles.grayText} style={{ fontSize: 20 }}>
                    {stadiumData.name}
                  </Text>
                </Col>
              </Row>
            ) : null}

            <Form
              form={form}
              layout="vertical"
              size="large"
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFieldsChange={handleFormChange}
            >
              {formFields.map((field) => (
                <React.Fragment key={field.key}>
                  {_renderFormField(field, field.key)}
                </React.Fragment>
              ))}

              {showSaveCheckbox ? (
                <Checkbox
                  onChange={(e) => setSaveInfoForNextTime(e.target.checked)}
                  style={{
                    marginBottom: 30,
                    "--background-color": "black",
                    "--border-color": "black",
                    color: "gray",
                  }}
                  checked={saveInfoForNextTime}
                >
                  Save info for next time
                </Checkbox>
              ) : null}

              {digitalFeeObj?.applyServiceFee ? (
                <Col
                  xs={{ span: 22, offset: 1 }}
                  sm={{ span: 22, offset: 1 }}
                  md={{ span: 22, offset: 1 }}
                  lg={{ span: 18, offset: 3 }}
                  xl={{ span: 12, offset: 6 }}
                  style={{ marginBottom: 30 }}
                >
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={
                      []
                      // [
                      //   {
                      //     validator: (_, value) => {
                      //       console.log("This is the value");
                      //       console.log(_, value);
                      //       if (value) {
                      //         Promise.resolve();
                      //       } else {
                      //         Promise.reject(
                      //           new Error(
                      //             `Please acknowledge the ${
                      //               digitalFeeObj?.serviceFeeInfo?.feeLabel
                      //                 ? digitalFeeObj.serviceFeeInfo.feeLabel
                      //                 : "Digital Service Fee"
                      //             }`
                      //           )
                      //         );
                      //       }
                      //     },
                      //   },
                      // ]
                    }
                  >
                    <Checkbox
                      onChange={(e) => setServiceFeeAgreement(e.target.checked)}
                      style={{
                        "--background-color": token.colorSecondary,
                        "--border-color": token.colorSecondary,
                        color: "gray",
                      }}
                      checked={serviceFeeAgreement}
                    >
                      I acknowledge the{" "}
                      {digitalFeeObj?.serviceFeeInfo?.feeLabel
                        ? digitalFeeObj.serviceFeeInfo.feeLabel
                        : "Digital Service Fee"}
                      <span style={{ color: "red" }}>*</span>
                    </Checkbox>
                    <Tooltip
                      title={`Your transaction is processed by a secure third-party provider. Your personal and financial information is secure and PCI DSS compliant when processed through our payment portal. A transaction receipt will be sent to the contact information in the payment form.`}
                    >
                      <FaInfoCircle
                        name="info"
                        style={{ fontSize: 15, color: "gray" }}
                      />
                    </Tooltip>
                  </Form.Item>
                </Col>
              ) : null}
              <Col
                xs={{ span: 22, offset: 1 }}
                sm={{ span: 22, offset: 1 }}
                md={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 12, offset: 6 }}
                style={{ marginBottom: 40 }}
              >
                <Row justify={"space-between"}>
                  <Text className={styles.totalText}>Subtotal</Text>
                  <Text
                    className={
                      orderSubtotal ? styles.totalText : styles.redText
                    }
                  >
                    {orderSubtotal
                      ? `$${formatNumberWithCommas(orderSubtotal)}`
                      : "Please input payment amount"}
                  </Text>
                </Row>
                {digitalFeeObj?.applyServiceFee ? (
                  <Row justify={"space-between"}>
                    <Text className={styles.totalText}>
                      {digitalFeeObj?.serviceFeeInfo?.feeLabel
                        ? `${digitalFeeObj.serviceFeeInfo.feeLabel
                        } (${formatServiceFee(digitalFeeObj)})`
                        : `Digital Service Fee (${formatServiceFee(
                          digitalFeeObj
                        )})`}
                    </Text>
                    <Text className={styles.totalText}>
                      {orderSubtotal
                        ? `$${Number(serviceFeeToShow).toFixed(2)}`
                        : "..."}
                    </Text>
                  </Row>
                ) : null}
                <Divider className={styles.totalDivider} />
                <Row justify={"space-between"}>
                  <Text className={styles.totalText}>Total</Text>
                  <Text className={styles.totalText}>
                    {orderSubtotal
                      ? `$${formatNumberWithCommas(orderTotalWithEverything)}`
                      : "..."}
                  </Text>
                </Row>
                <Button
                  disabled={!submittable}
                  htmlType="submit"
                  className={styles.submitButton}
                  onClick={createTheOrder}
                >
                  {submitButtonTitle}
                </Button>
              </Col>
            </Form>
          </Col>
        </Col>
      ) : null}
    </div>
  );
};

export default InvoicePaymentForm;
