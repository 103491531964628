import { Col, Divider, Row } from "antd";
import React, { useEffect } from "react";
import { isSafari } from "react-device-detect";
import {
  CreditCard,
  ApplePay,
  PaymentForm,
  GooglePay,
} from "react-square-web-payments-sdk";
import { useBrandContext } from "../contexts/Brand";

const SquarePayment = ({
  makeThePayment,
  orderTotalWithEverything,
  firstName,
  lastName,
  email,
  phoneNumber,
}) => {
  const { brandContextData } = useBrandContext();

  if (!brandContextData?.squareData) {
    return null;
  }

  return (
    <PaymentForm
      applicationId={brandContextData.squareData.squareId}
      cardTokenizeResponseReceived={(token, verifiedBuyer) => {
        makeThePayment(token, verifiedBuyer);
      }}
      locationId={brandContextData.squareData.squareLocation}
      createVerificationDetails={() => ({
        amount: `${orderTotalWithEverything}`,
        /* collected from the buyer */
        billingContact: {
          familyName: lastName,
          givenName: firstName,
          email: email,
          phone: phoneNumber,
        },
        currencyCode: brandContextData.squareData.currencyCode,
        intent: "CHARGE",
      })}
      createPaymentRequest={() => ({
        countryCode: brandContextData.squareData.countryCode,
        currencyCode: brandContextData.squareData.currencyCode,
        total: {
          amount: orderTotalWithEverything.toString(),
          label: "Total",
        },
      })}
    >
      <Row justify="center" style={{ paddingTop: 0 }}>
        <Col xl={12} lg={18} md={22} xs={22}>
          <CreditCard
            buttonProps={{
              css: {
                backgroundColor: "black",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "black",
                },
              },
            }}
          >
            Pay ${orderTotalWithEverything}
          </CreditCard>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" style={{ paddingTop: 0 }}>
        <Col xl={12} lg={18} md={22} xs={22}>
          <GooglePay />
        </Col>
      </Row>
      <Row justify="center" style={{ paddingTop: 24 }}>
        <Col xl={12} lg={18} md={22} xs={22}>
          <ApplePay />
        </Col>
      </Row>
    </PaymentForm>
  );
};

export default SquarePayment;
