import { Tabs, notification, Collapse, Typography } from "antd";
import React, { useState, useEffect, useRef } from "react";
import RetailItemList from "./RetailItemList";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { decodeParamString, screenWidths } from "../const/const_functions";
import useQuery from "../hooks/useQuery";
import { useServer } from "../contexts/Server";
import SearchBar from "./SearchBar";
import _ from "lodash";

const noMatchingText = `No matching items, try refining your search`;

const { Text } = Typography;
const { Panel } = Collapse;
const RetailItemBrowser = ({ stadium, setloadingCategoryItems }) => {
  const { width } = useWindowDimensions();
  const [stadiumCategoryItems, setStadiumCategoryItems] = useState([]);
  const [filteredStadiumCategoryItems, setFilteredStadiumCategoryItems] =
    useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [searchHasItem, setSearchHasItem] = useState(false);
  const query = useQuery();
  const { serverData } = useServer();
  const searchBarRef = useRef(null);

  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  const filterCategoryItems = (passedSearchValue) => {
    let cloneRetailCategoryItems = _.cloneDeep(stadiumCategoryItems);
    let searchHasItemFlag = false;
    for (let category of cloneRetailCategoryItems) {
      // Only filter if category doesn't match search key
      if (
        !category?.shopType
          .toLowerCase()
          .includes(passedSearchValue?.toLowerCase())
      ) {
        // Filters retail items in category
        const filteredRetailItems = category.retailItems.filter((item) => {
          return item?.Ptitle?.toLowerCase().includes(
            passedSearchValue?.toLowerCase()
          );
        });
        if (filteredRetailItems.length > 0) {
          searchHasItemFlag = true;
        }
        category.retailItems = filteredRetailItems;
      } else {
        searchHasItemFlag = true;
      }
    }
    setSearchHasItem(searchHasItemFlag);
    setFilteredStadiumCategoryItems(cloneRetailCategoryItems);
  };

  useEffect(() => {
    if (stadiumCategoryItems?.length > 0) {
      filterCategoryItems(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    getStadiumData();
  }, [serverData]);

  const getStadiumData = async () => {
    const storeId = query.get("glb");
    const decodedStoreId = decodeParamString(storeId);
    try {
      const stadiumCategoryRetailItems = await serverData.call(
        "USSR_getFrontPageRetailStore",
        { stadiumId: decodedStoreId, requestedLimit: 100 }
      );
      setStadiumCategoryItems(stadiumCategoryRetailItems.CTWithProducts);
      setFilteredStadiumCategoryItems(
        stadiumCategoryRetailItems.CTWithProducts
      );
      setloadingCategoryItems(false);
    } catch (e) {
      console.log("There was an unexpected error");
      console.log(e);
      retailerLoadingError("top");
      return;
    }
  };

  const retailerLoadingError = (placement) => {
    notification.info({
      message: "Store Loading Error",
      description:
        "Sorry, there is an error loading the store. Please contact support.",
      placement,
    });
  };

  const handleTabClick = () => {
    searchBarRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  if (!stadiumCategoryItems.length) {
    return;
  }

  if (mobileSize) {
    return (
      <>
        <div style={{ marginTop: 20 }}>
          <SearchBar
            setSearchValue={setSearchValue}
            placerholderText={"Search Items"}
            mobileSpanWidth={22}
            webSpanWidth={16}
          />
          {/* Only show if no item matches search AND we're searching */}
          {!searchHasItem && searchValue ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text style={{ textAlign: "center" }}>{noMatchingText}</Text>
            </div>
          ) : null}
        </div>
        {/* Render tabs if we're not searching OR we're searching + there exists matching items */}
        {(searchValue && searchHasItem) || !searchValue ? (
          <Collapse
            defaultActiveKey={filteredStadiumCategoryItems.map(
              (categoryItem) => categoryItem.shopType
            )}
          >
            {filteredStadiumCategoryItems &&
              filteredStadiumCategoryItems.map((categoryItem) => {
                if (categoryItem?.retailItems?.length < 1) return;
                return (
                  <Panel
                    header={categoryItem.shopType}
                    key={categoryItem.shopType}
                  >
                    <RetailItemList
                      retailItems={categoryItem.retailItems}
                      stadium={stadium}
                      shopType={categoryItem.shopType}
                    />
                  </Panel>
                );
              })}
          </Collapse>
        ) : null}
      </>
    );
  } else {
    return (
      <>
        <div style={{ marginTop: 20 }} ref={searchBarRef}>
          <SearchBar
            setSearchValue={setSearchValue}
            placerholderText={"Search for an Item"}
            mobileSpanWidth={22}
            webSpanWidth={16}
          />
          {/* Only show if no item matches search AND we're searching */}
          {!searchHasItem && searchValue ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>{noMatchingText}</Text>
            </div>
          ) : null}
        </div>
        {/* Render tabs if we're not searching OR we're searching + there exists matching items */}
        {(searchValue && searchHasItem) || !searchValue ? (
          <Tabs
            onChange={() => handleTabClick()}
            centered
            renderTabBar={(props, TabNavList) => (
              <TabNavList {...props} mobile={false} />
            )}
            tabPosition={width > screenWidths.md ? "left" : "top"}
            style={{
              backgroundColor: "white",
              minHeight: mobileSize ? "35vh" : "70vh",
            }}
            items={filteredStadiumCategoryItems.map((categoryItem, index) => {
              if (categoryItem?.retailItems?.length < 1) return;
              return {
                label: categoryItem.shopType,
                key: index,
                children: (
                  <RetailItemList
                    retailItems={categoryItem.retailItems}
                    stadium={stadium}
                    shopType={categoryItem.shopType}
                  />
                ),
              };
            })}
          />
        ) : null}
      </>
    );
  }
};

export default RetailItemBrowser;
