import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Drawer,
  theme,
  message,
  Divider,
  Select,
  Spin,
  Button,
} from "antd";
import { AiFillLock } from "react-icons/ai";
import DonateAmountBox from "./DonateAmountBox";
import { DonateInfoSection } from "./DonateInfoSection";
import {
  CreditCard,
  ApplePay,
  PaymentForm,
  GooglePay,
} from "react-square-web-payments-sdk";
import { useServer } from "../contexts/Server";
import { FaCheckCircle } from "react-icons/fa";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { parseGoogleDetails } from "../const/const_functions";

const { useToken } = theme;

//Tepmorary variables
const tempAmount = [10, 20, 30, 60];
const tempText1 =
  "We are proudly non-profit, non-corpare and non-compromised.Thousands of people like you help us stand up for a healthy internet for all. We rely on donations top carry out our mission to keep the Web open and free. Will you give today?";
const tempTitle = "Donate Now";
const tempChoosePaymentTitle = "Payment";

const DonateDrawer = (props) => {
  const {
    showDrawer,
    brandContextData,
    setShowDrawer,
    loadingBrandContextData,
    mobile,
  } = props;
  const { Text } = Typography;
  const { token } = useToken();
  const [paymentAmount, setPaymentAmount] = useState(tempAmount[0]);
  const [inputPaymentAmountValue, setInputPaymentAmountValue] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [currentlySelectedCampaign, setCurrentlySelectedCampaign] =
    useState(null);
  const { serverData } = useServer();
  const [loadingPlacingDonation, setLoadingPlacingDonation] = useState(false);
  const [donationPlaced, setDonationPlaced] = useState(false);
  const [donationNum, setDonationNum] = useState(null);
  const [address, setAddress] = useState(null);
  const [parsedAddress, setParsedAddress] = useState(null);
  const [googleAPIKey, setGoogleAPIKey] = useState(null);

  useEffect(() => {
    // Get the current scroll position
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;

    setFormKey((prevKey) => prevKey + 1);

    // Restore the scroll position after re-mounting
    window.scrollTo(0, scrollPosition);
  }, [paymentAmount]);

  const fieldValidation = () => {
    if (!firstName) {
      message.error("Please input your First Name");
      return false;
    }
    if (!lastName) {
      message.error("Please input your Last Name");
      return false;
    }
    if (!email) {
      message.error("Please input your E-mail");
      return false;
    }

    if (!parsedAddress || !parsedAddress.formattedAddress) {
      message.error("Please input your address");
      return false;
    }

    return true;
  };

  let payButtonsDisable = true;
  if (
    !firstNameError &&
    !lastNameError &&
    !emailError &&
    !phoneError &&
    paymentAmount
  ) {
    payButtonsDisable = false;
  }

  const handleCampaignChange = (selectedCampaignId) => {
    const campaignFound = brandContextData.fundraisingCampaigns.find(
      (campaign) => campaign._id === selectedCampaignId
    );
    setCurrentlySelectedCampaign(campaignFound);
  };

  const createDonation = async (token, verifiedBuyer) => {
    if (!fieldValidation()) {
      return;
    }

    setLoadingPlacingDonation(true);

    const { _id: fundraiserId } = currentlySelectedCampaign;
    const {
      formattedAddress,
      shortAddress,
      postalCode,
      city,
      province,
      country,
      geoPointGoogle,
    } = parsedAddress;

    const donationObj = {
      fundraiserId,
      donorInfo: {
        donorFirstName: firstName,
        donorLastName: lastName,
        donorEmail: email,
        donorPhone: phone,
        donorFullAddress: formattedAddress,
        donorStreetAddress: shortAddress,
        donorCity: city,
        donorProvince: province,
        donorPostalCode: postalCode,
        donorCountry: country,
        donorGoogleCoords: geoPointGoogle,
      },
      paymentXYZObj: {
        paymentXType: "xpayment-entered-card",
        nonce: token.token,
      },
      donationAmount: paymentAmount,
      verifiedBuyer,
    };

    try {
      const donationRes = await serverData.call(
        "MIKO_createDonation",
        donationObj
      );

      setDonationNum(donationRes.donationConfirmationCode);
      setDonationPlaced(true);
    } catch (err) {
      console.log("Error Donating", err);
      message.error(
        "There was an error placing your donation. If the issue persists, please contact our support."
      );
    }
    setLoadingPlacingDonation(false);
  };

  useEffect(() => {
    if (
      brandContextData?.fundraisingCampaigns &&
      brandContextData?.fundraisingCampaigns.length
    ) {
      setCurrentlySelectedCampaign(brandContextData.fundraisingCampaigns[0]);
    }
  }, [brandContextData]);

  const donateAgain = () => {
    const { campaignPresetDollarAmounts } = currentlySelectedCampaign;

    // Reset all state variables
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setInputPaymentAmountValue(0);
    setPaymentAmount(campaignPresetDollarAmounts[0]);
    setDonationPlaced(false);
    setDonationNum(null);
    setFormKey(0);
  };

  const formatAddress = async () => {
    try {
      const formattedAddressRes = await geocodeByAddress(address.label);
      const parsedAddressRes = await parseGoogleDetails(formattedAddressRes);
      if (parsedAddressRes) {
        setParsedAddress(parsedAddressRes);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (address) {
      formatAddress();
    }
  }, [address]);

  const retrieveGoogleInfoAPI = async () => {
    try {
      const googleAPIKey = await serverData.call("BLLSTC_getGoogleInfo", {});
      if (googleAPIKey.googleKey) {
        setGoogleAPIKey(googleAPIKey.googleKey);
      }
    } catch (e) {
      console.log("Error getting google API Key", e);
    }
  };

  useEffect(() => {
    if (serverData) {
      retrieveGoogleInfoAPI();
    }
  }, [serverData]);

  return (
    <Drawer
      title={
        currentlySelectedCampaign
          ? currentlySelectedCampaign.campaignTitle
          : null
      }
      placement="right"
      onClose={() => setShowDrawer(!showDrawer)}
      open={showDrawer}
    >
      {donationPlaced ? (
        <Row justify={"center"}>
          <Text style={{ fontSize: 22 }}>Thanks for donating!</Text>
          <Row
            style={{
              width: "100%",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <FaCheckCircle
              style={{
                fontSize: 100,
                color: token.colorPrimary,
              }}
            />
          </Row>

          <Text style={{ fontSize: 18, marginTop: 10 }}>
            We have sent a tax receipt to your email.
          </Text>

          <Text style={{ fontSize: 18, marginTop: 10, textAlign: "center" }}>
            Your donation confirmation code is: {donationNum}
          </Text>

          <Row justify={"center"} style={{ width: "100%", marginTop: 20 }}>
            <Button onClick={() => donateAgain()}>Donate Again</Button>
          </Row>
          <Row justify={"center"} style={{ width: "100%", marginTop: 20 }}>
            <Button type="primary" onClick={() => setShowDrawer(false)}>
              Close
            </Button>
          </Row>
        </Row>
      ) : (
        <>
          {currentlySelectedCampaign ? (
            <Col span={24}>
              {brandContextData?.fundraisingCampaigns?.length > 1 ? (
                <>
                  <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                    Select Donation Campaign
                  </Text>
                  <Select
                    defaultValue={brandContextData.fundraisingCampaigns[0]._id.toString()}
                    style={{
                      width: "100%",
                      marginBottom: 20,
                      marginTop: 10,
                    }}
                    onChange={handleCampaignChange}
                    options={brandContextData.fundraisingCampaigns.map(
                      (obj) => ({
                        value: obj._id.toString(),
                        label: obj.campaignTitle,
                      })
                    )}
                  />
                </>
              ) : null}
              <Spin
                tip="Placing Donation... Please do not navigate away from the page."
                spinning={loadingPlacingDonation}
                size="large"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: 24,
                }}
              >
                {currentlySelectedCampaign.campaignFeaturedImage ? (
                  <Row
                    className="donateImage"
                    style={{
                      backgroundImage: `url(${currentlySelectedCampaign.campaignFeaturedImage})`,
                      height: mobile ? 100 : 150,
                      width: "100%",
                      backgroundSize: "contain",
                      backgroundPositionY: "center",
                      backgroundPositionX: "center",
                      backgroundRepeat: "no-repeat",
                      marginBottom: 10,
                    }}
                  ></Row>
                ) : null}
                {currentlySelectedCampaign.campaignFeaturesText ? (
                  <Text>{currentlySelectedCampaign.campaignFeaturesText}</Text>
                ) : null}
                <Row className="rowTitle">
                  {!loadingBrandContextData && tempTitle ? (
                    <Text className="textTitle">{tempTitle}</Text>
                  ) : null}
                </Row>
                <DonateAmountBox
                  tempAmount={
                    currentlySelectedCampaign.campaignPresetDollarAmounts
                      ? currentlySelectedCampaign.campaignPresetDollarAmounts
                      : [5, 15, 50]
                  }
                  paymentAmount={paymentAmount}
                  setPaymentAmount={setPaymentAmount}
                  setInputPaymentAmountValue={setInputPaymentAmountValue}
                  token={token}
                  inputPaymentAmountValue={inputPaymentAmountValue}
                />
                {googleAPIKey ? (
                  <DonateInfoSection
                    firstName={firstName}
                    setFirstNameError={setFirstNameError}
                    setFirstName={setFirstName}
                    setLastNameError={setLastNameError}
                    setPhone={setPhone}
                    setEmailError={setEmailError}
                    setLastName={setLastName}
                    setEmail={setEmail}
                    setPhoneError={setPhoneError}
                    firstNameError={firstNameError}
                    lastNameError={lastNameError}
                    lastName={lastName}
                    email={email}
                    emailError={emailError}
                    phone={phone}
                    phoneError={phoneError}
                    address={address}
                    setAddress={setAddress}
                    mobile={mobile}
                    googleAPIKey={googleAPIKey}
                  />
                ) : null}

                {!loadingBrandContextData && tempChoosePaymentTitle ? (
                  <Row className="rowTitle">
                    <Text className="textTitle">{tempChoosePaymentTitle}</Text>
                    <AiFillLock className="lockIcon" />
                    <Text className="grayText">SECURE</Text>
                  </Row>
                ) : null}
                {brandContextData.squareData ? (
                  <PaymentForm
                    key={formKey}
                    applicationId={brandContextData.squareData.squareId}
                    cardTokenizeResponseReceived={(token, verifiedBuyer) => {
                      console.info("Token:", token);
                      console.info("Verified Buyer:", verifiedBuyer);
                      createDonation(token, verifiedBuyer);
                    }}
                    locationId={brandContextData.squareData.squareLocation}
                    createVerificationDetails={() => ({
                      amount: `${paymentAmount}`,
                      /* collected from the buyer */
                      billingContact: {
                        familyName: `${lastName}`,
                        givenName: `${firstName}`,
                        email: `${email}`,
                        phone: `${phone}`,
                      },
                      currencyCode: brandContextData.squareData.currencyCode,
                      intent: "CHARGE",
                    })}
                    createPaymentRequest={() => ({
                      countryCode: brandContextData.squareData.countryCode,
                      currencyCode: brandContextData.squareData.currencyCode,
                      total: {
                        amount: `${paymentAmount}`,
                        label: "Total",
                      },
                    })}
                  >
                    <Row justify="center" style={{ paddingTop: 0 }}>
                      <Col span={24}>
                        <CreditCard
                          buttonProps={{
                            css: {
                              backgroundColor: "black",
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "black",
                              },
                            },
                          }}
                        >
                          Pay ${paymentAmount}
                        </CreditCard>
                      </Col>
                    </Row>
                    <Divider />
                    <Row justify="center" style={{ paddingTop: 0 }}>
                      <Col span={24}>
                        <GooglePay />
                      </Col>
                    </Row>
                    <Row justify="center" style={{ paddingTop: 24 }}>
                      <Col span={24}>
                        <ApplePay />
                      </Col>
                    </Row>
                  </PaymentForm>
                ) : null}

                <Row>
                  <Text className="donateProblemsText">
                    Problems donating?{" "}
                    <a
                      target="blank"
                      href={`mailto:support@getittechnologies.io`}
                    >
                      Send us an email.
                    </a>
                  </Text>
                </Row>
              </Spin>
            </Col>
          ) : null}
        </>
      )}
    </Drawer>
  );
};

export default DonateDrawer;
