import { Col, Layout } from "antd";
import React, { useEffect } from "react";
import { useCarts } from "../contexts/Carts";
import SingleCartCard from "../components/SingleCartCard";
import EmptyCart from "../components/EmptyCart";

import "../styles/Cart.css";

const Cart = () => {
  const { multiCart, filteredMultiCart } = useCarts();
  const _ = require("lodash");
  const multiCartCopy = _.cloneDeep(multiCart);

  const CartList = () => (
    <Col
      className="cartsContainer"
      md={{ span: 16, offset: 4 }}
      xs={{ span: 22, offset: 1 }}
    >
      {filteredMultiCart.map((cart) => {
        return (
          <SingleCartCard
            cart={cart}
            key={cart.stadiumID}
            multiCartCopy={multiCartCopy}
          />
        );
      })}
    </Col>
  );

  return (
    <Layout style={{ minHeight: "105vh", backgroundColor: "white" }}>
      {filteredMultiCart?.length ? <CartList /> : <EmptyCart />}
    </Layout>
  );
};

export default Cart;
