import React, { useState } from "react";
import { Col, Row, Typography, theme } from "antd";
import { TbSpeakerphone } from "react-icons/tb";
import { useChain } from "../contexts/Chain";
import SelectLocationModal from "./SelectLocationModal";
const { Text } = Typography;
const { useToken } = theme;

const SelectLocationSection = ({ mobile }) => {
  const { token } = useToken();
  const { locationSelected } = useChain();
  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
  let address = "";
  if (locationSelected) {
    address = locationSelected.address;
  }
  return (
    <Col span={24}>
      <Row
        justify={"center"}
        style={{
          height: mobile ? 45 : 50,
          alignItems: "center",
          backgroundColor: token.colorSecondary,
        }}
      >
        {!locationSelected ? (
          <TbSpeakerphone
            name="announce"
            style={{
              fontSize: mobile ? 32 : 36,
              transform: "rotate(-24deg)",
              color: token.colorTertiary,
              marginRight: mobile ? 13 : 25,
            }}
          />
        ) : null}
        {!locationSelected ? (
          <Text
            style={{
              color: token.colorTertiary,
              fontSize: mobile ? 14 : 16,
              marginRight: 10,
              paddingTop: 3,
              fontWeight: "bold",
            }}
          >
            To place an order
          </Text>
        ) : null}
        <Text
          style={{
            color: token.colorTertiary,
            textDecoration: "underline",
            fontSize: mobile ? 14 : 16,
            paddingTop: !locationSelected ? 3 : 0,
            cursor: "pointer",
            width: mobile && locationSelected ? 340 : "auto",
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            fontWeight: "bold",
          }}
          onClick={() => setShowSelectLocationModal(true)}
        >
          {locationSelected ? address : "Select a Location"}
        </Text>
      </Row>
      <SelectLocationModal
        mobile={mobile}
        showSelectLocationModal={showSelectLocationModal}
        setShowSelectLocationModal={setShowSelectLocationModal}
      />
    </Col>
  );
};

export default SelectLocationSection;
