import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useServer } from "../contexts/Server";
import {
  Modal,
  Typography,
  Row,
  Button,
  message,
  Divider,
  Form,
  Input,
  ConfigProvider,
} from "antd";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { containsLetters } from "../const/const_functions";
import { screenWidths } from "../const/const_functions";
import { useBrandContext } from "../contexts/Brand";

const TrackOrderModal = ({ showTrackOrderModal, setShowTrackOrderModal }) => {
  const navigate = useNavigate();
  const { serverData } = useServer();
  const { Text } = Typography;
  const { width } = useWindowDimensions();
  const { theme } = useContext(ConfigProvider.ConfigContext);

  const { brandContextData } = useBrandContext();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);

  const trackOrder = async () => {
    try {
      const orderToBeTracked = await serverData.call("USSR_getOrderWithoutId", {
        orderNum: orderNumber,
        phone: phoneNumber,
        contextId: brandContextData._id,
      });
      navigate(`/myorders/${orderNumber}`, {
        state: {
          order: orderToBeTracked,
          stadium: orderToBeTracked.stadium,
        },
      });
    } catch (e) {
      message.error(
        "We couldn't find an order with that order number and phone number",
        3
      );
      console.log(e);
    }
    closeModal();
  };

  const closeModal = () => {
    setPhoneNumber(null);
    setOrderNumber(null);
    setShowTrackOrderModal(false);
  };

  return (
    <Modal
      open={showTrackOrderModal}
      onCancel={closeModal}
      width={width > screenWidths.md ? "40%" : "100%"}
      footer={null}
      title="Track Your Order"
      destroyOnClose={true}
    >
      <Divider />
      <Form layout="vertical" onFinish={trackOrder}>
        <Form.Item
          initialValue={phoneNumber}
          style={{ height: 70 }}
          label={<Text style={{ fontWeight: "bold" }}>Phone Number</Text>}
          name="phonenumber"
          rules={[
            {
              required: true,
              message: "Please input your phone number!",
            },
            {
              validator: (_, value) => {
                if (value && containsLetters(value)) {
                  return Promise.reject(
                    new Error("Phone numbers must only contain digits!")
                  );
                }
                if (value && value.trim().length < 10) {
                  return Promise.reject(
                    new Error("Phone numbers must be 10 digits long")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            value={phoneNumber}
            maxLength={10}
            placeholder="Ex.5555555555"
            onChange={(e) => setPhoneNumber(e.target.value.trim())}
          />
        </Form.Item>
        <Form.Item
          initialValue={orderNumber}
          style={{ height: 85, marginBottom: 5 }}
          label={<Text style={{ fontWeight: "bold" }}>Order Number</Text>}
          name="orderNumber"
          rules={[
            {
              required: true,
              message: "Please input your order number!",
            },
            {
              validator: (_, value) => {
                if (value && containsLetters(value)) {
                  return Promise.reject(
                    new Error("Order numbers must only contain digits!")
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            style={{ width: "100%" }}
            value={orderNumber}
            placeholder="Ex.50496"
            onChange={(e) => setOrderNumber(e.target.value.trim())}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row justify={"end"}>
            <Button
              type="text"
              size="large"
              onClick={closeModal}
              style={{ marginRight: 10, color: "gray", fontWeight: 600 }}
            >
              Cancel
            </Button>
            <Button
              type="secondary"
              size="large"
              htmlType="submit"
              style={{
                fontWeight: 600,
                backgroundColor: theme.token.colorPrimary,
                color: theme.token.colorTertiary,
              }}
            >
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TrackOrderModal;
