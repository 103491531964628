import React from "react";
import { Typography, Avatar } from "antd";
import styles from "../styles/EventCategory.module.css";
const { Text } = Typography;

export const EventCategory = ({ tabName, categoryItem }) => {
  let evCatImgUrl = "";
  if (
    typeof categoryItem.evCatImage === "string" &&
    categoryItem.evCatImage.trim() !== ""
  ) {
    evCatImgUrl = categoryItem.evCatImage;
  }
  return (
    <div className={styles.main}>
      {evCatImgUrl ? (
        <Avatar
          src={evCatImgUrl}
          className={styles.evCatImg}
        />
      ) : null}
      <div className={styles.evCatBox}>
        <Text
          className={styles.tabName}
          style={{ padding: evCatImgUrl ? 0 : 10 }}
        >
          {tabName}
        </Text>
        <Text
          className={styles.evCatDesc}
          style={{
            paddingLeft: evCatImgUrl ? 0 : 10,
          }}
        >
          {categoryItem.evCatDesc}
        </Text>
      </div>
    </div>
  );
};
