import React, { useState, useEffect } from "react";
import { Col, Typography, Row, Divider, Popconfirm } from "antd";
import { FaPlus, FaMinus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { encodeParamString, formatPrice } from "../../const/const_functions";

const EditRetailItemCard = ({ currentCart, multiCart, setMultiCartData }) => {
  const [cart, setCart] = useState(currentCart);
  const { Text } = Typography;
  const navigate = useNavigate();

  useEffect(() => {
    updateMultiCart();
  }, [cart]);

  const removeItemFromCart = (cartItem) => {
    const foundItem = cart.merchItems.some(
      (item) => item.apfRenderId === cartItem.apfRenderId
    );
    if (foundItem) {
      if (currentCart.merchItems.length !== 1) {
        //Remove current item
        const newRetailItems = currentCart.merchItems.filter(
          (merchItem) => merchItem.apfRenderId !== cartItem.apfRenderId
        );
        const newCart = { ...cart, merchItems: newRetailItems };
        setCart(newCart);
      } else {
        //If last item, remove current cart
        const newMultiCart = multiCart.filter(
          (mCart) => mCart.stadiumID !== cart.stadiumID
        );
        setMultiCartData(newMultiCart);
        let encodedStoreId = encodeParamString(currentCart.stadiumID);
        navigate(
          `/order/${currentCart.stadiumName.replaceAll(
            " ",
            "-"
          )}?glb=${encodedStoreId}`,
          false
        );
      }
    }
  };

  const decreaseQuantityToPurchase = (cartItem) => {
    let tempCart = { ...currentCart };
    // Find the item to decrease in current cart
    if (cartItem.quantityToPurchase > 1) {
      for (let merchItem of tempCart.merchItems) {
        if (cartItem.apfRenderId === merchItem.apfRenderId) {
          merchItem.quantityToPurchase -= 1;
          break;
        }
      }
    }
    setCart(tempCart);
  };

  const increaseQuantityToPurchase = (cartItem) => {
    let tempCart = { ...currentCart };
    // Find the item to increase in current cart
    for (let merchItem of tempCart.merchItems) {
      if (cartItem.apfRenderId === merchItem.apfRenderId) {
        const quantityAvailable = merchItem.quantityAvailable
          ? merchItem.quantityAvailable
          : merchItem.veepQuantity;
        if (quantityAvailable > merchItem.quantityToPurchase) {
          merchItem.quantityToPurchase += 1;
        }
        break;
      }
    }
    setCart(tempCart);
  };

  const updateMultiCart = () => {
    const newMultiCart = multiCart.map((mCart) =>
      mCart.stadiumID === cart.stadiumID ? cart : mCart
    );
    setMultiCartData(newMultiCart);
  };
  return (
    <>
      {currentCart.merchItems &&
        currentCart.merchItems.map((cartItem, index) => {
          const isLastItem = currentCart.merchItems.length === 1 ? true : false;

          const selectedVariants = [];
          if (!cartItem.isSimpleProduct) {
            if (cartItem.variant1.isSelected) {
              selectedVariants.push(
                <Row key={1} style={{ paddingTop: 10 }}>
                  <Col>
                    <Row>
                      <Text style={{ fontWeight: "600", fontSize: 14 }}>
                        {cartItem.variant1.variantName}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                        {cartItem.variant1.selectedVariant}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              );
            }
            if (cartItem.variant2.isSelected) {
              selectedVariants.push(
                <Row key={2} style={{ paddingTop: 5 }}>
                  <Col>
                    <Row>
                      <Text style={{ fontWeight: "600", fontSize: 14 }}>
                        {cartItem.variant2.variantName}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                        {cartItem.variant2.selectedVariant}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              );
            }
            if (cartItem.variant3.isSelected) {
              selectedVariants.push(
                <Row key={3} style={{ paddingTop: 5 }}>
                  <Col>
                    <Row>
                      <Text style={{ fontWeight: "600", fontSize: 14 }}>
                        {cartItem.variant3.variantName}
                      </Text>
                    </Row>
                    <Row>
                      <Text style={{ fontStyle: "italic", fontSize: 12 }}>
                        {cartItem.variant3.selectedVariant}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              );
            }
          }

          return (
            <Col key={cartItem.apfRenderId} className="cartItemCol">
              <Row>
                <Col span={20}>
                  <Row>
                    {cartItem.image ? (
                      <Col
                        className="imgCol"
                        style={{
                          backgroundImage: `url(${cartItem.image})`,
                        }}
                      ></Col>
                    ) : null}
                    <Col
                      style={{
                        paddingLeft: cartItem.image ? 10 : null,
                        width: cartItem.image ? "60%" : "80%",
                      }}
                    >
                      <Row>
                        <Text className="cartItemName">{cartItem.pTitle}</Text>
                      </Row>
                      {selectedVariants}
                    </Col>
                  </Row>
                </Col>
                <Col span={4}>
                  <Row justify="end">
                    <Text
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: 16,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {formatPrice(
                        Number(cartItem.price) * cartItem.quantityToPurchase
                      )}
                    </Text>
                  </Row>
                  <Row justify="end">
                    {isLastItem ? (
                      <Popconfirm
                        title={
                          "Are you sure you want to remove the only item in this cart? Doing so will remove this cart. Do you wish to proceed?"
                        }
                        placement="left"
                        style={{ margin: 20 }}
                        onConfirm={() => removeItemFromCart(cartItem)}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{
                          type: "danger",
                        }}
                      >
                        <Text
                          style={{
                            textDecorationLine: "underline",
                            cursor: "pointer",
                          }}
                          className="removeBtn"
                        >
                          Remove
                        </Text>
                      </Popconfirm>
                    ) : (
                      <Text
                        style={{
                          textDecorationLine: "underline",
                          cursor: "pointer",
                        }}
                        className="removeBtn"
                        onClick={() => removeItemFromCart(cartItem)}
                      >
                        Remove
                      </Text>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row justify="end">
                <Col>
                  <Row className="quantityRow">
                    <Col>
                      <FaMinus
                        name="minus"
                        style={{
                          fontSize: 16,
                          color:
                            cartItem.quantityToPurchase === 1
                              ? "lightgray"
                              : "gray",
                        }}
                        onClick={() =>
                          cartItem.quantityToPurchase !== 1
                            ? decreaseQuantityToPurchase(cartItem)
                            : null
                        }
                      />
                    </Col>
                    <Text className="quantityToPurchase">
                      {cartItem.quantityToPurchase}
                    </Text>
                    <Col>
                      <FaPlus
                        name="plus"
                        style={{ fontSize: 15, color: "gray" }}
                        onClick={() => {
                          increaseQuantityToPurchase(cartItem);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {cart.merchItems.length - 1 !== index && <Divider />}
            </Col>
          );
        })}
    </>
  );
};

export default EditRetailItemCard;
