import React, { useEffect, useState } from "react";
import { Typography, Row, Col, theme } from "antd";
import EventItem from "./EventItem";
import { useServer } from "../contexts/Server";
import { useBrandContext } from "../contexts/Brand";
import { generateShades } from "../const/const_functions";
import { useNavigate } from "react-router-dom";
const { Text } = Typography;

const EventsSection = ({ mobile, eventsRefProp, title }) => {
  const { serverData } = useServer();
  const { brandContextData } = useBrandContext();
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const navigate = useNavigate();

  const { useToken } = theme;
  const { token } = useToken();
  const backgroundColor = generateShades(token.colorPrimary)[10];

  // Default show 3 on homescreen
  let numberOfEventsOnHomePageToShow = 3;
  // If set in context, take that amount to show on home screen
  if (brandContextData?.numEventsHomePageToShow) {
    numberOfEventsOnHomePageToShow = brandContextData.numEventsHomePageToShow;
  }
  // But if there isn't enough events to show, lower the amount
  if (upcomingEvents?.length < numberOfEventsOnHomePageToShow) {
    numberOfEventsOnHomePageToShow = upcomingEvents.length;
  }

  useEffect(() => {
    if (serverData && brandContextData) {
      getUpcomingEvents();
    }
  }, [serverData, brandContextData]);

  const getUpcomingEvents = async () => {
    try {
      const upcomingEventsList = await serverData.call(
        "USSR_getContextEventsList",
        {
          contextId: brandContextData._id,
        }
      );
      setUpcomingEvents(upcomingEventsList.eventsList);
    } catch (e) {
      console.log("Error getting events", e);
    }
  };

  if (!upcomingEvents.length) {
    return null;
  }

  const renderRows = (passedEvents) => {
    const eventList = passedEvents;
    const eventListSliced = eventList.slice(0, numberOfEventsOnHomePageToShow);
    const groupSize = 3;
    let eventItems = eventListSliced.map((event, index) => {
      // Turn every event item into it's own column
      return (
        <Col key={index} style={{ marginTop: 20, display: "flex" }}>
          <EventItem event={event} mobile={mobile} />
        </Col>
      );
    });
    let rows = [];
    // Group each column into 3
    for (let i = 0; i < eventItems.length; i++) {
      if (i % groupSize === 0) {
        rows.push([]);
      }
      rows[rows.length - 1].push(eventItems[i]);
    }
    // Wrap each group of 3 columns into a row
    let wrappedRows = rows.map((row, index) => {
      return (
        <Row key={index} style={{ display: "flex", justifyContent: "center" }}>
          {row}
        </Row>
      );
    });
    return <>{wrappedRows}</>;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ededed",
        paddingTop: mobile ? 40 : 75,
      }}
      ref={eventsRefProp}
    >
      <Text
        style={{
          fontSize: mobile ? 22 : 28,
          fontWeight: "600",
          textAlign: "center",
        }}
      >
        {title}
      </Text>
      <Row
        style={{
          paddingTop: mobile ? 40 : 50,
          justifyContent: "center",
          width: "100%",
        }}
      >
        {mobile ? (
          <Col xs={{ span: 22 }} xl={{ span: 22 }}>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              {upcomingEvents.map((event, index) => {
                if (index >= numberOfEventsOnHomePageToShow) {
                  return null;
                }
                return <EventItem event={event} mobile={mobile} key={index} />;
              })}
            </Row>
          </Col>
        ) : (
          <Col style={{ paddingBottom: mobile ? 40 : 50 }}>
            {renderRows(upcomingEvents)}
          </Col>
        )}
      </Row>
      {upcomingEvents?.length > numberOfEventsOnHomePageToShow ? (
        <Text
          style={{
            marginTop: 20,
            fontWeight: "500",
            fontSize: 16,
            cursor: "pointer",
            paddingBottom: mobile ? 40 : 75,
          }}
          onClick={() =>
            navigate(`/event/list`, {
              state: {
                eventsList: upcomingEvents,
                mobile: mobile,
              },
            })
          }
        >
          See All Events
        </Text>
      ) : null}
    </div>
  );
};

export default EventsSection;
