import { Tabs, notification, Collapse, theme, Row, Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import FoodItemList from "./FoodItemList";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { decodeParamString, screenWidths } from "../const/const_functions";
import useQuery from "../hooks/useQuery";
import { useServer } from "../contexts/Server";
import "../styles/App.css";
import { useBrandContext } from "../contexts/Brand";
import FoodItem from "./FoodItem";
import { ReactComponent as ArrowUpIcon } from "../assets/up-arrow.svg";
import { useCarts } from "../contexts/Carts";
import { IoMdCart } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const { useToken } = theme;
const { Panel } = Collapse;
const FoodItemBrowser = ({ stadium, setloadingCategoryItems }) => {
  const { TabPane } = Tabs;
  const { width } = useWindowDimensions();
  const [sortStadiumCategoryItems, setSortStadiumCategoryItems] = useState([]);
  const query = useQuery();
  const { serverData } = useServer();
  const { token } = useToken();
  const navigate = useNavigate();
  const ref = useRef({});
  const topRef = useRef(null);
  const [categoryYPos, setCategoryYPos] = useState(null);
  const [scrolledPastCategory, setScrolledPastCategory] = useState(false);

  const { brandContextData } = useBrandContext();
  const { multiCart } = useCarts();
  const storeId = query.get("glb");
  const decodedStoreId = decodeParamString(storeId);
  const stadiumCart = multiCart.find(
    (cart) => cart.stadiumID === decodedStoreId
  );
  // Sets food items as empty array if stadium cart doesn't exist
  const stadiumCartFoodItems = stadiumCart?.foodItems ?? [];

  let mobileSize = false;
  if (screenWidths.md > width) {
    mobileSize = true;
  }

  useEffect(() => {
    getStadiumData();
  }, [serverData]);

  const getStadiumData = async () => {
    try {
      const stadiumCategoryFoodItems = await serverData.call(
        "fetchCategoryWiseFoodItems",
        decodedStoreId
      );

      //sort stadiumCategoryFoodItems by catPosition
      stadiumCategoryFoodItems.sort(
        (a, b) => parseFloat(a.catPosition) - parseFloat(b.catPosition)
      );

      //sort stadiumCategoryFoodItems by foodPosition
      const sortStadiumCategoryItems = stadiumCategoryFoodItems.map(
        (category, index) => {
          const sortedFoodItems = category.foodItems.sort((a, b) => {
            if (a.foodPosition && b.foodPosition) {
              return a.foodPosition - b.foodPosition;
            } else if (a.foodPosition) {
              return -1; // Put items with a.foodPosition first
            } else if (b.foodPosition) {
              return 1; // Put items with b.foodPosition first
            } else {
              return 0; // Keep the order unchanged if neither has foodPosition
            }
          });
          return {
            ...category,
            foodItems: sortedFoodItems,
            renderCatPosition: index,
          };
        }
      );
      setSortStadiumCategoryItems(sortStadiumCategoryItems);
      setloadingCategoryItems(false);
    } catch (e) {
      console.log("There was an unexpected error");
      console.log(e);
      restaurantLoadingError("top");
      return;
    }
  };

  const restaurantLoadingError = (placement) => {
    notification.info({
      message: "Store Loading Error",
      description:
        "Sorry, there is an error loading the store. Please contact support.",
      placement,
    });
  };

  const handleClick = (passedCatposition) => {
    if (categoryYPos === null) {
      handleInitScroll();
    }
    const yPos =
      // Current y pos on window
      window.scrollY +
      // Y pos of category relative to window
      ref?.current[passedCatposition]?.current?.getBoundingClientRect().top -
      // Height of category
      ref?.current[passedCatposition]?.current?.getBoundingClientRect().height;
    window.scrollTo({ left: 0, top: yPos, behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    // Compare Y scroll amount to category position
    if (window.scrollY > categoryYPos) {
      setScrolledPastCategory(true);
    } else if (window.scrollY < categoryYPos) {
      setScrolledPastCategory(false);
    }
  };

  useEffect(() => {
    if (categoryYPos !== null) {
      window.addEventListener("scroll", () => handleScroll());
      return () => window.removeEventListener("scroll", null);
    }
  }, [categoryYPos]);

  const handleInitScroll = () => {
    // This grabs the category component's Y position relative to the top of the screen
    const initCatYPos = topRef?.current?.getBoundingClientRect().top;
    setCategoryYPos(initCatYPos);
  };

  useEffect(() => {
    if (!mobileSize) {
      window.addEventListener("scroll", () => handleInitScroll(), {
        once: true,
      });
      return () => window.removeEventListener("scroll", null);
    }
  }, []);

  if (!sortStadiumCategoryItems.length) {
    return;
  }

  let alternateExperience = "classic";
  if (stadium.browsingExperience === "visual") {
    alternateExperience = "visual";
  }

  if (mobileSize) {
    return (
      <Collapse defaultActiveKey={["0"]}>
        {sortStadiumCategoryItems &&
          sortStadiumCategoryItems.map((categoryItem) => {
            return (
              <Panel
                header={categoryItem.name}
                key={categoryItem.renderCatPosition}
              >
                {categoryItem.description ? (
                  <p
                    style={{
                      fontStyle: "italic",
                      paddingLeft: 6,
                      paddingRight: 6,
                      color: "gray",
                    }}
                  >
                    {categoryItem.description}
                  </p>
                ) : null}
                <FoodItemList categoryItem={categoryItem} stadium={stadium} />
              </Panel>
            );
          })}
      </Collapse>
    );
  } else {
    if (alternateExperience === "visual") {
      return (
        <>
          <div
            ref={topRef}
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              boxShadow: "1px 2px 5px lightgray",
              backgroundColor: "#FFFFFF",
              position: "sticky",
              top: 0,
              zIndex: 10,
              marginTop: 20,
              borderRadius: 22,
            }}
          >
            {sortStadiumCategoryItems &&
              sortStadiumCategoryItems.map((categoryItem) => {
                return (
                  <Button
                    key={categoryItem.name}
                    onClick={() => handleClick(categoryItem.catPosition)}
                    ghost
                    style={{
                      backgroundColor: "black",
                      fontSize: 18,
                      margin: 12,
                      paddingLeft: 18,
                      paddingRight: 18,
                      paddingTop: 22,
                      paddingBottom: 22,
                      borderRadius: 22,
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                  >
                    {categoryItem.name}
                  </Button>
                );
              })}
          </div>
          {sortStadiumCategoryItems &&
            sortStadiumCategoryItems.map((categoryItem) => {
              const sortedFoodItems = categoryItem.foodItems.sort(
                (a, b) => a.foodPosition - b.foodPosition
              );
              return (
                <div key={categoryItem.name}>
                  <p
                    ref={
                      (ref.current[categoryItem.catPosition] ??= {
                        current: null,
                      })
                    }
                    style={{ fontSize: 22, paddingTop: 50, marginBottom: 0 }}
                  >
                    {categoryItem.name}
                  </p>
                  {categoryItem.description ? (
                    <p style={{ fontSize: 18, color: "gray" }}>
                      {categoryItem.description}
                    </p>
                  ) : null}
                  <div style={{ height: 22 }}></div>
                  <Row gutter={[16, 16]}>
                    {sortedFoodItems.map((item) => {
                      return (
                        <FoodItem
                          categoryItem={categoryItem}
                          item={item}
                          stadium={stadium}
                          stadiumCartFoodItems={stadiumCartFoodItems}
                        />
                      );
                    })}
                  </Row>
                </div>
              );
            })}
          {scrolledPastCategory ? (
            <div
              onClick={() => scrollToTop()}
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom:
                  stadium?.nowDoInHouse && stadium?.enableHaveAQuestion
                    ? 155
                    : 96,
                right: 64,
                zIndex: 1,
                display: "flex",
                borderRadius: 26,
                borderWidth: 0,
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <ArrowUpIcon style={{ fill: "black", height: 50, width: 50 }} />
            </div>
          ) : null}
          <div
            onClick={() => navigate("/cart")}
            style={{
              cursor: "pointer",
              position: "fixed",
              bottom:
                stadium?.nowDoInHouse && stadium?.enableHaveAQuestion ? 95 : 36,
              right: 64,
              zIndex: 1,
              display: "flex",
              borderRadius: 30,
              borderWidth: 4,
              borderColor: "black",
              borderStyle: "solid",
              padding: 6,
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
            }}
          >
            <IoMdCart
              style={{
                color: "black",
                fontSize: 32,
              }}
            />
          </div>
        </>
      );
    } else {
      return (
        <Tabs
          centered
          renderTabBar={(props, TabNavList) => (
            <TabNavList {...props} mobile={false} />
          )}
          tabPosition={"left"}
          style={{
            backgroundColor: "white",
            minHeight: mobileSize ? "35vh" : "70vh",

            "--color": brandContextData.brandTheme.colorSecondary,
            "--backgroundColor": brandContextData.brandTheme.colorPrimary,
          }}
          tabBarStyle={{ marginTop: 10 }}
        >
          {sortStadiumCategoryItems &&
            sortStadiumCategoryItems.map((categoryItem) => {
              return (
                <TabPane
                  tab={categoryItem.name}
                  key={categoryItem.renderCatPosition}
                >
                  <FoodItemList categoryItem={categoryItem} stadium={stadium} />
                </TabPane>
              );
            })}
        </Tabs>
      );
    }
  }
};

export default FoodItemBrowser;
