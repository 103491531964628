import React from "react";
import { Col, Row, Typography, Input } from "antd";

const AllergySection = ({
  hasAllergies,
  setHasAllergies,
  setAllergiesNote,
  allergiesNote,
}) => {
  const { Text } = Typography;
  const { TextArea } = Input;
  return (
    <Row justify="center" style={{ paddingTop: 20 }}>
      <Col xl={12} lg={18} md={22} xs={24}>
        <Row justify="end">
          <Text
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setHasAllergies(!hasAllergies)}
          >
            Allergies?
          </Text>
        </Row>
        {hasAllergies ? (
          <Row>
            <TextArea
              rows={2}
              placeholder="Please specify any allergies"
              maxLength={100}
              value={allergiesNote}
              onChange={(e) => setAllergiesNote(e.target.value)}
            />
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default AllergySection;
