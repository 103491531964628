import { Button, Drawer, Input, Row, Typography } from "antd";
import React from "react";

const OrderGeneration = ({
  showDrawer,
  onClose,
  open,
  numOrdersToGen,
  setNumOrdersToGen,
  generateOrders,
  ordersGenerated,
  startOrderGeneration,
  queuingText,
  setQueuingText,
}) => {
  if (
    !process.env.REACT_APP_TESTING_ORDERS ||
    process.env.REACT_APP_TESTING_ORDERS !== "true"
  ) {
    return null;
  }

  const { Text } = Typography;
  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        Open Order Queuing System
      </Button>
      <Drawer
        title="TEST ORDER PLACEMENT"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Row>
          <Text>Orders to Generate (30 Max)</Text>
          <Input
            type="number"
            placeholder="Number of Orders to Generate"
            value={numOrdersToGen}
            style={{ marginBottom: 10 }}
            onChange={(e) => {
              if (e.target.value > 30) {
                setNumOrdersToGen(30);
              } else {
                setNumOrdersToGen(e.target.value);
              }
            }}
          ></Input>
        </Row>
        <Row>
          <Button type="primary" onClick={() => generateOrders()}>
            Generate Orders
          </Button>
        </Row>
        <Row>
          {ordersGenerated ? (
            <Text style={{ marginBottom: 50 }}>Orders Generated</Text>
          ) : null}
        </Row>
        {ordersGenerated ? (
          <Row>
            <Button type="primary" onClick={() => startOrderGeneration()}>
              Start Order Placement
            </Button>
          </Row>
        ) : null}
        {queuingText ? (
          <>
            {queuingText.split("RFX").map((line, index) => (
              <div key={index}>
                <Row
                  style={{
                    marginBottom: line.includes("Waiting") ? 20 : 2,
                  }}
                >
                  <Text
                    style={{
                      color: line.includes("payed") ? "green" : "black",
                    }}
                  >
                    {line}
                  </Text>
                </Row>
                <Row>
                  {line.includes("COMPLETE") ? (
                    <Button type="primary" onClick={() => setQueuingText("")}>
                      Reset
                    </Button>
                  ) : null}
                </Row>
              </div>
            ))}
          </>
        ) : null}
      </Drawer>
    </>
  );
};

export default OrderGeneration;
