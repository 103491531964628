import {
  Button,
  Col,
  ConfigProvider,
  Image,
  Layout,
  Row,
  Spin,
  Modal,
  message,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState, useContext } from "react";
import { useAuth } from "../contexts/Auth";
import { useBrandContext } from "../contexts/Brand";
import { useServer } from "../contexts/Server";
import { useNavigate } from "react-router-dom";
import styles from "../styles/LoginForm.module.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import {
  userAccountInfoValidation,
  screenWidths,
  validateEmail,
} from "../const/const_functions";
import AccountNameSection from "../components/AccountNameSection";
import AccountContactInfoSection from "../components/AccountContactInfoSection";
import AccountSuccessModal from "../components/AccountSuccessModal";
const { Link, Text } = Typography;

const UserAccount = () => {
  const { brandContextData } = useBrandContext();
  const { theme } = useContext(ConfigProvider.ConfigContext);
  const { userDetails, handleUpdateProfile, authData, loadingAuth } = useAuth();
  const { serverData } = useServer();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [externalId, setExternalId] = useState(null);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [externalIdError, setExternalIdError] = useState("");
  const [emailError, setEmailError] = useState(null);

  const [allInfoFieldsValid, setAllInfoFieldsValid] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const accountRequiresExternalId =
    brandContextData?.accountUserInfo?.doesAccountHaveExternalId;
  const externalIdLabel = brandContextData?.accountUserInfo?.externalIdLabel;
  let mobile = false;
  const { width } = useWindowDimensions();
  if (screenWidths.md > width) {
    mobile = true;
  }
  let pageTitle = `Account Settings`;
  let pageSubtitle = `Edit/Update Your Account Information Here`;
  useEffect(() => {
    if (!authData && !loadingAuth) {
      navigate("/login");
    }
  }, [authData, loadingAuth]);

  useEffect(() => {
    if (userDetails?.profile && serverData) {
      setAllUserState();
    }
  }, [userDetails, serverData]);

  useEffect(() => {
    checkAccountInfoError();
  }, [firstName, lastName, phone, externalId]);

  useEffect(() => {
    checkAllInfoFieldsValid();
  }, [firstNameError, lastNameError, phoneError, externalIdError]);

  const setAllUserState = () => {
    const { firstName, lastName, phone, email, externalAccountId } =
      userDetails.profile;
    setFirstName(firstName);
    setLastName(lastName);
    setPhone(phone);
    setEmail(email);
    setExternalId(externalAccountId);
  };

  const checkAccountInfoError = () => {
    if (!firstName) {
      setFirstNameError("First name cannot be empty");
    } else if (firstName && !userAccountInfoValidation(firstName, "isName")) {
      setFirstNameError("First name is too short");
    } else {
      setFirstNameError("");
    }
    if (!lastName) {
      setLastNameError("Last name cannot be empty");
    } else if (lastName && !userAccountInfoValidation(lastName, "isName")) {
      setLastNameError("Last name is too short");
    } else {
      setLastNameError("");
    }
    if (!phone) {
      setPhoneError("Phone number cannot be empty");
    } else if (phone && !userAccountInfoValidation(phone, "isPhone")) {
      setPhoneError("Phone numbers must be 10 digits long");
    } else {
      setPhoneError("");
    }
    if (accountRequiresExternalId && !externalId) {
      setExternalIdError(`${externalIdLabel} cannot be empty`);
    } else {
      setExternalIdError("");
    }
  };

  const checkAllInfoFieldsValid = () => {
    if (
      firstName &&
      !firstNameError &&
      lastName &&
      !lastNameError &&
      ((accountRequiresExternalId && externalId) ||
        !accountRequiresExternalId) &&
      !externalIdError &&
      ((phone && !phoneError) || !phone)
    ) {
      setAllInfoFieldsValid(true);
    } else {
      setAllInfoFieldsValid(false);
    }
  };

  const updateAccount = async () => {
    try {
      setLoading(true);
      const profileData = {
        uId: userDetails._id,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        email: email,
      };
      if (externalId) {
        profileData.externalAccountId = externalId;
      }
      await handleUpdateProfile(profileData);
      setShowSuccessModal(true);
    } catch (e) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  let isFieldsChanges = false;
  if (
    userDetails?.profile?.firstName === firstName &&
    userDetails?.profile?.lastName === lastName &&
    userDetails?.profile?.phone === phone &&
    userDetails?.profile?.externalAccountId === externalId
  ) {
    isFieldsChanges = false;
  } else {
    isFieldsChanges = true;
  }

  const resendVerification = async () => {
    try {
      setLoading(true);
      const resendVerificationRes = await serverData.call(
        "USSR_resendVerificationEmail",
        { contextId: brandContextData._id }
      );
      if (resendVerificationRes?.success) {
        setLoading(false);
        message.success(
          `Check your inbox, An email with a link to verify your email has been sent to you`
        );
      }
    } catch (e) {
      setLoading(false);
      message.error(
        `There was an error, Please check the Help And Support screen for further assistance`
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      style={{
        backgroundColor: "rgb(237, 237, 237)",
        minHeight: "100vh",
      }}
    >
      <AccountSuccessModal
        message={"The Profile has been successfully updated!"}
        handleCloseModal={() => setShowSuccessModal(false)}
        showSuccessModal={showSuccessModal}
      />
      <Spin
        size="large"
        spinning={loading}
        tip={loading ? "Logging In." : null}
      >
        <Content
          style={{
            marginTop: 20,
            marginBottom: 100,
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Col
            style={{
              backgroundColor: "white",
              padding: mobile ? 20 : 48,
              borderRadius: 12,
            }}
            xs={{ span: 22, offset: 1 }}
            md={{ span: 8, offset: 8 }}
          >
            {brandContextData?.brandLogo ? (
              <Row justify={"center"} className={styles.logoContainer}>
                <Image
                  width={mobile ? "50%" : 200}
                  preview={false}
                  src={brandContextData.brandLogo}
                  style={{
                    maxHeight: 180,
                    minHeight: 75,
                    objectFit: "contain",
                  }}
                />
              </Row>
            ) : null}
            <p
              style={{
                fontWeight: "600",
                color: "black",
                fontSize: 24,
                marginBottom: 0,
              }}
            >
              {pageTitle}
            </p>
            <p
              style={{
                fontWeight: "500",
                color: "grey",
                marginBottom: 40,
                fontSize: 14,
                marginTop: 4,
              }}
            >
              {pageSubtitle}
            </p>
            <AccountNameSection
              firstName={firstName}
              setFirstName={setFirstName}
              firstNameError={firstNameError}
              lastName={lastName}
              setLastName={setLastName}
              lastNameError={lastNameError}
            />
            <AccountContactInfoSection
              phone={phone}
              setPhone={setPhone}
              phoneError={phoneError}
              accountRequiresExternalId={accountRequiresExternalId}
              externalIdLabel={externalIdLabel}
              externalId={externalId}
              setExternalId={setExternalId}
              externalIdError={externalIdError}
              email={email}
              setEmail={setEmail}
              setEmailError={setEmailError}
              accountRestrictsDomain={false}
              allowedEmailDomains={[]}
              emailError={emailError}
              disableEmailField={true}
            />
            <Col style={{ paddingTop: 20 }}>
              {userDetails?.profile?.emailVerified ? (
                <Text style={{ fontWeight: 500 }}>
                  Your email has been verified!
                </Text>
              ) : (
                <Col>
                  <Text style={{ fontWeight: 500 }}>
                    Check your inbox for an email to get verified
                  </Text>
                  <Row>
                    <Text style={{ color: "gray" }}>Didn't get an email?</Text>
                    <Link
                      onClick={() => resendVerification()}
                      style={{
                        color: brandContextData.brandTheme.colorSecondary,
                        fontWeight: 500,
                        marginLeft: 5,
                      }}
                    >
                      Resend verification link
                    </Link>
                  </Row>
                </Col>
              )}
            </Col>
            <Button
              className={styles.loginBtn}
              style={{
                "--border-color":
                  isFieldsChanges && allInfoFieldsValid
                    ? theme.token.colorSecondary
                    : "#FFFFFF",
                width: "100%",
                backgroundColor:
                  isFieldsChanges && allInfoFieldsValid
                    ? theme.token.colorSecondary
                    : "lightgray",
                marginTop: 20,
              }}
              disabled={isFieldsChanges && allInfoFieldsValid ? false : true}
              onClick={() => updateAccount()}
            >
              <span style={{ color: "white", fontWeight: "600" }}>
                Update Profile
              </span>
            </Button>
            <Button
              style={{ marginTop: 20, width: "100%", marginBottom: 20 }}
              onClick={() => navigate("/")}
            >
              <span style={{ color: "red", fontWeight: "600" }}>Cancel</span>
            </Button>
          </Col>
        </Content>
      </Spin>
    </Layout>
  );
};

export default UserAccount;
