import { List, Typography, Avatar } from "antd";
import React, { useState } from "react";
import FoodItemModal from "./FoodItemModal";

const { Text } = Typography;

const FoodItemList = ({ categoryItem, stadium }) => {
  const { foodItems } = categoryItem;
  const [showFoodItemModal, setShowFoodItemModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  const viewSelectedItem = (item) => {
    setCurrentItem(item);
    setShowFoodItemModal(true);
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={foodItems}
        style={{ backgroundColor: "white" }}
        renderItem={(item) => {
          let imgUrl = "";
          if (
            typeof item.fXQImageURL === "string" &&
            item.fXQImageURL.trim() !== ""
          ) {
            imgUrl = item.fXQImageURL;
          }
          let mySaneDesc = "";
          if (typeof item.fXQDesc === "string" && item.fXQDesc.trim() !== "") {
            mySaneDesc = item.fXQDesc.trim();
          }
          return (
            <List.Item
              onClick={() => viewSelectedItem(item)}
              style={{ paddingTop: 25, paddingBottom: 25, cursor: "pointer" }}
            >
              <List.Item.Meta
                avatar={imgUrl && <Avatar src={imgUrl} className="itemImg" />}
                title={
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>{item.name}</Text>
                    <Text style={{ fontWeight: "500", whiteSpace: "nowrap" }}>
                      ${Number(item.price).toFixed(2)}
                    </Text>
                  </div>
                }
                description={
                  <div style={{ paddingRight: 55 }}>{mySaneDesc}</div>
                }
              />
            </List.Item>
          );
        }}
      ></List>
      <FoodItemModal
        showFoodItemModal={showFoodItemModal}
        setShowFoodItemModal={setShowFoodItemModal}
        currentItem={currentItem}
        categoryItem={categoryItem}
        stadium={stadium}
      />
    </>
  );
};

export default FoodItemList;
