import React from "react";
import { Col, Row, Typography } from "antd";
import StadiumCard from "./StadiumCard";
const { Text } = Typography;

const StadiumListing = ({ filteredStadiumListing, searchValue, mobile }) => {
  if (!filteredStadiumListing.length && searchValue) {
    return (
      <Row justify={"center"}>
        <Text>No vendors. Try expanding your search.</Text>
      </Row>
    );
  }
  return (
    <Row
      gutter={[12, 12]}
      justify={"center"}
      style={{ paddingLeft: mobile ? 12 : 0, paddingRight: mobile ? 12 : 0 }}
    >
      {filteredStadiumListing.map((mStadium, index) => (
        <Col
          key={mStadium._id}
          xs={{ span: 24 }}
          sm={{ span: 18 }}
          md={{ span: 16 }}
          lg={{ span: 14 }}
          xl={{ span: 8 }}
        >
          <div>
            <StadiumCard stadium={mStadium} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default StadiumListing;
