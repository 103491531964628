import {
  Breadcrumb,
  Col,
  Divider,
  Layout,
  message,
  Row,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/OrderHistoryDetails.css";
import OrderHistoryStatus from "../components/OrderHistoryStatus";
import OrderStatusDetails from "../components/OrderStatusDetails";
import {
  capitalizeFirstLetter,
  encodeParamString,
} from "../const/const_functions";
import { useEffect } from "react";
// import {useAuth} from '../contexts/Auth';
import { useServer } from "../contexts/Server";
import { useState } from "react";
import { useBrandContext } from "../contexts/Brand";
import CallServerButton from "../components/CallServerButton";

const OrderHistoryDetails = () => {
  const { Text } = Typography;
  const location = useLocation();
  const { order, stadium } = location.state;
  const deliveryOption = capitalizeFirstLetter(order.deliveryOption);
  const [currentOrder, setCurrentOrder] = useState(order);
  const { brandContextData } = useBrandContext();
  const navigate = useNavigate();
  const [isCallServerButtonDisabled, setIsCallServerButtonDisabled] =
    useState(false);

  useEffect(() => {
    if (isCallServerButtonDisabled) {
      setTimeout(() => setIsCallServerButtonDisabled(false), 30000);
    }
  }, [isCallServerButtonDisabled]);

  const showBusinessOfferings = () => {
    const { name = "", _id = "" } = stadium;
    const stadiumNameDashes = name.replaceAll(" ", "-");
    let encodedStoreId = encodeParamString(_id);
    const newStoreUrl = `/order/${stadiumNameDashes}?glb=${encodedStoreId}`;
    navigate(newStoreUrl);
  };

  return (
    <Layout style={{ backgroundColor: "white" }}>
      <Content
        style={{
          marginTop: 20,
          minHeight: "100vh",
          marginBottom: 100,
        }}
      >
        <Row>
          <Col xs={{ span: 22, offset: 1 }} md={{ span: 16, offset: 4 }}>
            <Breadcrumb
              items={[
                {
                  title:
                    brandContextData?._id === "a5121e36-getit-technologies" ? (
                      <a onClick={() => showBusinessOfferings()}>Menu</a>
                    ) : (
                      <a href="/">Home</a>
                    ),
                },
                { title: "Order Details" },
              ]}
              style={{ marginBottom: 6 }}
            />
            <Row justify="center" style={{ marginTop: 20 }}>
              <Col span={24}>
                <Row justify="center">
                  <Text className="orderNo">{`Order ${currentOrder.orderNo}`}</Text>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Text className="orderName">{stadium.name}</Text>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <Text className="stadiumAddressText">{stadium.address}</Text>
                </Row>
              </Col>
              <Divider />
              {deliveryOption !== "Inhouse" &&
              deliveryOption !== "Admission" ? (
                <Col span={24}>
                  <Row justify="center">
                    <Text className="deliveryOption">{deliveryOption}</Text>
                  </Row>
                </Col>
              ) : null}
            </Row>
            <OrderStatusDetails order={currentOrder} stadium={stadium} />
          </Col>
        </Row>
      </Content>
      {order.deliveryOption === "inhouse" &&
      order.inhouseTableNumber !== "N/A" &&
      order.stadium.enableHaveAQuestion ? (
        <CallServerButton
          storeId={order.stadium._id}
          tableNumber={order.inhouseTableNumber}
          isCallServerButtonDisabled={isCallServerButtonDisabled}
          setIsCallServerButtonDisabled={setIsCallServerButtonDisabled}
        />
      ) : null}
    </Layout>
  );
};

export default OrderHistoryDetails;
