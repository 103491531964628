import React from "react";
import { Row, Col } from "antd";
import QuickAccessBtnVar1 from "../QuickAccessBtnVar1";
import QuickAccessBtn from "../QuickAccessBtn";

const QuickAccessSectionVar1 = ({ quickAccessData, mobile }) => {
  if (!quickAccessData || !quickAccessData.length) {
    return null;
  }

  return (
    <Col span={24}>
      <Row
        style={{
          paddingTop: quickAccessData && quickAccessData.length ? 0 : 0,
          justifyContent: "center",
        }}
      >
        <Col
          span={24}
          style={{
            paddingTop: 20,
            paddingBottom: mobile ? 5 : 20,
            width: "100%",
          }}
        >
          <Row
            gutter={16}
            style={{
              overflowX: "auto",
              paddingBottom: 20,
              justifyContent: "center",
            }}
          >
            <Col
              style={{
                display: "flex",
                whiteSpace: "wrap",
              }}
            >
              {quickAccessData.map((item) => {
                return (
                  <QuickAccessBtn
                    key={item._id}
                    quickAccessItem={item}
                    mobile={mobile}
                  />
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default QuickAccessSectionVar1;
