import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Row,
  Col,
  Button,
  Divider,
  theme,
  Spin,
} from "antd";
import styles from "../styles/SelectLocationModal.module.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useChain } from "../contexts/Chain";
import { useServer } from "../contexts/Server";
import { useBrandContext } from "../contexts/Brand";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { getTimeOperation } from "../const/const_functions";
import { useLocation, useNavigate } from "react-router-dom";
const { Text, Link } = Typography;
const { useToken } = theme;

const SelectLocationModal = ({
  showSelectLocationModal,
  mobile,
  setShowSelectLocationModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { brandContextData } = useBrandContext();
  const { setBrandChain } = useChain();
  const { serverData } = useServer();
  const { token } = useToken();
  const [googleAPIKey, setGoogleAPIKey] = useState(null);

  const [userAddressDetails, setUserAddressDetails] = useState(null);
  const [stadiumListing, setStadiumListing] = useState([]);
  const [loadingStadiumListing, setloadingStadiumListing] = useState(false);
  const [showResultsMessage, setShowResultsMessage] = useState(false);
  const { height } = useWindowDimensions();
  const contentHeight = height - 350;

  useEffect(() => {
    if (serverData) {
      retrieveGoogleInfoAPI();
    }
  }, [serverData]);

  const retrieveGoogleInfoAPI = async () => {
    try {
      const googleAPIKey = await serverData.call("BLLSTC_getGoogleInfo", {});
      if (googleAPIKey.googleKey) {
        setGoogleAPIKey(googleAPIKey.googleKey);
      }
    } catch (e) {
      console.log("Error getting google API Key", e);
    }
  };

  const getUserLatLng = async (data) => {
    setStadiumListing([]);
    setShowResultsMessage(false);
    if (data) {
      try {
        const results = await geocodeByAddress(data.label);
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        setUserAddressDetails({ lat: lat, lng: lng });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const searchStores = async () => {
    if (userAddressDetails) {
      setloadingStadiumListing(true);
      try {
        const listStoresWithFilters = await serverData.call(
          "USSR_listStoresWithFilters",
          {
            userLocation: userAddressDetails,
            contextId: brandContextData._id,
          }
        );
        if (listStoresWithFilters?.length) {
          setStadiumListing(listStoresWithFilters);
        } else {
          setShowResultsMessage(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setloadingStadiumListing(false);
  };

  const closeModal = () => {
    setStadiumListing([]);
    setUserAddressDetails(null);
    setShowSelectLocationModal(false);
    setShowResultsMessage(false);
    if (location.pathname.includes("/order")) {
      navigate("/");
    }
  };

  return (
    <Modal
      destroyOnClose={true}
      open={showSelectLocationModal}
      onCancel={closeModal}
      width={mobile ? "100%" : "50%"}
      footer={
        <Row justify={"center"}>
          <Button
            disabled={!userAddressDetails}
            className={styles.footerButton}
            style={{
              color: token.colorTertiary,
              backgroundColor: !userAddressDetails
                ? "lightgray"
                : token.colorSecondary,
            }}
            onClick={
              stadiumListing.length || showResultsMessage
                ? closeModal
                : searchStores
            }
          >
            {stadiumListing.length || showResultsMessage ? "Cancel" : "Search"}
          </Button>
        </Row>
      }
      title="Enter an address to see nearby locations"
    >
      <Col span={24} className={styles.mainCol}>
        <GooglePlacesAutocomplete
          fetchDetails={true}
          apiKey={googleAPIKey}
          onLoadFailed={(error) => {
            console.log("Could not inject Google Script", error);
          }}
          selectProps={{
            placeholder: "Enter your address",
            height: 50,
            onChange: getUserLatLng,
            userAddressDetails,
            styles: {
              input: (provided) => ({
                ...provided,
                paddingTop: mobile ? 0 : 10,
                paddingBottom: 10,
              }),
            },
          }}
        />
        <Spin spinning={loadingStadiumListing} size="large">
          <Row
            className={styles.locations}
            style={{
              maxHeight: mobile ? contentHeight : 600,
              overflowY: "auto",
              marginTop: 10,
            }}
          >
            {stadiumListing.map((stadium) => {
              const timeOperation = getTimeOperation(stadium);
              let stadiumKMAway = "";
              if (stadium.dDisplayKM) {
                stadiumKMAway = stadium.dDisplayKM.toFixed(1);
              }
              return (
                <Link
                  key={stadium._id}
                  className={styles.cardLink}
                  onClick={() => {
                    setBrandChain(stadium);
                    closeModal();
                  }}
                >
                  <Row>
                    <Col
                      xs={{ span: 3 }}
                      sm={{ span: 3 }}
                      md={{ span: 3 }}
                      lg={{ span: 3 }}
                      xl={{ span: 2 }}
                    >
                      <FaMapMarkerAlt
                        name="map"
                        style={{
                          fontSize: mobile ? 28 : 30,
                          color: token.colorSecondary,
                        }}
                      />
                    </Col>
                    <Col
                      xs={{ span: 21 }}
                      sm={{ span: 21 }}
                      md={{ span: 21 }}
                      lg={{ span: 21 }}
                      xl={{ span: 22 }}
                    >
                      <Row>
                        <Text>{stadium.address}</Text>
                      </Row>
                      {stadiumKMAway ? (
                        <Text>{stadiumKMAway} km away</Text>
                      ) : null}
                      <Row></Row>
                      <Row>
                        <Text>{timeOperation}</Text>
                      </Row>
                    </Col>
                    <Divider className={styles.divider} />
                  </Row>
                </Link>
              );
            })}
            {showResultsMessage ? (
              <Row justify={"center"} style={{ width: "100%" }}>
                <Text>Sorry, no locations found near that address.</Text>
              </Row>
            ) : null}
          </Row>
        </Spin>
      </Col>
    </Modal>
  );
};

export default SelectLocationModal;
